import { faCheckSquare, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { generateRandomString } from "../../service/RandomString";
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { sortByProperty } from "../SortByProperty";

export default function SelectFinalOne({setFormValues,setError,formValues,name,choix,options,label,value,seteventy,error,max,placeholder}) {
  const [selectedvalues, setselectedvalues] = useState(null);
  const SortedValue = sortByProperty(options)
  const animatedComponents = makeAnimated();
  let text = "";
  const [ito, setIto] = useState([]);
  const change = (choice) => {
    var nb = 0;
    text = "";
    
        setFormValues({ ...formValues, [name]: choice });
        setError({ ...error, [name]: null });
    
    try {
      seteventy(generateRandomString(5));
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    console.log(selectedvalues)
  change(selectedvalues)
   }, [selectedvalues]);
  useEffect(() => {
   if (choix == null) {
    setIto(null)
   }else{
    let categ = choix.split(",");
    let valueCat = [];
    categ.map((i) => i != "" && valueCat.push({ value: i, label: i }));
    setIto(valueCat);
   }
  }, [choix]);
  useEffect(()=>{
    console.log(formValues)
      },[formValues])
  return (
    <div className="d-flex align-items-center gap-2">
    

      <Dropdown appendTo={"self"} value={selectedvalues} onChange={(e) => setselectedvalues(e.value)} options={SortedValue} optionValue={value} optionLabel={label} 
            showClear  filter placeholder={placeholder || "Sélectionnez"}  className="w-full md:w-20rem" />
    </div>
  );
}




 