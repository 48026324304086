import {
  faCheckCircle,
  faEdit,
  faEraser,
  faFilter,
  faFilterCircleXmark,
  faHouse,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";

import { toast } from "react-toastify";
import Select from "react-select";

import { useSelector } from "react-redux";
import {
  examenspratiques_API,
  getListExaminateur,
  getcentreByatt,
  listcandexampratique,
} from "../../../../service/config";
import { generateRandomString } from "../../../../service/RandomString";
import { regions } from "../../../../service/auth/UtilApi";
import SelectCustom from "../../../../outils/Select/SelectComponent";
import SelectionExaminateur from "../Examinateur/SelectionExaminateur";
import SelectOneId from "../../../../outils/Select/selectCustomId";
import isconnected from "../../../../service/isconnected";

export default function ExamenPratique() {
  const [choix, setchoix] = useState("");
  const [error, setError] = useState({
    categ: "",
  });
  const columns = [
    // {
    //   name: "id",
    //   width: "8%",
    //   selector: (row) => row.id_cand,
    //   sortable: true,
    // },
    {
      name: "Permis",
      selector: (row) => row.categ_permis,
      sortable: true,
    },
    {
      name: "Nom et prenom",
      selector: (row) => (
        <>
          {row.nom_cand} {row.prenom_cand}
        </>
      ),
      sortable: true,
    },
    {
      name: "Date de naissance",
      selector: (row) => row.naiss_cand,
      sortable: true,
    },
    {
      name: "Adresse",
      selector: (row) => row.adresse_cand,
      sortable: true,
    },
    {
      name: "Ville",
      selector: (row) => row.ville_cand,
      sortable: true,
    },
    {
      name: "Region",
      selector: (row) => row.province_cand,
      sortable: true,
    },
  ];
  const columns_valid = [
    // {
    //   name: "id",
    //   width: "8%",
    //   selector: (row) => row.id_cand,
    //   sortable: true,
    // },
    {
      name: "Permis",
      selector: (row) => row.categ_permis,
      sortable: true,
    },
    {
      name: "Nom et prenom",
      selector: (row) => (
        <>
          {row.nom_cand} {row.prenom_cand}
        </>
      ),
      sortable: true,
    },
    {
      name: "Date de naissance",
      selector: (row) => row.naiss_cand,
      sortable: true,
    },
    {
      name: "Adresse",
      selector: (row) => row.adresse_cand,
      sortable: true,
    },
    {
      name: "Ville",
      selector: (row) => row.ville_cand,
      sortable: true,
    },
    {
      name: "Region",
      selector: (row) => row.province_cand,
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="">
          <button
            className="btn btn-primary"
            onClick={() => {
              let mis = candidatSelect.filter(
                (el) => el.id_cand != row.id_cand
              );
              setcandidatSelect(mis);
            }}
          >
            <FontAwesomeIcon icon={faEraser} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const [selectExam, setselectExam] = useState({
    nom_memb: "",
    prenom_memb: "",
  });
  const [eventy, seteventy] = useState("");
  const [data, setdata] = useState([]);
  const [autoecole, setautoecole] = useState([]);
  const [centre, setcentre] = useState([]);
  const [Examinateur, setExaminateur] = useState([]);
  const [loadExamin, setloadexamin] = useState(true);
  const [filtre, setfiltre] = useState({
    categorie: "",
    auto: "",
    dateinsc: "",
  });
  const [load, setLoad] = useState(false);
  const [loadValid, setLoadValid] = useState(false);
  const [candidatSelect, setcandidatSelect] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, settoggledClearRows] = useState(false);
  const categ = useSelector((state) => state.categ);
  const Alltype = useSelector((state) => state.listtypeexam);
  const abortController = new AbortController();

  const getExaminateur = (id) => {
    axios.get(getListExaminateur + id,{
      signal: abortController.signal,
      }).then((rep) => {
      console.log(rep);
      setExaminateur(rep.data.data);
    });
  };
  useEffect(() => {
    getExaminateur(isconnected.user().id_type);
    return () => {
      abortController.abort();
    };
  }, []);
  const getAutoecole = () => {
    let auto = [];
    try {
      axios
        .get(getcentreByatt + "Auto-écoles",{
          signal: abortController.signal,
          })
        .then((reponse) => {
          for (let index = 0; index < reponse.data.data.length; index++) {
            const element = reponse.data.data[index];
            auto.push({ label: element.nom_org, value: element.id_org });
          }
          setautoecole(auto);
          console.log(reponse.data);
        })
        .catch((err) => {
          toast.error("erreur lors de la récuperation liste auto-école");
        });
    } catch (error) {
      toast.error("erreur interne");
    }
  };
  const getcentre = () => {
    let cent = [];
    try {
      axios
        .get(getcentreByatt + "Examinateurs",{
          signal: abortController.signal,
          })
        .then((reponse) => {
          for (let index = 0; index < reponse.data.data.length; index++) {
            const element = reponse.data.data[index];
            cent.push({ label: element.nom_org, value: element.id_org });
          }
          setcentre(cent);
          console.log(reponse.data);
        })
        .catch((err) => {
          toast.error("erreur lors de la récuperation liste centre");
        });
    } catch (error) {
      toast.error("erreur interne");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setfiltre({ ...filtre, [name]: value });
  };
  const [showvalid, setshowvalid] = useState(false);
  const handleClearRows = () => {
    settoggledClearRows(!toggledClearRows);
  };
  const handleValidation = () => setshowvalid(!showvalid);
  useEffect(() => {
    getAutoecole();
    getcentre();
  }, []);

  const filtredCandidat = async () => {
    setLoad(true);
    try {
      await axios
        .get(
          listcandexampratique +
            `?id_org=${filtre?.auto ? filtre?.auto : ""}${
              filtre?.categ
                ? "&categ_permis=" + filtre?.categ.slice(0, -1) + ""
                : ""
            }${filtre?.region ? "&region=" + filtre?.region + "" : ""}`,{
              signal: abortController.signal,
              }
        )
        .then((reponse) => {
          console.log(reponse);
          setdata(reponse.data.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setLoad(false);
        });
    } catch (error) {
      setLoad(false);
    }
  };
  const validerCand = () => {
    setLoadValid(true);
    try {
      axios
        .post(examenspratiques_API, {
          id_org: filtre?.centre,
          date_examp: filtre?.date_exam,
          heurs_examp: filtre?.heurs_exam,
          id_typeexam: filtre?.id_categ,
          id_memb: selectExam?.id_memb,
          candidats: candidatSelect,
          id_categ: filtre.id_categ,
        })
        .then((reponse) => {
          setLoad(false);
          console.log(reponse);
          toast.success("candidats valider !");
          setLoadValid(false);
          handleValidation();
          settoggledClearRows(!toggledClearRows);
          setcandidatSelect([]);
          setfiltre({});
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        })
        .catch((err) => {
          setLoad(false);
          toast.error("un erreur est arrivée");
          handleValidation();
          console.log(err);
          setLoadValid(false);
        });
    } catch (error) {
      setLoadValid(false);
      console.log(error);
      handleValidation();
    }
  };

  useEffect(() => {
    filtredCandidat();
    return () => {
      abortController.abort();
    };
  }, [eventy]);

  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={showvalid}
        onHide={handleValidation}
        backdrop="static"
        keyboard={false}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Validation de liste des candidats selectionnés à l'examen </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="">
              <DataTable
                className="custom-table"
                title={<p className="fs-2">Liste des candidats </p>}
                columns={columns_valid}
                data={candidatSelect}
                contextActions={
                  <button
                    className="btn btn-cyan me-2"
                    onClick={() => {
                      // ValiderCandidat();
                    }}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                    Valider
                  </button>
                }
                progressComponent={
                  <div className="w-100 d-flex justify-content-center py-5">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#22577A"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                    <strong></strong>
                  </div>
                }
                contextMessage={{
                  singular: "élement",
                  plural: "élements",
                  message: "sélectionnées",
                }}
                onSelectedRowsChange={(rows) => {
                  setSelectedRows(rows);
                }}
                pagination
                paginationPerPage={5}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page",
                }}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={loadValid}
            onClick={handleValidation}
          >
            Fermer
          </Button>
          <Button
            variant="primary"
            disabled={loadValid}
            onClick={() => validerCand()}
          >
            <>Valider</>
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="">
          <h2>
            <FontAwesomeIcon icon={faHouse} className="me-1" /> Examens Pratique{" "}
          </h2>
          <h4>Gestion des examens et validation des candidats</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-12">
          <div className="card">
            <div className="card-body pb-4">
              <h1>Filtres</h1>
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="my-2">
                    <label className="form-label">Auto-école</label>
                    <Select
                      options={autoecole}
                      onChange={(select) => {
                        setfiltre({ ...filtre, auto: select.value });
                        seteventy(generateRandomString(5));
                      }}
                      className="w-100"
                      placeholder="Sélectionnez Auto-école..."
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="my-2">
                    <label className="form-label">Région</label>
                    <Select
                      options={regions}
                      onChange={(select) => {
                        setfiltre({ ...filtre, region: select.value });
                        seteventy(generateRandomString(5));
                      }}
                      className="w-100"
                      placeholder="Sélectionnez région..."
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="my-2">
                    <label className="form-label">Catégorie permis</label>
                    <SelectCustom
                      options={categ}
                      load={false}
                      choix={choix}
                      placeholder="choix catégorie"
                      setFormValues={setfiltre}
                      formValues={filtre}
                      setError={setError}
                      error={error}
                      seteventy={seteventy}
                      name={"categ"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-body">
              <div className="">
                <DataTable
                  className="custom-table"
                  title={
                    <p className="fs-2">Liste des candidats sélectionnées</p>
                  }
                  columns={columns}
                  data={data}
                  selectableRows
                  clearSelectedRows={toggledClearRows}
                  onSelectedRowsChange={(rows) => {
                    setSelectedRows(
                      rows.selectedRows.map((row) => row.id_cand)
                    );
                    setcandidatSelect(rows.selectedRows.map((row) => row));
                  }}
                  progressPending={load}
                  selectedRow={selectedRows}
                  contextActions={
                    <div className="d-flex">
                      <Button
                        className="me-2"
                        variant="secondary"
                        onClick={() => {
                          settoggledClearRows(!toggledClearRows);
                          setcandidatSelect([]);
                        }}
                      >
                        <FontAwesomeIcon icon={faUndoAlt} className="" />
                      </Button>
                      {/* <button
                      className="btn btn-cyan me-2"
                      onClick={() => {
                        handleValidation();
                      }}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                      voir la liste des selectionné
                    </button> */}
                    </div>
                  }
                  progressComponent={
                    <div className="w-100 d-flex justify-content-center py-5">
                      <TailSpin
                        height="80"
                        width="80"
                        color="#22577A"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  }
                  contextMessage={{
                    singular: "élement",
                    plural: "élements",
                    message: "sélectionnées",
                  }}
                  pagination
                  paginationPerPage={5}
                  paginationComponentOptions={{
                    rowsPerPageText: "Lignes par page",
                  }}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-12">
          <div className="card">
            <div className="card-body">
              <h1 className="text-center">Sélection pour l'éxamen</h1>
              <div className="my-3">
                <label className="form-label">Date d'examen</label>
                <input
                  className="form-control datepicker"
                  value={filtre?.date_exam}
                  onChange={(e) =>
                    setfiltre({ ...filtre, date_exam: e.target.value })
                  }
                  type="date"
                />
              </div>
              <div className="my-3">
                <label className="form-label">Heure d'examen</label>
                <input
                  className="form-control timepicker"
                  value={filtre?.heurs_exam}
                  onChange={(e) =>
                    setfiltre({ ...filtre, heurs_exam: e.target.value })
                  }
                  type="time"
                />
              </div>
              <div className="my-3 d-flex flex-wrap align-items-center justify-content-between">
                <label className="form-label">
                  Examinateur :{" "}
                  {selectExam?.nom_memb != undefined &&
                    selectExam?.nom_memb + " " + selectExam?.prenom_memb}
                </label>
                <SelectionExaminateur
                  data={Examinateur}
                  selectExam={selectExam}
                  setselectExam={setselectExam}
                />
              </div>

              <div className="my-3">
                <label className="form-label">Type d'examen</label>
                <Select
                  options={Alltype}
                  isClearable
                  onChange={(select) =>
                    setfiltre({ ...filtre, type: select.value })
                  }
                  className="w-100"
                  placeholder="Sélectionnez centre..."
                />
              </div>
              <div className="my-3">
                <label className="form-label">Catégorie Permis</label>
                <SelectOneId
                  options={categ}
                  load={false}
                  placeholder="choix catégorie"
                  setFormValues={setfiltre}
                  formValues={filtre}
                  setError={setError}
                  error={error}
                  name={"id_categ"}
                />
              </div>
              <div className="d-flex gap-1 justify-content-end">
                {/* <button className="btn btn-secondary px-4">Suivant</button> */}
                <button
                  className={
                    "btn btn-success px-4 " + (loadValid && "disabled")
                  }
                  onClick={() => {
                    if (
                      !filtre?.date_exam ||
                      !filtre?.heurs_exam ||
                      candidatSelect.length == 0
                    ) {
                      toast.info(
                        "veuillez remplir les champs avant de valider !"
                      );
                    } else {
                      handleValidation();
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
