import {
  faCalendarTimes,
  faCancel,
  faCheckCircle,
  faClockFour,
  faEdit,
  faEraser,
  faEye,
  faFilePdf,
  faHouse,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { Calendar } from "primereact/calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";

import Select from "react-select";
import { toast } from "react-toastify";

import moment from "moment";
import { Tag } from "primereact/tag";

import { useSelector } from "react-redux";
import UseEndpoint from "../../../../service/getApi/UseEndpoint";
import { CATEGORIE_API, ExamenListTheo, getcentreByatt, organisme_API, ResRedoubleTheo } from "../../../../service/config";
import DetailExamen from "../ResultatExamen/DetailExamen";
import isconnected from "../../../../service/isconnected";
import SelectFinalOne from "../../../../outils/Select/SelectFinalOne";


export default function Red() {
  const [recharger, setRecharger] = useState(false);
  const [centre, setcentre] = useState([]);
  const [choix, setChoix] = useState(null);
  const [error, setError] = useState(null);
  const [show, setshow] = useState({
    examen: false,
    resultat: false,
    clear: false,
  });
  const dataRedux = useSelector((state) => state.auto);
  const handleShow = (propr) => setshow({ ...show, [propr]: !show[propr] });
  const { data: examlist, load: loadlist } = UseEndpoint(
    ExamenListTheo(),
    "data"
  );
  console.log(examlist);
  const { data: list, load: loadlistexamen } = UseEndpoint(
    ResRedoubleTheo(),
    "data",
    recharger
  );

  const { data, load } = UseEndpoint(
    organisme_API + `${"?type_org=Examinateurs"}`,
    "data"
  );
  const { data: categ, load: loadcateg } = UseEndpoint(CATEGORIE_API, "data");

  const abortController = new AbortController();
  const cherche = (dat, row, champs,champscomp, ret) => {
    for (let i = 0; i < dat.length; i++) {
      const element = dat[i];
      if (element[champscomp] == row[champs]) {
        
        return element[ret];
      }
    }
  };
  const columns = [
    // {
    //   name: "ID",
    //   maxWidth: "80px",
    //   selector: (row) => row.id_exam,
    //   sortable: true,
    // },
    {
      name: "Date de l'examen",
      cell: (row) => <Tag severity={"info"}> {moment(row.examen.date_exam).locale("fr").format("LL")}</Tag>,
      selector: (row) => row.date_exam,
      sortable: true,
    },
    {
      name: "Centre",
      sortable: true,
      selector: (row) => row.id_org,
      cell: (row) => {
      
        return <span>{row.examen.organisme.nom_org}</span>;
      },
    },
    // {
    //   name: "Catégorie de permis",
    //   sortable: true,
    //   selector: (row) => row.id_categ,
    //   format: (row) => {
    //     const nom = cherche(categ, row, "id_categ", "categ");
    //     return <Tag severity={"info"}> {nom}</Tag>;
    //   },
    // },
    
    {
      name: "heure de début",
      selector: (row) => (
        <Tag severity={"info"}>
          <FontAwesomeIcon className="me-2" icon={faClockFour} />
          {row.examen.heurs_exam}
        </Tag>
      ),
      sortable: true,
    },
    {
      name: "action",
      width:'15%',
      cell: (row) => (
        <DetailExamen Affiche={"red"} list={row} error={error} setError={setError} cherche={cherche} choix={choix} setfiltre={setfiltre} filtre={filtre} Auto={dataRedux} />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const columns_valid = [
    {
      name: "Nom et prenom",
      selector: (row) => (
        <>
          {row.nom} {row.prenom}
        </>
      ),
      sortable: true,
    },
    {
      name: "Adresse",
      selector: (row) => row.adresse,
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: (row) => row.telephone,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      width: "105px",
      name: "statut",
      selector: (row) => (
        <span className="badge bg-secondary">{row.status}</span>
      ),
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="">
          <button className="btn btn-primary" onClick={() => {}}>
            <FontAwesomeIcon icon={faEraser} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [showvalid, setshowvalid] = useState(false);
  const [ExamSelected, setExamSelected] = useState([]);

  const [filtre, setfiltre] = useState({});
  const [autoecole, setautoecole] = useState([]);
  const handleValidation = () => setshowvalid(!showvalid);

  const handleFiltre = (e) => {
    const { name, value } = e.target;
    setfiltre({ ...filtre, [name]: value });
  };

  const getAutoecole = () => {
    let auto = [];
    try {
      axios
        .get(organisme_API + "?type_org=Auto-écoles", {
          signal: abortController.signal,
        })
        .then((reponse) => {
          for (let index = 0; index < reponse.data.data.length; index++) {
            const element = reponse.data.data[index];
            auto.push({ label: element.nom_org, value: element.id_org });
          }
          setautoecole(auto);
          console.log(reponse.data);
        })
        .catch((err) => {
          toast.error("erreur lors de la récuperation liste auto-école");
        });
    } catch (error) {
      toast.error("erreur interne");
    }
  };

  const getcentre = () => {
    let cent = [];
    try {
      axios
        .get(getcentreByatt + "Examinateurs", {
          signal: abortController.signal,
        })
        .then((reponse) => {
          for (let index = 0; index < reponse.data.data.length; index++) {
            const element = reponse.data.data[index];
            cent.push({ label: element.nom_org, value: element.id_org });
          }
          setcentre(cent);
          console.log(reponse.data);
        })
        .catch((err) => {
          toast.error("erreur lors de la récuperation liste centre");
        });
    } catch (error) {
      toast.error("erreur interne");
    }
  };

  useEffect(() => {
    getcentre();
    if (isconnected.user().type_util !== "Auto-écoles") {
      getAutoecole();
    }
  }, []);

  useEffect(() => {
    console.log(ExamSelected);
    
  }, [ExamSelected]);

  useEffect(() => {
    console.log(filtre)
  }, [filtre?.value, filtre?.statut]);

  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={showvalid}
        onHide={handleValidation}
        backdrop="static"
        keyboard={false}
        size={"xl"}
      >
        <Modal.Header>
          <Modal.Title>
            <h2>Validation de liste des candidats selectionnés à l'examen </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="">
              <DataTable
                className="custom-table"
                title={<p className="fs-2">Liste des candidats </p>}
                columns={columns_valid}
                data={examlist}
                progressPending={loadlist}
                contextActions={
                  <button className="btn btn-cyan me-2">
                    <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                    Valider
                  </button>
                }
                progressComponent={
                  <div className="w-100 d-flex justify-content-center py-5">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#22577A"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                }
                contextMessage={{
                  singular: "élement",
                  plural: "élements",
                  message: "sélectionnées",
                }}
                pagination
                paginationPerPage={5}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page",
                }}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleValidation}>
            Fermer
          </Button>
          <Button variant="primary">
            <>Envoyer</>
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="row g-3">
        <div className="col-12">
          <Card>
            <Card.Body>
              <h1 className="text-start">Redoublants / Examens théoriques</h1>
              <div className="row align-items-center">
                {/* <div className="col-lg-4 col-12">
                  <div className="my-2">
                    <div className="form-label">
                      Veuillez choisir un examen :
                    </div>
                    <Button
                      className="w-100"
                      onClick={() => handleShow("examen")}
                    >
                      Selectionner un examen
                    </Button>
                  </div>

                </div> */}
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="my-2">
                    <label className="form-label">Centre d'examen</label>
                    <SelectFinalOne
                      options={centre}
                      load={false}
                      value={"label"}
                      label={"label"}
                      choix={choix}
                      placeholder="choix centre"
                      setFormValues={setfiltre}
                      formValues={filtre}
                      setError={setError}
                      error={error}
                      name={"centre"}
                      seteventy={null}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                <label htmlFor="" className="form-label">Date d'examen :(choisir entre deux dates)</label>
                <Calendar className="w-100" style={{height:'35px'}} locale={"fr"} value={filtre?.date} name="date"  onChange={handleFiltre} selectionMode="range" readOnlyInput showButtonBar showIcon/>
                </div>
                {/* {isconnected.user().type_util !== "Auto-écoles" && (
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="my-2">
                      <label className="form-label">Auto-école</label>
                      <SelectFinalOne
                      options={autoecole}
                      load={false}
                      value={"label"}
                      label={"label"}
                      choix={choix}
                      placeholder="choix auto-école"
                      setFormValues={setfiltre}
                      formValues={filtre}
                      setError={setError}
                      error={error}
                      name={"auto"}
                      seteventy={null}
                    />
                    </div>
                  </div>
                )} */}
                {/* <div className="col-lg-4 col-12">
                  <div className="my-2">
                    <label className="form-label">Statut :</label>
                    <select className="form-control w-100"  value={filtre?.statut}
                            onChange={handleFiltre} name="statut">
                      <option value="0">Réussites</option>
                      <option value="1">Echecs</option>
                    </select>
                  </div>
                </div> */}
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-12">
          <Modal
            size="xl"
            style={{ zIndex: "30 !important" }}
            show={show.examen}
            onHide={() => handleShow("examen")}
            backdrop={"static"}
            scrollable
          >
            <Modal.Header>
              <h1>Choisir un examen</h1>
            </Modal.Header>
            <Modal.Body>
              <div className="">
           
              </div>
            </Modal.Body>
            <Modal.Footer>
              {" "}
              <button
                onClick={() => handleShow("examen")}
                className="btn btn-secondary"
              >
                <FontAwesomeIcon icon={faCancel} className="me-2" />
                Annuler
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="col-12">
          <DataTable
            title={<div className="mb-4 mt-3"></div>}
            className="custom-table"
            columns={columns}
            data={isconnected.user().type_util == "Auto-écoles" ?  list.filter(item => 
              item.candidats.some(candidat => candidat.id_org === isconnected.user().id_type)
          ) : list.filter((f) =>
              filtre.centre != undefined && f?.examen?.organisme?.nom_org != null
                ? f.examen.organisme.nom_org == filtre.centre
                : f
            ).filter((item) => {
              const date = filtre?.date ? filtre?.date : ["",""]
              const itemDate = new Date(item.examen.date_exam);
              const start = new Date(date[0]);
              const end = new Date(date[1]);
              return date[0] && date[1] ? itemDate >= start && itemDate <= end : item;
            })}
            progressPending={loadlistexamen}
            progressComponent={
              <div className="w-100 d-flex justify-content-center py-5">
                <TailSpin
                  height="80"
                  width="80"
                  color="#22577A"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            }
            contextMessage={{
              singular: "élement",
              plural: "élements",
              message: "sélectionnées",
            }}
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page",
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
          />
        </div>
      </div>
    </div>
  );
}
