import axios from "axios"
import { organisme_API } from "../config"
import isconnected from "../isconnected"
import { store } from "../Redux/store"


export const getCompte =  () => {
        axios.get(organisme_API+'/'+isconnected.user().id_type).then((rep)=>{
        console.log(rep)
          store.dispatch({type:'addcompte',payload:rep.data.organisme})
        })
}