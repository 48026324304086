import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import localforage from 'localforage';

// Importez vos reducers et combinez-les si nécessaire
import rootReducer from './reducers';

// Configuration de LocalForage comme mécanisme de stockage
localforage.config({
  name: 'my-redux-app', // Nom du store LocalForage
  storeName: 'redux-persist', // Nom du store utilisé pour Redux Persist
  driver: localforage.INDEXEDDB, // Mécanisme de stockage à utiliser (IndexedDB est recommandé)
});

// Configuration de Redux Persist avec LocalForage
const persistConfig = {
  key: 'root', // Clé pour l'objet stocké
  storage: localforage, // Utilisation de LocalForage comme mécanisme de stockage
};

// Création du reducer persistant
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Création du store Redux avec le reducer persistant
const store = createStore(persistedReducer);

// Initialisation de Redux Persist
const persistor = persistStore(store);

export { store, persistor };
