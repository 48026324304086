import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

function GetEndpoint(url, apidata, recharger) {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
 

  useEffect(() => {
    getData();
  }, [recharger]);

  const getData = async () => {
    setLoad(true);
    await axios
      .get(url)
      .then((response) => {
        console.log(response);
        let donnee = response.data[apidata];
        setData(donnee);
        setLoad(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des données:", error);
      });
  };

  return { data, load };
}

async function PostEndpoint(url,datapost,message,setError,setload,load ) {
 setload(true)
    await axios
      .put(url,datapost)
      .then((response) => {
        setload(false)
       
      })
      .catch((error) => {
        const errordata = error.response.data;
        const msg = errordata.message
        setload(false)
        if (msg) {
          setError(msg)
        }
        console.error("Erreur lors de la récupération des données:", error);

      });
}

export  {GetEndpoint, PostEndpoint};
