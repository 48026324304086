import { faEdit, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import InputMask from "react-input-mask"
import { toast } from "react-toastify";
import * as Yup from "yup"
import { PostEndpoint } from "../../../service/getApi/RequestEndpoint";
import { UserEndpoint } from "../../../service/config";

export default function EditProfileInfo({user,setrefresh}) {
const [load, setload] = useState(false)
const [open, setopen] = useState(false)

    const [formValues, setFormValues] = useState(user)
    const handleShow = ()=> setopen(!open)
    const [error,setError] = useState({})
    const validationSchema = Yup.object().shape({
        nom_util: Yup.string().required("Ce champs est obligatoire !"),
        prenom_util: Yup.string().required("Ce champs est obligatoire !"),
        email: Yup.string().email("adresse email invalide !").required("Ce champs est obligatoire !"),
        tel_util: Yup.string().required("Ce champs est obligatoire !"),
        
      });


      const verif = async () => {
        setError({});
        const champs = Object.keys(formValues);
        for (let i = 0; i < champs.length; i++) {
          const name_champs = champs[i];
          try {
            await validationSchema
              .validateAt(name_champs, formValues, { abortEarly: true })
              .then(() => {
              
            handleSubmit();
                
              });
          } catch (error) {
            if (error.path === name_champs) {
              setError({ [name_champs]: error.message });
              return;
            }
          }
        }
      };
      const handleChange = (e)=>{
        const {name, value} = e.target;
        setFormValues({...formValues, [name]:value})
        setError({...error,[name]:null})
      }
      const handleSubmit = async ()=>{
      await PostEndpoint(UserEndpoint+"/"+user?.id,formValues,"Modifié avec succées !",setError,setload,load).then(()=>{
        toast.success("Opération réussi !")
        setrefresh(Math.floor(Math.random() * 7) + 1)
      })
       
      }
  return (
    <div>
      <Button onClick={()=>handleShow()} className="p-3" variant="primary">
        <FontAwesomeIcon icon={faUserEdit} className="me-3" />
        Modifier Compte utilisateur
      </Button>
      <Modal show={open} onHide={handleShow} backdrop='static'>
        <Modal.Header closeButton>Mon compte</Modal.Header>
        <Modal.Body>
          <form className="w-100">
            <div className="row g-3">
              <div className="col-md-12 col-lg-6">
                <label className="form-label">Nom (*)</label>
                <input
                  onChange={handleChange}
                 
                  type="text"
                  className={
                    "form-control " +
                    (error.nom_util?.length > 0 && " is-invalid")
                  }
                  value={formValues.nom_util}
                  name="nom_util"
                  placeholder="Nom"
                />
                {error.nom_util &&
                  error.nom_util.map((element) => (
                    <p key={element} className="invalid-feedback p-0 m-1">
                      * {element}
                    </p>
                  ))}
              </div>
              <div className="col-md-12 col-lg-6">
                <label className="form-label">Prenom (*)</label>
                <input
                  onChange={handleChange}
                  type="text"
                  className={
                    "form-control " +
                    (error.prenom_util?.length > 0 && " is-invalid")
                  }
                  value={formValues.prenom_util}
                  name="prenom_util"
                  placeholder="Prenom"
                />
                {error.prenom_util &&
                  error.prenom_util.map((element) => (
                    <p key={element} className="invalid-feedback p-0 m-1">
                      * {element}
                    </p>
                  ))}
              </div>
            </div>
            <div className="my-3">
              <label className="form-label">Carte d'Identité Nationale </label>
              <InputMask
            
                onChange={handleChange}
                mask="999 999 999 999"
                type="text"
                className={
                  "form-control " +
                  (error.cin_util?.length > 0 && " is-invalid")
                }
                value={formValues.cin_util}
                name="cin_util"
                placeholder="EX: 201 031 000 000"
              />

              {error.cin_util &&
                error.cin_util.map((element) => (
                  <p key={element} className="invalid-feedback p-0 m-1">
                    * {element}
                  </p>
                ))}
            </div>
            <div className="my-3">
              <label className="form-label">Adresse email</label>
              <input
                onKeyUp={(e) => {
                  if (e.target.value !== "") {
                    setError({ ...error, email: [] });
                  }
                }}
                onChange={handleChange}
                type="text"
                className={
                  "form-control " + (error.email?.length > 0 && " is-invalid")
                }
                value={formValues.email}
                name="email"
                placeholder="Email Adress"
              />
              {error.email &&
                error.email.map((element) => (
                  <p key={element} className="invalid-feedback p-0 m-1">
                    * {element}
                  </p>
                ))}
            </div>
            <div className="my-3">
              <label className="form-label">Telephone (*)</label>
              <InputMask
                onKeyUp={(e) => {
                  console.log(e.target.value);
                  if (e.target.value === "") {
                    setError({
                      ...error,
                      tel_util: ["le champs telephone est requis"],
                    });
                  } else if (e.target.value !== "") {
                    setError({ ...error, tel_util: [] });
                  }
                }}
                onChange={handleChange}
                mask="+261 39 99 999 99"
                type="text"
                className={
                  "form-control " +
                  (error.tel_util?.length > 0 && " is-invalid")
                }
                value={formValues.tel_util}
                name="tel_util"
                placeholder="Telephone"
              />

              {error.tel_util &&
                error.tel_util.map((element) => (
                  <p key={element} className="invalid-feedback p-0 m-1">
                    * {element}
                  </p>
                ))}
            </div>
            {/* <div className="my-3">
              <label className="form-label">Mot de passe (*)</label>
              <div className="input-group ">
                <input
                  onKeyUp={(e) => {
                    if (e.target.value === "") {
                      setError({
                        ...error,
                        password: ["le champs mot de passe est requis"],
                      });
                    } else if (e.target.value !== "") {
                      setError({ ...error, password: [] });
                    }
                  }}
                  onChange={handleChange}
                  type={showdp ? "text" : "password"}
                  name="password"
                  value={formValues.password}
                  className={
                    "form-control " +
                    (error.password?.length > 0 && " is-invalid")
                  }
                  placeholder="Mot de passe"
                />
                <div
                  className="input-group-append"
                  onClick={() => setshowdp(!showdp)}
                >
                  <span className="input-group-text h-100">
                    <FontAwesomeIcon icon={showdp ? faEye : faEyeSlash} />
                  </span>
                </div>
                {error.password &&
                  error.password.map((element) => (
                    <p key={element} className="invalid-feedback p-0 m-1">
                      * {element}
                    </p>
                  ))}
              </div>
            </div>
            <div className="my-3">
              <label className="form-label">
                Mot de passe de confirmation (*)
              </label>
              <input
                onKeyUp={(e) => {
                  if (e.target.value === "") {
                    setError({
                      ...error,
                      password_confirmation: ["ce champs est requis"],
                    });
                  } else if (e.target.value !== "") {
                    setError({ ...error, password_confirmation: [] });
                  }
                }}
                onChange={handleChange}
                type="password"
                name="password_confirmation"
                value={formValues.password_confirmation}
                className={
                  "form-control " +
                  (error.password_confirmation?.length > 0 && " is-invalid")
                }
                placeholder="Confirmation du passe"
              />
              {error.password_confirmation && (
                <p className="invalid-feedback p-0 m-1">
                  * {error.password_confirmation}
                </p>
              )}
            </div> */}
          
          
          </form>
        </Modal.Body>
        <Modal.Footer>
        <div className="d-flex justify-content-center my-3">
              <button
                type="button"
                onClick={()=>handleSubmit()}
                className={
                  "btn btn-primary rounded-5 " + (load && "disabled")
                }
              >
                <FontAwesomeIcon className="me-2" icon={faEdit}/>Modifier mon compte
              </button>
            </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
