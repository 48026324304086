import { faMailBulk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ResendEmail_API } from '../../service/config'

export default function VerifyEmail() {
   const location = useLocation()
   const email = location.state?.email
   const [load,setload] = useState(false)
    console.log(email)
    const resend = async () => {
        setload(true)
        try {
            await axios.post(ResendEmail_API+email).then((response)=>{
                toast.success("E-mail envoyé !")
                setload(false)
            }).catch((error)=>{
                toast.error("erreur interne, veuillez réessayer plus tard !")
                setload(false)
            })
        } catch (error) {
            console.log(error)
            setload(false)
            toast.error("verifier votre connexion et réessayer !")
        }
    }
    return (
        <div className='d-flex align-items-center justify-content-center h-100'>
        <div className='p-4 card rounded-4'>
            <div className='card-header flex justify-content-center'><h3 className='text-center text-success'>Félicitation, vous êtes inscrit ! <FontAwesomeIcon icon={faMailBulk} /></h3></div>
            <div className='card-body'><p className='text-center'>Pour une derniere verification un email a été envoyer a <span className='fw-bold'>{email}</span> pour activer votre compte.</p></div>
            <div className='my-2 text-center'><p>Veuillez cliquer ci-dessous si vous n'avez pas reçu d'email</p><div className='d-flex flex-row flex-wrap align-items-center justify-content-center gap-1'><button className={'btn btn-success '+(load && "disabled")} type='button' onClick={()=>resend()}>Renvoyer email</button></div></div>
        </div>
      </div>
       

    )
}
