import {
  faAdd,
  faCheckCircle,
  faEdit,
  faEye,
  faFilterCircleXmark,
  faHouse,
  faShower,
  faTrash,
  faUndoAlt,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal, FormControl, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { Image } from "primereact/image";
import { Tag } from "primereact/tag";

import { useSelector } from "react-redux";
import isconnected from "../../../service/isconnected";
import { file_url, organisme_API } from "../../../service/config";
import SelectOne from "../../../outils/Select/selectCustom";
import { regions } from "../../../service/auth/UtilApi";
import SelectCustom from "../../../outils/Select/SelectComponent";
import { getCompte } from "../../../service/getApi/getCompte";

export default function EditProfileAuto({ dataPermis }) {
  const dataRedux = useSelector((state) => state.categ);
  const [data, setdata] = useState([]);
  const [show, setShow] = useState(false);
  const [mode, setmode] = useState(false);
  const [supp, setsupp] = useState(false);
  const [recharger, setrecharger] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [load, setLoad] = useState(false);
  const [formValues, setFormValues] = useState({
    nom_org: "",
    email_org: "",
    adresse_org: "",
    ville_org: "",
    province_org: "",
    cp_org: "",
    pays_org: "",
    web_org: "",
    tel_org: "",
    type_org: "",
    scan_agrement: null,
    numero_agrement: "",
    logo_org: null,
  });
  const [vide, setvide] = useState({
    nom_org: "",
    email_org: "",
    adresse_org: "",
    ville_org: "",
    province_org: "",
    cp_org: "",
    pays_org: "",
    web_org: "",
    tel_org: "",
    type_org: "",
    scan_agrement: null,
    numero_agrement: "",
    logo_org: null,
  });

  const [toggledClearRows, settoggledClearRows] = useState(false);
  const handleClearRows = () => {
    settoggledClearRows(!toggledClearRows);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlesup = () => setsupp(!supp);
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;

    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
  };

  const [image, setImage] = useState(null);
  const [imagescan, setImagescan] = useState(null);

  const onImageChange = (e) => {
    console.log(e.target.files[0].size);

    if (e.target.files && e.target.files[0]) {
      setFormValues({ ...formValues, [e.target.name]: e.target.files[0] });
      setError({ ...error, [e.target.name]: null });
    }
    if (e.target.name === "logo_org") {
      setImage(URL.createObjectURL(e.target.files[0]));
    } else {
      setImagescan(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleUpdate = () => {
    let formFinal = {}
    const apiErrors = {};
    setLoad(true);
  
      let form =  Object.keys(formValues)
    for (let i = 0; i < form.length; i++) {
        const element = form[i];
        if (element == "scan_agrement" || element == "logo_org") {
          if (typeof formValues[element] == null || typeof formValues[element] == "string") {
            const { [element]: _, ...formfiltrer } = formValues;
            formFinal = formfiltrer;
          }
         
        }
    }
    axios
    .post(organisme_API + `/${formFinal.id_org}?_method=PUT`, formFinal, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      console.log(response);
      setLoad(false);
      setShow(false);
      getCompte();
      toast.success("Modification reussi !");
      setImage(null);
      setFormValues(vide);
    })
    .catch((error) => {
      console.log(error);
      const msg = error.response.data;
      setLoad(false);
      if (msg) {
        let champs =  Object.keys(msg)
        for (let i = 0; i < champs.length; i++) {
          const element = champs[i];
          if (msg[element]) {
            apiErrors[element] = msg[element]
          }
        }
      }
    });
    setError(apiErrors);
  };

  return (
    <div className="">
      <Button
        variant="primary"
        className="p-3"
        onClick={() => {
          setFormValues(dataPermis);
          setShow(true);
          setImage(`${file_url}/${dataPermis.logo_org}`);
          setImagescan(`${file_url}/${dataPermis.scan_agrement}`);
        }}
      >
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        Modifier compte Organisme
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={"xl"}
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>
              <FontAwesomeIcon icon={faUserCircle} size="xl" className="me-2" />
              Information profile
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 d-flex align-item-stretch">
              <Form className="w-100" onSubmit={handleUpdate}>
                <div className="row">
                  <div className="col-12 col-lg-6 d-flex align-items-stretch">
                    <div className="card w-100">
                      <div className="card-body">
                        {mode && (
                          <div className="col-12 pb-3">
                            <Image
                              src={image}
                              alt="Image"
                              width="120"
                              preview
                            />
                          </div>
                        )}
                        <div className="col-12">
                          <label className="form-label">Nom</label>
                          <input
                            className={
                              "form-control " + (error.nom_org && "is-invalid")
                            }
                            name="nom_org"
                            value={formValues.nom_org}
                            onChange={handleChange}
                            type="text"
                            placeholder="Saisir nom "
                          />
                          {error.nom_org ? (
                            <div className="invalid-feedback">
                              {error.nom_org}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="my-3">
                          <label className="form-label">Email</label>
                          <input
                            className={
                              "form-control " +
                              (error.email_org && "is-invalid")
                            }
                            name="email_org"
                            value={formValues.email_org}
                            onChange={handleChange}
                            type="email"
                            placeholder="Saisir email"
                          />
                          {error.email_org ? (
                            <div className="invalid-feedback">
                              {error.email_org}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="row g-3">
                          <div className="col-12 col-lg-6">
                            <label className="form-label">Adresse</label>
                            <input
                              className={
                                "form-control " +
                                (error.adresse_org && "is-invalid")
                              }
                              name="adresse_org"
                              value={formValues.adresse_org}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir adresse"
                            />
                            {error.adresse_org ? (
                              <div className="invalid-feedback">
                                {error.adresse_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12 col-lg-6">
                            <label className="form-label">ville</label>
                            <input
                              className={
                                "form-control " +
                                (error.ville_org && "is-invalid")
                              }
                              name="ville_org"
                              value={formValues.ville_org}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir ville"
                            />
                            {error.ville_org ? (
                              <div className="invalid-feedback">
                                {error.ville_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12 col-lg-6">
                            <label className="form-label">Région</label>
                            <SelectOne
                              options={regions}
                              load={false}
                              choix={formValues.province_org}
                              placeholder="choix région"
                              setFormValues={setFormValues}
                              formValues={formValues}
                              setError={setError}
                              error={error}
                              name={"province_org"}
                            />
                            {error.province_org ? (
                              <div className="invalid-feedback">
                                {error.province_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12 col-lg-6">
                            <label className="form-label">Code postal</label>
                            <input
                              className={
                                "form-control " + (error.cp_org && "is-invalid")
                              }
                              name="cp_org"
                              value={formValues.cp_org}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir code postal"
                            />
                            {error.cp_org ? (
                              <div className="invalid-feedback">
                                {error.cp_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12">
                            <label className="form-label">Pays</label>
                            <input
                              className={
                                "form-control " +
                                (error.pays_org && "is-invalid")
                              }
                              name="pays_org"
                              value={formValues.pays_org}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir pays"
                            />
                            {error.pays_org ? (
                              <div className="invalid-feedback">
                                {error.pays_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12">
                            <label className="form-label">Site web</label>
                            <input
                              className={
                                "form-control " +
                                (error.web_org && "is-invalid")
                              }
                              name="web_org"
                              value={formValues.web_org}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir Site web"
                            />
                            {error.web_org ? (
                              <div className="invalid-feedback">
                                {error.web_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 d-flex align-items-stretch">
                    <div className="card w-100">
                      <div className="card-body">
                        <div className="row g-3 align-items-center">
                       
                          {
                             dataPermis.type_org == 'Auto-écoles' &&
                            <>
                            <div className="col-12">
                            <label className="form-label">
                              Numéro agrément
                            </label>
                            <input
                              className={
                                "form-control " +
                                (error.numero_agrement && "is-invalid")
                              }
                              name="numero_agrement"
                              value={formValues.numero_agrement}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir Numéro agrément"
                            />
                            {error.numero_agrement ? (
                              <div className="invalid-feedback">
                                {error.numero_agrement}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-12">
                            <label className="form-label">
                              Délai d'agrément
                            </label>
                            <input
                              className={
                                "form-control " +
                                (error.delais_agrement && "is-invalid")
                              }
                              name="delais_agrement"
                              value={formValues.delais_agrement}
                              onChange={handleChange}
                              type="date"
                              placeholder="le délai d'agrément"
                            />
                            {error.delais_agrement ? (
                              <div className="invalid-feedback">
                                {error.delais_agrement}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className="col-12">
                            <div className="row">
                              <div className="col-6">
                                <label className="form-label">
                                  Scan agrément{" "}
                                </label>
                                <input
                                  className={
                                    "form-control " +
                                    (error.scan_agrement && "is-invalid")
                                  }
                                  name="scan_agrement"
                                  id="scan_agrement"
                                  type="file"
                                  onChange={onImageChange}
                                  accept="image/*"
                                />
                                {error.scan_agrement && (
                                  <div className="invalid-feedback">
                                    {error.scan_agrement}
                                  </div>
                                )}
                              </div>
                              <div className="col-6">
                                <Image
                                  src={imagescan}
                                  alt="Image"
                                  width="70"
                                  preview
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <label className="form-label">
                              Catégorie permis
                            </label>
                            <SelectCustom
                              options={dataRedux}
                              load={false}
                              choix={formValues.categ_permis}
                              placeholder="choix catégories"
                              setFormValues={setFormValues}
                              formValues={formValues}
                              setError={setError}
                              error={error}
                              name={"categ_permis"}
                            />
                          </div>
                            </>
                          }
                          <div className="col-12">
                            <label className="form-label">Telephone (*)</label>
                            <InputMask
                              onKeyUp={(e) => {
                                console.log(e.target.value);
                                if (e.target.value === "") {
                                  setError({
                                    ...error,
                                    tel_org: ["le champs telephone est requis"],
                                  });
                                } else if (e.target.value !== "") {
                                  setError({ ...error, tel_org: [] });
                                }
                              }}
                              onChange={handleChange}
                              mask="+261 39 99 999 99"
                              type="text"
                              className={
                                "form-control " +
                                (error.tel_org?.length > 0 && " is-invalid")
                              }
                              value={formValues.tel_org}
                              name="tel_org"
                              placeholder="Telephone"
                            />

                            {error.tel_org && (
                              <p className="invalid-feedback p-0 m-1">
                                {error.tel_org}
                              </p>
                            )}
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-6">
                                <label className="form-label">Logo </label>
                                <input
                                  className={
                                    "form-control " +
                                    (error.logo_org && "is-invalid")
                                  }
                                  name="logo_org"
                                  id="logo_org"
                                  type="file"
                                  onChange={onImageChange}
                                  accept="image/*"
                                />
                                {error.logo_org && (
                                  <div className="invalid-feedback">
                                    {error.logo_org}
                                  </div>
                                )}
                              </div>
                              <div className="col-6 d-flex align-items-end">
                                <Image
                                  src={image}
                                  alt="Image"
                                  width="40"
                                  preview
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button
            variant="primary"
            disabled={load}
            onClick={() => handleUpdate()}
          >
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
