const Cherche = (dat, row, champs, ret) => {
  if (row) {
    for (let i = 0; i < dat.length; i++) {
      const element = dat[i];
      if (element[champs] == row[champs] ) {
        return element[ret];
      }
    }
  }
  };
export default Cherche;