import {
    faAdd,
    faCheckCircle,
    faCheckSquare,
    faEdit,
    faEraser,
    faFilter,
    faHouse,
    faRotate,
    faTrash,
    faUndoAlt,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import axios from "axios";
  import React, { useState } from "react";
  import { Tag } from "primereact/tag";
  import { useEffect } from "react";
  import {
    Button,
    Modal,
    FormControl,
    Form,
    Overlay,
    OverlayTrigger,
    Tooltip,
    Popover,
  } from "react-bootstrap";
  import DataTable from "react-data-table-component";
  import { TailSpin } from "react-loader-spinner";
  import { toast } from "react-toastify";
  import InputMask from "react-input-mask";
  
  import { useSelector } from "react-redux";
import CardHover from "../../../../outils/HoverCard/CardHover";
import { listCandidatValidForCentre } from "../../../../service/config";
 
 
  
  export default function CandidatByCentre() {
    const abortController = new AbortController();
    const columns = [
      // {
      //   name: "ID",
      //   selector: (row) => row.id_cand,
      //   sortable: true,
      // },
      {
        width: "23%",
        name: "Nom et prenom",
        selector: (row) => (
      <CardHover candidat={row}/>
        ),
        sortable: true,
      },
      {
        name: "Permis",
        selector: (row) => row.categ_permis,
        sortable: true,
      },
      {
        name: "Téléphone",
        selector: (row) => row.tel_cand,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email_cand,
        sortable: true,
      },
      {
        name: "Date d'inscription",
        selector: (row) => row.date_insc_cand,
        sortable: true,
      },
      {
        name: "Adresse",
        selector: (row) => row.adresse_cand,
        sortable: true,
      }
  
   
    ];
   
    const columns_validPratique = [
      // {
      //   name: "ID",
      //   width:'15px',
      //   selector: (row) => row.id_cand,
      //   sortable: true,
      // },
      {
          width: "23%",
          name: "Nom et prenom",
          selector: (row) => (
        <CardHover candidat={row}/>
          ),
          sortable: true,
        },
      {
        name: "Permis",
        selector: (row) => row.categ_permis,
        sortable: true,
      },
      {
        name: "Date de naissance",
        selector: (row) => row.naiss_cand,
        sortable: true,
      },
      {
        name: "Ville",
        selector: (row) => row.ville_cand,
        sortable: true,
      },
      {
        name: "action",
        cell: (row) => (
          <div className="">
            <button
              className="btn btn-primary"
              onClick={() => {
                let mis = candidatSelect.filter(
                  (el) => el.id_cand != row.id_cand
                );
                setcandidatSelect(mis);
              }}
            >
              <FontAwesomeIcon icon={faEraser} className="me-2" /> retirer
            </button>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];
  
    const confCandPratique = [
      // {
      //   name: "ID",
      //   selector: (row) => row.id_cand,
      //   sortable: true,
      // },
      {
        width: "23%",
        name: "Nom et prenom",
        selector: (row) => (
      <CardHover candidat={row}/>
        ),
        sortable: true,
      },
      {
        name: "Permis",
        selector: (row) => row.categ_permis,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email_cand,
        sortable: true,
      },
      {
        name: "Date d'inscription",
        selector: (row) => row.date_insc_cand,
        sortable: true,
      },
      {
        name: "Adresse",
        selector: (row) => row.adresse_cand,
        sortable: true,
      }
    ];
    const [data, setdata] = useState([
     
    ]);
    const dataReduxTypeExamen = useSelector((state) => state.listtypeexam);
  
    const [dataOrg, setdataorg] = useState([]);
    const [show, setShow] = useState(false);
    const [ModalShow, setModalShow] = useState(false);
    const [image, setImage] = useState(false);
    const [mode, setmode] = useState(false);
    const [supp, setsupp] = useState(false);
    const [textfiltre, settextfiltre] = useState("");
    const [toggledClearRows, settoggledClearRows] = useState(false);
    const [showvalid, setshowvalid] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [candidatSelect, setcandidatSelect] = useState([]);
    const [categorie, setcategorie] = useState([]);
    const [load, setLoad] = useState(false);
    const [rotation, setRotation] = useState(0);
    const handleClearRows = () => {
      settoggledClearRows(!toggledClearRows);
    };
  
    const ModalShowclose = () => setModalShow(!ModalShow);
    const handleRotate = () => {
      setRotation(rotation + 90);
    };
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handlesup = () => setsupp(!supp);
    const handleValidation = () => setshowvalid(!showvalid);
    const [error, setError] = useState([]);
  
    const [imagefull, setimagefull] = useState(null);
    const [cin, setcin] = useState(null);
    const [certificat, setcertificat] = useState(null);
  
    // const getCandidatBeforeValidation = () => {
    //   setLoad(true);
    //   axios
    //     .get(getCandidatBeforeVld)
    //     .then((rep) => {
    //       console.log("first");
    //       console.log(rep);
    //       setdata(rep.data.data);
    //       setLoad(false);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };

  

  

    const getCandidats = async () => {
      setLoad(true);
       await axios
        .get(listCandidatValidForCentre, {
          signal: abortController.signal,
        })
        .then((reponse) => {
          console.log(reponse);
          setdata(reponse.data.data);
          setLoad(false);
        })
        .catch((error) => {
          toast.error(error.response.message);
          setLoad(false);
        });
    };
    const datafiltrer = data.filter((item) => {
      return (
        (item.id_cand && item.id_cand === textfiltre) ||
        (item.nom_cand &&
          item.nom_cand.toLowerCase().includes(textfiltre.toLowerCase()))
      );
    });
    const handleSearch = (e) => {
      settextfiltre(e.target.value);
    };
    useEffect(() => {
      getCandidats();
      return () => {
          abortController.abort();
        };
    }, []);
    return (

        <div className="container-lg container-fluid pt-3 ">
        <div className="mb-1 d-flex flex-row align-items-center justify-content-between">
          <div className="">
            <h2>
            Candidats pour l'examen théorique{" "}
            </h2>
          </div>
        </div>
     
    
  
        <div className="mt-3">
        <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size={"md"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Filtre</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <DataTable
                className="custom-table"
                title={<p className="fs-4">Liste des candidats</p>}
                columns={columns}
                data={data.filter((el) => el.active_cand != "Activer")}
                selectableRows
                clearSelectedRows={toggledClearRows}
                onSelectedRowsChange={(rows) => {
                  setSelectedRows(rows.selectedRows.map((row) => row.id_cand));
                  setcandidatSelect(rows.selectedRows.map((row) => row));
                }}
                progressPending={load}
                selectedRow={selectedRows}
                contextActions={
                  <div className="d-flex">
                    <Button
                      className="me-2"
                      variant="secondary"
                      onClick={() => {
                        settoggledClearRows(!toggledClearRows);
                      }}
                    >
                      <FontAwesomeIcon icon={faUndoAlt} className="" />
                    </Button>
                    <button
                      className="btn btn-cyan me-2"
                      onClick={() => {
                        handleValidation();
                      }}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                      Valider
                    </button>
                  </div>
                }
                progressComponent={
                  <div className="w-100 d-flex justify-content-center py-5">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#22577A"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                }
                contextMessage={{
                  singular: "élement",
                  plural: "élements",
                  message: "sélectionnées",
                }}
                pagination
                paginationPerPage={5}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page",
                }}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
              />
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Fermer
                </Button>
                <Button
                  variant="primary"
                  disabled={load}
                  onClick={() => (mode ? handleUpdate() : handleSubmit())}
                >
                  {mode ? <>Modifier</> : <>Enregistrer</>}
                </Button>
              </Modal.Footer> */}
        </Modal>
        <Modal
          show={showvalid}
          onHide={() => {
            handleValidation();
            handleClearRows();
          }}
          backdrop="static"
          keyboard={false}
          size={"xl"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Les candidats pour l'examen théorique</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {/* <div className="col-12 d-flex align-item-stretch">
                    <div className="w-100 my-2 px-3">
                      <p>Epreuve précedemment raté pour ceux qui fait le repassage</p>
                      <select
                        className={"form-control " + (error.nom_org && "is-invalid")}
                        name="nom_org"
                      >
                        <option value="">Epreuve du 10-10-22</option>
                        <option value="">Epreuve du 10-12-22</option>
                        <option value="">Epreuve du 9-03-22</option>
                      </select>
                      {error.nom_org ? (
                        <div className="invalid-feedback">{error.nom_org}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div> */}
              <div className="col-12">
                <DataTable
                  className="custom-table"
                  title={
                    <p className="fs-2">Liste des candidats sélectionnées</p>
                  }
                  columns={columns_validPratique}
                  data={candidatSelect}
                //   contextActions={
                //     <button
                //       className="btn btn-cyan me-2"
                //       onClick={() => {
                //         ValiderCandidat();
                //       }}
                //     >
                //       <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                //       Valider
                //     </button>
                //   }
                  progressComponent={
                    <div className="w-100 d-flex justify-content-center py-5">
                      <TailSpin
                        height="80"
                        width="80"
                        color="#22577A"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  }
                  contextMessage={{
                    singular: "élement",
                    plural: "élements",
                    message: "sélectionnées",
                  }}
                  onSelectedRowsChange={(rows) => {
                    setSelectedRows(rows);
                  }}
                  pagination
                  paginationPerPage={5}
                  paginationComponentOptions={{
                    rowsPerPageText: "Lignes par page",
                  }}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleValidation();
                handleClearRows();
              }}
            >
              Fermer
            </Button>
          
          </Modal.Footer>
        </Modal>
        <Modal show={ModalShow} onHide={ModalShowclose} size="md">
          <Modal.Body>
            <img
              src={imagefull}
              onError={ModalShowclose}
              alt={"version grandeur nature"}
              style={{
                width: "100%",
                height: "100%",
                transform: `rotate(${rotation}deg)`,
              }}
            />
            <div className="w-100">
              <button
                type="button"
                onClick={handleRotate}
                className="btn btn-primary"
              >
                Tourner <FontAwesomeIcon className="ms-2" icon={faRotate} />
              </button>
            </div>
          </Modal.Body>
        </Modal>
       
        <div className="row g-0">
          <div className="col-12 d-flex flex-column">
            <div className="card-body">
              <div className="">
                <DataTable
                  className="custom-table"
                  title={
                    <div className="d-flex gap-3 justify-content-between align-items-center">
                      <p className="h3">
                        {"La liste des candidats affectée a l'epreuve"}
                      </p>
                    </div>
                  }
                  columns={confCandPratique}
                  data={datafiltrer}
                  selectableRows={true}
                  clearSelectedRows={toggledClearRows}
                  onSelectedRowsChange={(rows) => {
                    setSelectedRows(rows.selectedRows.map((row) => row.id_cand));
                    setcandidatSelect(rows.selectedRows.map((row) => row));
                  }}
                  progressPending={load}
                  selectedRow={selectedRows}
                  contextActions={
                    <div className="d-flex">
                      <Button
                        className="me-2"
                        variant="secondary"
                        onClick={() => {
                          settoggledClearRows(!toggledClearRows);
                        }}
                      >
                        <FontAwesomeIcon icon={faUndoAlt} className="" />
                      </Button>
                      <button
                        className="btn btn-cyan me-2"
                        onClick={() => {
                          handleValidation();
                        }}
                      >
                        <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                        voir la liste des selectionné
                      </button>
                    </div>
                  }
                  progressComponent={
                    <div className="w-100 d-flex justify-content-center py-5">
                      <TailSpin
                        height="80"
                        width="80"
                        color="#22577A"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  }
                  contextMessage={{
                    singular: "élement",
                    plural: "élements",
                    message: "sélectionnées",
                  }}
                  pagination
                  paginationPerPage={5}
                  paginationComponentOptions={{
                    rowsPerPageText: "Lignes par page",
                  }}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </div>
              <div className="col-12"></div>
            </div>
          </div>
        </div>
      </>
        </div>
      </div>





    
    );
  }
  