import axios from "axios";
import React, { useEffect, useState } from "react";
import { Bars, TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { file_url, SearchModuleLesson_API } from "../../service/config";

export default function RecherchePage(props) {
  let { id } = useParams();
  const [result, setresult] = useState([]);
  const [load, setload] = useState(true);
  const search = async () => {
    setload(true);
    try {
      await axios
        .post(SearchModuleLesson_API, {
          "search": id,
        })
        .then((reponse) => {
          console.log(reponse);
          setresult(reponse.data);
          setload(false);
        })
        .catch((error) => {
          console.log(error);
          setload(false);
        });
    } catch (error) {}
  };
  useEffect(() => {
    search();
  }, [props.rech]);
  return (
    <div className="bg-light h-100">
      <div className="container pb-4 pt-3 h-100">
        <h2>Resultat de "{id}"</h2>

        <div className="row p-2">
          {load ? (
            <div className="col-12 d-flex justify-content-center align-items-center h-100 py-5">
              <Bars
                height="80"
                width="80"
                color="#22577A"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : result.lessons.length ==0 && result.modules.length == 0 ? <div className="display-5 text-center">Aucun résultat</div>: <>
         { result.modules.map((el,index)=>
            <div key={index} className="col-12 my-2">
              <div className="card">
                <div className="row p-2">
                  <div className="col-3 d-flex align-items-center " >
                  <span
                      className="avatar w-100 h-100"
                      style={{
                        backgroundImage: `url(${file_url}/${el.img_mod})`,backgroundSize:'cover'
                      }}
                    ></span>
                  </div>
                  <div className="col-lg">
                    <h3>{el.titre_mod}</h3>
                    <p className="my-2">
                    {el.desc_mod}
                    </p>
                    <div className="my-2 d-flex justify-content-end">
                        <Link  to={`/details/${el.id_mod}`} className="text-decoration-none " >
                        <button className="btn btn-outline-primary px-4">
                        Voir
                      </button>
                        </Link>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}{
          result.lessons.map((el,index)=>
            <div key={index} className="col-12 my-2">
              <div className="card">
                <div className="row p-2">
                  <div className="col-3 d-flex align-items-center " >
                  <span
                      className="avatar w-100 h-100"
                      style={{
                        backgroundImage: `url(${file_url}/${el.img_les})`,backgroundSize:'cover'
                      }}
                    ></span>
                  </div>
                  <div className="col-lg">
                    <h3>{el.titre_les}</h3>
                    <p className="my-2">
                    {el.desc_les}
                    </p>
                    <div className="my-2 d-flex justify-content-end">
                        <Link  to={`/lessons/${el.id_les}`} state={{rech:"oui"}} className="text-decoration-none " >
                        <button className="btn btn-outline-primary px-4">
                        Voir
                      </button>
                        </Link>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
          }
          </>}
        </div>
      </div>
    </div>
  );
}
