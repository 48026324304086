import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { generateRandomString } from "../../service/RandomString";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { sortByProperty } from "../SortByProperty";

export default function SelectCustom(props) {
  const animatedComponents = makeAnimated();
  let text = "";
  const [ito, setIto] = useState([]);
  const SortedValue = sortByProperty(props.options)
  const change = (choice) => {
    setIto(choice);
    var nb = 0;
    text = "";
    choice.forEach((element) => {
      nb++;
      text += element.label + ",";
    });
    props.setFormValues({ ...props.formValues, [props.name]: text });
    props.setError({ ...props.error, [props.name]: null });
    props?.seteventy(generateRandomString(5));
  };

  useEffect(() => {
    if (props.choix == null) {
      setIto(null);
    } else {
      let categ = props.choix.split(",");
      let valueCat = [];
      categ.map((i) => i != "" && valueCat.push({ value: i, label: i }));
      setIto(valueCat);
    }
  }, [props.choix]);
  return (
    <div className="d-flex align-items-center gap-2">
      <Select
        closeMenuOnSelect
        components={animatedComponents}
        className="w-75"
        isMulti
        placeholder="choisir ici"
        options={SortedValue}
        name="id_categ"
        value={ito}
        onChange={(choice) => change(choice)}
        noOptionsMessage={() => "plus d'options"}
        isDisabled={props.load}
        isLoading={props.load}
      />
      <Button variant="primary" onClick={() => change(props.options)}>
        <FontAwesomeIcon icon={faCheckSquare} />
      </Button>
      {ito != null && ito.length >0  && <Button
        variant="danger"
        onClick={() => {
          setIto([]);
          props.setFormValues({ ...props.formValues, [props.name]: "" });
          props.setError({ ...props.error, [props.name]: null });
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </Button>}
    </div>
  );
}
