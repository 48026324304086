import React, { useEffect, useState } from "react";
import message from "../../../assets/svg/Car crash-pana.svg";
import { Bounce, Fade } from "react-awesome-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faBullhorn,
  faClockFour,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import image from "../../../assets/image/imgtest/image";
import axios from "axios";
import { file_url, SENSI_API } from "../../../service/config";
import { Link, useNavigate } from "react-router-dom";
import { Carousel } from "primereact/carousel";
import { Skeleton } from "primereact/skeleton";


import Carrousel from "./Carrousel";
export default function Section4() {

  const test =
    " Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut oditaut fugit sed quia.";
  const test1 =
    " Nemo enim ipsam voluptatem quia ipsam voluptatem quia ipsam voluptatem ipsam voluptatem quia ipsam voluptatem quia  quia  voluptas sit aspernatur aut oditaut fugit sed quia.";
  function truncate(str) {
    return str.length > 10 ? str.substring(0, 150) + "..." : str;
  }
  const navigate = useNavigate();
  const [load, setload] = useState(true);
  const [sensib, setSensib] = useState([]);
  const [filter, setfilter] = useState("asc");
  const [chiffre, setChiffre] = useState([
    { id: "1" },
    { id: "1" },
    { id: "1" },
    { id: "1" },
  ]);
  const skeletonTemplate = () => {
    return (
      <div
        className="p-d-flex p-jc-center p-ai-center p-1"
        style={{ height: "400px" }}
      >
        <Skeleton
          shape="rectangular"
          width="350px"
          height="100%"
          style={{ marginRight: "2rem" }}
        />
      </div>
    );
  };
  const getsensib = async () => {
    setload(true);
    try {
      await axios
        .get(SENSI_API)
        .then((reponse) => {
          console.log(reponse);
          let data = reponse.data.data
         
          setSensib(data);
          setload(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getsensib();
  }, [filter]);
  return (
    <div className="container-lg container-fluid-md pt-4">
    <div className="my-4 d-flex gap-5 justify-content-center">
<FontAwesomeIcon icon={faBullhorn} className="text-cyan" size="3x"/>
      <p className="h1 align-self-end">Message du ministère</p>
    </div>
    <div>
  </div>
 
    <div className="container-lg container-fluid">
      {load ? (
        <>
          <Carousel
            value={[null, null, null]}
            numVisible={3}
            numScroll={1}
            itemTemplate={skeletonTemplate}
            showIndicators={false}
            showNavigators={false}
          />
        </>
      ) : sensib.length === 0 ? (
        <div className="w-100  my-5 p-5">
          <p className="display-5 fw-light text-secondary text-center">
            Aucun message du ministère
          </p>
        </div>
      ) : (
        <div className="">
          <Carrousel type='sensi' data={sensib} load={load}/>
        </div>
      )}
    </div>
    {/* <div className="d-flex justify-content-end mt-4 mb-2">
      {load === false && sensib.filter((el,i)=> el.type_sens != "Public").length > 3 ? (
        <div
          onClick={() => navigate("/All/messages")}
          className="Nav-link btn btn-primary rounded-0 text-decoration-none"
        >
          Voir plus
        </div>
      ) : null}
    </div> */}
  </div>
  );
}
