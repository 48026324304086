import {
    faAdd,
    faCheckCircle,
    faCircleNotch,
    faEdit,
    faEye,
    faFilterCircleXmark,
    faHouse,
    faShower,
    faTrash,
    faUndoAlt,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import axios from "axios";
  import React, { useState } from "react";
  import { useEffect } from "react";
  import { Button, Modal, FormControl, Form } from "react-bootstrap";
  import DataTable from "react-data-table-component";
  import { TailSpin } from "react-loader-spinner";
  import { toast } from "react-toastify";

  import InputMask from "react-input-mask";
  import { Image } from "primereact/image";
  import { Tag } from "primereact/tag";
  
  import { useSelector } from "react-redux";
import ChangerStatusAutoEcole from "../../Auto-ecole/ChangerStatusAutoEcole";
import { file_url, organisme_API } from "../../../../service/config";
import isconnected from "../../../../service/isconnected";
import { regions } from "../../../../service/auth/UtilApi";
import SelectOne from "../../../../outils/Select/selectCustom";

  
  export default function CentreExamen() {
    const dataRedux = useSelector((state) => state.categ);
    const [filtre, setfiltre] = useState({
      nom: "",
      region: "",
      ville: "",
      type: "",
    });
    const [videfiltre, setvidefiltre] = useState({
      nom: "",
      region: "",
      ville: "",
      type: "",
    });
    const handleFilter = (e) => {
      e.preventDefault();
      let { name, value } = e.target;
      setfiltre({ ...filtre, [name]: value });
    };
    const columns = [
      {
        name: "logo",
        selector: (row) => (
          <Image src={file_url + row.logo_org} alt="Image" width="30" preview />
        ),
        sortable: true,
      },
      {
        name: "nom",
        selector: (row) => row.nom_org,
        sortable: true,
        width: "13%",
        wrap:true,
      },
      {
        name: "Type",
        selector: (row) => row.type_org =='Examinateurs' ? "Centre d'examen" :row.type_org,
        sortable: true,
        wrap:true,
        width: "13%",
      },
      {
        name: "email",
        selector: (row) => row.email_org,
        sortable: true,
        wrap: true,
        width: "23%",
      },
      {
        name: "téléphone",
        selector: (row) => row.tel_org,
        sortable: true,
        wrap: true,
        width: "13%",
      },
      {
        name: "province",
        selector: (row) => row.province_org,
        sortable: true,
        wrap:true
      },
      {
        width: "105px",
        name: "statut",
        selector: (row) => (
          <ChangerStatusAutoEcole row={row} setrecharger={setrecharger} />
        ),
        sortable: true,
      },
      {
        name: "Détails",
        cell: (row) => (
          <div className="d-flex gap-1">
            <button
              className="btn btn-primary"
              onClick={() => {
                // alert(JSON.stringify(row))
                setmode(true);
                setFormValues(row);
                setShow(true);
                setImage(`${file_url}/${row.logo_org}`);
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];
    const abortController = new AbortController();
    const [data, setdata] = useState([]);
    const [show, setShow] = useState(false);
    const [mode, setmode] = useState(false);
    const [supp, setsupp] = useState(false);
    const [recharger, setrecharger] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [load, setLoad] = useState(false);
    const [formValues, setFormValues] = useState({
      nom_org: "",
      email_org: "",
      adresse_org: "",
      ville_org: "",
      province_org: "",
      cp_org: "",
      pays_org: "",
      web_org: "",
      tel_org: "",
      type_org: "",
      scan_agrement: null,
      numero_agrement: "",
      logo_org: null,
    });
    const [vide, setvide] = useState({
      nom_org: "",
      email_org: "",
      adresse_org: "",
      ville_org: "",
      province_org: "",
      cp_org: "",
      pays_org: "",
      web_org: "",
      tel_org: "",
      type_org: "",
      scan_agrement: null,
      numero_agrement: "",
      logo_org: null,
    });
  
    const [toggledClearRows, settoggledClearRows] = useState(false);
    const handleClearRows = () => {
      settoggledClearRows(!toggledClearRows);
    };
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handlesup = () => setsupp(!supp);
    const [error, setError] = useState([]);
    const handleChange = ({ currentTarget }) => {
      const { name, value } = currentTarget;
      if (isconnected.user().type_util != "MTM") {
        setFormValues({ ...formValues, [name]: value });
        setError({ ...error, [name]: null });
      }
    };
  
    const [image, setImage] = useState(null);
    const [imagescan, setImagescan] = useState(null);
  
    const onImageChange = (e) => {
      console.log(e.target.files[0].size);
      if (isconnected.user().type_util != "MTM") {
        if (e.target.files && e.target.files[0]) {
          setFormValues({ ...formValues, [e.target.name]: e.target.files[0] });
          setError({ ...error, [e.target.name]: null });
        }
        if (e.target.name === "logo_org") {
          setImage(URL.createObjectURL(e.target.files[0]));
        } else {
          setImagescan(URL.createObjectURL(e.target.files[0]));
        }
      }
    };
  
    const handleSubmit = () => {
      if (!formValues.nom_org) {
        setError({ ...error, nom_org: "ce champs est réquis !" });
        const input = document.getElementsByName("nom_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.email_org) {
        setError({ ...error, email_org: "ce champs est réquis !" });
        const input = document.getElementsByName("email_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.adresse_org) {
        setError({ ...error, adresse_org: "ce champs est réquis !" });
        const input = document.getElementsByName("adresse_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.ville_org) {
        setError({ ...error, ville_org: "ce champs est réquis !" });
        const input = document.getElementsByName("ville_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.province_org) {
        setError({ ...error, province_org: "ce champs est réquis !" });
        const input = document.getElementsByName("province_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.cp_org) {
        setError({ ...error, cp_org: "ce champs est réquis !" });
        const input = document.getElementsByName("cp_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.pays_org) {
        setError({ ...error, pays_org: "ce champs est réquis !" });
        const input = document.getElementsByName("pays_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else {
        setLoad(true);
        const apiErrors = {};
        axios
          .post(organisme_API, formValues, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            setLoad(false);
            setShow(false);
            toast.success("enregistrement reussi !");
            getListAuto();
            setImage(null);
            setFormValues(vide);
          })
          .catch((error) => {
            console.log(error);
            const msg = error.response.data;
            setLoad(false);
            if (msg) {
              apiErrors.nom_org = msg.nom_org;
              apiErrors.active_org = msg.active_org;
              apiErrors.email_org = msg.email_org;
              apiErrors.adresse_org = msg.adresse_org;
              apiErrors.ville_org = msg.ville_org;
              apiErrors.province_org = msg.province_org;
              apiErrors.cp_org = msg.cp_org;
              apiErrors.pays_org = msg.pays_org;
              apiErrors.web_org = msg.web_org;
              apiErrors.tel_org = msg.tel_org;
              apiErrors.type_org = msg.type_org;
              apiErrors.logo_org = msg.logo_org;
            }
            if (error.response.status == 500) {
              toast.error(error.response.message);
              getListAuto();
              setShow(false);
              setFormValues(vide);
              setImage(null);
            }
          });
        setError(apiErrors);
      }
    };
    const handleUpdate = () => {
      if (!formValues.nom_org) {
        setError({ ...error, nom_org: "ce champs est réquis !" });
        const input = document.getElementsByName("nom_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.email_org) {
        setError({ ...error, email_org: "ce champs est réquis !" });
        const input = document.getElementsByName("email_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.adresse_org) {
        setError({ ...error, adresse_org: "ce champs est réquis !" });
        const input = document.getElementsByName("adresse_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.ville_org) {
        setError({ ...error, ville_org: "ce champs est réquis !" });
        const input = document.getElementsByName("ville_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.province_org) {
        setError({ ...error, province_org: "ce champs est réquis !" });
        const input = document.getElementsByName("province_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.cp_org) {
        setError({ ...error, cp_org: "ce champs est réquis !" });
        const input = document.getElementsByName("cp_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.pays_org) {
        setError({ ...error, pays_org: "ce champs est réquis !" });
        const input = document.getElementsByName("pays_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.tel_org) {
        setError({ ...error, tel_org: "ce champs est réquis !" });
        const input = document.getElementsByName("tel_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (!formValues.type_org) {
        setError({ ...error, type_org: "ce champs est réquis !" });
        const input = document.getElementsByName("type_org")[0];
        const inputTop = input.offsetTop;
        window.scrollTo(0, inputTop);
        setLoad(false);
      } else if (typeof formValues.logo_org === "string") {
        const { logo_org, ...formfiltrer } = formValues;
        setFormValues(formfiltrer);
      } else {
        // alert(JSON.stringify(formValues))
        setLoad(true);
        const apiErrors = {};
        axios
          .post(organisme_API + `/${formValues.id_org}?_method=PUT`, formValues, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            setLoad(false);
            setShow(false);
            toast.success("Modification reussi !");
            getListAuto();
            setImage(null);
            setFormValues(vide);
          })
          .catch((error) => {
            console.log(error);
            const msg = error.response.data;
            setLoad(false);
            if (msg) {
              apiErrors.nom_org = msg.nom_org;
              apiErrors.active_org = msg.active_org;
              apiErrors.email_org = msg.email_org;
              apiErrors.adresse_org = msg.adresse_org;
              apiErrors.ville_org = msg.ville_org;
              apiErrors.province_org = msg.province_org;
              apiErrors.cp_org = msg.cp_org;
              apiErrors.pays_org = msg.pays_org;
              apiErrors.web_org = msg.web_org;
              apiErrors.tel_org = msg.tel_org;
              apiErrors.type_org = msg.type_org;
              apiErrors.logo_org = msg.logo_org;
            }
          });
        setError(apiErrors);
      }
    };
  
    const getAllOrganisme = () => {
      setLoad(true);
      axios
        .get(
          organisme_API +
            `?type_org=Examinateurs`,{
              signal: abortController.signal,
              }
        )
        .then((rep) => {
          console.log(rep);
          setdata(rep.data.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    const getListAuto = async () => {
      setLoad(true);
      try {
        await axios
          .get(organisme_API + "?type_org=Auto-écoles",{
            signal: abortController.signal,
            })
          .then((reponse) => {
            console.log(reponse.data);
            setdata(reponse.data.data);
            setLoad(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
      getAllOrganisme();
      return () => {
        abortController.abort();
      };
    }, [recharger]);
    return (
      <div className="container-lg container-fluid py-4">
        <Modal
          show={supp}
          onHide={handlesup}
          backdrop="static"
          keyboard={false}
          fullscreen={false}
          size={"sm"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Supprimer</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>Voulez-vous vraiment supprimer ?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handlesup}>
              Annuler
            </Button>
            <Button
              variant="danger"
              disabled={load}
              onClick={() => handleSubmit()}
            >
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>
  
        <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
          <div className="">
            <h2>
              <FontAwesomeIcon icon={faCircleNotch} className="me-1" /> Centre d'examens{" "}
            </h2>
          </div>
          <div>
            <Button
              variant="primary"
              onClick={() => {
                setmode(false);
                setFormValues(vide);
                setImage(null);
                setImagescan(null);
                handleShow();
              }}
            >
              <FontAwesomeIcon icon={faAdd} className="me-2" />
              Nouveau centre d'examen
            </Button>
          </div>
        </div>
  
        <div className="">
          <div className="row my-2 mb-3 g-2">
            <div className="col-lg-3 col-md-6  d-flex align-items-center">
              <label style={{ width: "65px" }}>Nom :</label>
              <input
                className="form-control"
                name="nom"
                value={filtre.nom}
                onChange={handleFilter}
                type="text"
                placeholder="nom de l'organisme"
              />
            </div>
            <div className="col-lg-3 col-md-6  d-flex align-items-center">
              <label style={{ width: "65px" }}>Région :</label>
              <select
                className="form-control"
                name="region"
                value={filtre.region}
                onChange={handleFilter}
              >
                <option value="">sélectionner ici...</option>
                {regions.map((reg) => (
                  <option value={reg.label}>{reg.label}</option>
                ))}
              </select>
            </div>
            <div className="col-lg-3 col-md-6  d-flex align-items-center">
              <label style={{ width: "65px" }}>Ville :</label>
              <input
                className="form-control"
                name="ville"
                value={filtre.ville}
                placeholder="Choix ville"
                onChange={handleFilter}
              />
          
            </div>
            <div className="col-lg-3 col-md-6  d-flex align-items-center">
              <label style={{ width: "65px" }}>Type :</label>
              <select
                className="form-control"
                name="type"
                value={filtre.type}
                onChange={handleFilter}
              >
                <option value="">sélectionner ici...</option>
                  <option value="Examinateurs">Centre d'examen</option>
                
               
              </select>
              
            
            </div>
          </div>
          <div className="d-flex justify-content-end mb-2">
            <Button
              variant="secondary"
              onClick={() => setfiltre(videfiltre)}
              size="md"
            >
              <FontAwesomeIcon icon={faFilterCircleXmark} className="me-2" />{" "}
              Enlever filtre
            </Button>
          </div>
          <DataTable
            className="custom-table"
            title={<p className="fs-4">Liste des organismes</p>}
            columns={columns}
            data={data
              .filter((f) =>
                filtre.nom != "" && f.nom_org != null
                  ? f.nom_org.toLowerCase().includes(filtre.nom.toLowerCase())
                  : f
              )
              .filter((f) =>
                filtre.region != "" && f.province_org != null
                  ? f.province_org
                      .toLowerCase()
                      .includes(filtre.region.toLowerCase())
                  : f
              )
              .filter((f) =>
                filtre.type != "" && f.type_org != null
                  ? f.type_org.toLowerCase().includes(filtre.type.toLowerCase())
                  : f
              )
              .filter((f) =>
                filtre.ville != "" && f.ville_org != null
                  ? f.ville_org.toLowerCase().includes(filtre.ville.toLowerCase())
                  : f
              )}
            selectableRows
            clearSelectedRows={toggledClearRows}
            selectedRow={selectedRows}
            progressPending={load}
            contextActions={
              <div className="d-flex w-100 justify-content-between">
                <Button
                  className="me-2"
                  variant="secondary"
                  onClick={() => {
                    settoggledClearRows(!toggledClearRows);
                  }}
                >
                  <FontAwesomeIcon icon={faUndoAlt} className="" />
                </Button>
  
                <button
                  className="btn btn-danger me-2"
                  onClick={() => {
                    setsupp(true);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            }
            progressComponent={
              <div className="w-100 d-flex justify-content-center py-5">
                <TailSpin
                  height="80"
                  width="80"
                  color="#22577A"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            }
            contextMessage={{
              singular: "élement",
              plural: "élements",
              message: "sélectionnées",
            }}
            onSelectedRowsChange={(rows) => {
              setSelectedRows(rows);
            }}
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page",
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
          />
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size={"xl"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Formulaire </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6 col-12 d-flex align-item-stretch">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="">
                      <label className="form-label">Nom (*)</label>
                      <input
                        className={
                          "form-control " + (error.nom_org && "is-invalid")
                        }
                        name="nom_org"
                        value={formValues.nom_org}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir nom organisme"
                      />
                      {error.nom_org ? (
                        <div className="invalid-feedback">{error.nom_org}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="my-3">
                      <label className="form-label">Email (*)</label>
                      <input
                        className={
                          "form-control " + (error.email_org && "is-invalid")
                        }
                        name="email_org"
                        value={formValues.email_org}
                        onChange={handleChange}
                        type="email"
                        placeholder="Saisir email de l'organisme"
                      />
                      {error.email_org ? (
                        <div className="invalid-feedback">{error.email_org}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="my-3">
                      <label className="form-label">Adresse (*)</label>
                      <input
                        className={
                          "form-control " + (error.adresse_org && "is-invalid")
                        }
                        name="adresse_org"
                        value={formValues.adresse_org}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir adresse de l' organisme"
                      />
                      {error.adresse_org ? (
                        <div className="invalid-feedback">
                          {error.adresse_org}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="my-3">
                      <label className="form-label">Ville (*)</label>
                      <input
                        className={
                          "form-control " + (error.ville_org && "is-invalid")
                        }
                        name="ville_org"
                        value={formValues.ville_org}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir ville de l'organisme"
                      />
                      {error.ville_org ? (
                        <div className="invalid-feedback">{error.ville_org}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="my-3">
                      <label className="form-label">Région (*)</label>
                      <SelectOne
                        options={regions}
                        load={false}
                        choix={formValues.province_org}
                        placeholder="choix région"
                        setFormValues={setFormValues}
                        formValues={formValues}
                        setError={setError}
                        error={error}
                        name={"province_org"}
                      />
                      {/* <input
                        className={
                          "form-control " + (error.province_org && "is-invalid")
                        }
                        name="province_org"
                        value={formValues.province_org}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir région de l'organisme"
                      /> */}
                      {error.province_org ? (
                        <div className="invalid-feedback">
                          {error.province_org}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 d-flex align-item-stretch">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="row">
                      <div className=" col-12 col-lg-4">
                        <label className="form-label">Code postal (*)</label>
                        <input
                          className={
                            "form-control " + (error.cp_org && "is-invalid")
                          }
                          name="cp_org"
                          value={formValues.cp_org}
                          onChange={handleChange}
                          type="number"
                          placeholder="Saisir son code postal"
                        />
                        {error.cp_org ? (
                          <div className="invalid-feedback">{error.cp_org}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-12 col-lg-8">
                        <label className="form-label">Pays (*)</label>
                        <input
                          className={
                            "form-control " + (error.pays_org && "is-invalid")
                          }
                          name="pays_org"
                          value={formValues.pays_org}
                          onChange={handleChange}
                          type="text"
                          placeholder="Saisir le pays"
                        />
                        {error.pays_org ? (
                          <div className="invalid-feedback">{error.pays_org}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="my-3">
                      <label className="form-label">web</label>
                      <input
                        className={
                          "form-control " + (error.web_org && "is-invalid")
                        }
                        name="web_org"
                        value={formValues.web_org}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir web"
                      />
                      {error.web_org ? (
                        <div className="invalid-feedback">{error.web_org}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="my-3">
                      <label className="form-label">Telephone </label>
                      <InputMask
                        onKeyUp={(e) => {
                          console.log(e.target.value);
                          if (e.target.value === "") {
                            setError({
                              ...error,
                              tel_org: ["le champs telephone est requis"],
                            });
                          } else if (e.target.value !== "") {
                            setError({ ...error, tel_org: [] });
                          }
                        }}
                        onChange={handleChange}
                        mask="+261 39 99 999 99"
                        type="text"
                        className={
                          "form-control " +
                          (error.tel_org?.length > 0 && " is-invalid")
                        }
                        value={formValues.tel_org}
                        name="tel_org"
                        placeholder="Telephone"
                      />
  
                      {error.tel_org &&
                        error.tel_org.map((element) => (
                          <p key={element} className="invalid-feedback p-0 m-1">
                            {element}
                          </p>
                        ))}
                    </div>
              
                    <div className="row align-items-center">
                      <div className="col-8">
                        <div className="">
                          <label className="form-label">Logo </label>
                          <input
                            className={
                              "form-control " + (error.logo_org && "is-invalid")
                            }
                            name="logo_org"
                            id="logo_org"
                            type="file"
                            onChange={onImageChange}
                            accept="image/*"
                          />
                          {error.logo_org && (
                            <div className="invalid-feedback">
                              {error.logo_org}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-4 align-self-end">
                        {image && (
                          <span
                            className="avatar avatar-md"
                            style={{ backgroundImage: "url(" + image + ")" }}
                          ></span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            {mode == false && (
              <Button
                variant="primary"
                disabled={load}
                onClick={() => handleSubmit()}
              >
                {<>Enregistrer</>}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  