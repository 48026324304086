import React from "react";
import { Badge } from "react-bootstrap";

export default function Permis({permisdata}) {
  const data = permisdata != null ? permisdata.split(",") : "";
  return permisdata != null ? (
    <div className="d-flex flex-wrap gap-1">
      {data.map((el, i) => el != "" && <Badge bg="primary">{el}</Badge>)}
    </div>
  ) : (
    <div>Aucune</div>
  );
}
