import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import isconnected from "../../service/isconnected";
import Footer from "../Footer/Footer";
import Header from "../header/Header";
import Actualite from "./section/Actualite";
import Partenaire from "./section/Partenaire";
import Popinsc from "./section/Popinsc";
import Section1 from "./section/Section1";
import Section2 from "./section/Section2";
import Section3 from "./section/Section3";
import Section4 from "./section/Section4";
import SuggestionModule from "./section/SuggestionModule";
import Profile from "./section/Profile";
import { useSelector } from "react-redux";
import { getCompte } from "../../service/getApi/getCompte";
import { getMots } from "../../service/getApi/getMots";

export default function Accueil(props) {
  const navigate = useNavigate();
  const dataRedux = useSelector(state=>state.compte)
  const token = window.localStorage.getItem("authToken")
  console.log(token)
  useEffect(()=>{
    getCompte();
    getMots();
  },[])
  return (
    <div className=" w-100">
      <div className="m-0 p-0" >
        <Section1 />
        {isconnected.user() != false &&
          isconnected.user().type_util == "Auto-écoles" ? (
            <div className="mb-5 pb-4" style={{position:'relative'}} >
              <Profile />
            </div>
          ):  !["Auto-écoles"].includes(isconnected.user()?.type_util)  && (
            <div className="mb-5 pb-4" style={{position:'relative'}} >
             
             <Profile />
           
         
            </div>
          )}
        {/* <Actualite />
        <Section4 /> */}
        {/* <Partenaire /> */}
      </div>
    </div>
  );
}
