import {
  faAdd,
  faEdit,
  faHSquare,
  faTrash,
  faUndoAlt,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Image } from "primereact/image";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { file_url, motsministres } from "../../../service/config";
import { toast } from "react-toastify";
import ResizeFile, { resizeImage } from "../../../service/ResizeFile";

export default function GererMotMinistere() {
  const [showmodal, setshowmodal] = useState({ del: false, form: false });
  const [selectedRow, setSelectedRows] = useState({});
  const [mode, setmode] = useState(false);
  const [error, setError] = useState({});
  const [formValues, setFormValues] = useState({});
  const [imagescan, setImagescan] = useState(null);
  const [data, setdata] = useState([]);
  const [load, setload] = useState({
    button: false,
    data: true,
  });
  const abortController = new AbortController();

  const columns = [
    {
      name: "Ministre",
      wrap: true,
      selector: (row) => row.nom_ministre,
      sortable: true,
    },
    {
      name: "Mots",
      selector: (row) => row.text_ministre,
      sortable: true,
      wrap: true,
    },
    {
      name: "Photo",
      selector: (row) => (
        <Image src={file_url + row.photo_ministre} height="45" preview />
      ),
    },
    {
      width: "175px",
      name: "Action",
      selector: (row) => (
        <div className="d-flex gap-2">
          <Button
            className="bg-cyan"
            onClick={() => {
              const { photo_ministre, ...filtrer } = row;
              setmode(true);
              setError({});
              setSelectedRows(row);
              setFormValues(filtrer);
              setImagescan(file_url + row.photo_ministre);
              handleShowModal("form");
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button
            className="bg-danger"
            onClick={() => {
              setSelectedRows(row);
              handleShowModal("del");
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
      sortable: true,
    },
  ];

  const handleShowModal = (mod) => {
    setshowmodal({ ...showmodal, [mod]: !showmodal[mod] });
  };

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;

    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
  };

  const onImageChange = async (e) => {
    try {
      const resizedImage = await resizeImage(e.target.files[0], 579, 367);
      console.log(resizedImage);
      if (e.target.files && e.target.files[0]) {
        setFormValues({ ...formValues, [e.target.name]: resizedImage });

        setError({ ...error, [e.target.name]: null });
  
        setImagescan(URL.createObjectURL(resizedImage));
      }
    
    } catch (error) {
      console.error('Error resizing image:', error);
    }
  };

  const getdata = () => {
    setload({ ...load, data: true });
    axios
      .get(motsministres, {
        signal: abortController.signal,
      })
      .then((rep) => {
        console.log(rep.data);
        setdata(rep.data.data);
        setload({ ...load, data: false });
      })
      .catch((err) => {
        setload({ ...load, data: false });
        console.log(err);
      });
  };

  const handleSubmit = async () => {
    const apiErrors = {};
    setload({ ...load, button: true });
    console.log(formValues);
    axios
      .post(motsministres, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((rep) => {
        toast.success("opération réussi !");
        setload({ ...load, button: false });
        handleShowModal("form");
        getdata();
      })
      .catch((error) => {
        console.log(error);
        const msg = error.response.data;
        setload({ ...load, button: false });
        setload(false);
        if (msg) {
          toast.error("Verifier votre saisi !");
          let champs = Object.keys(msg);
          for (let i = 0; i < champs.length; i++) {
            const element = champs[i];
            if (msg[element]) {
              apiErrors[element] = msg[element];
            }
          }
        }
        if (error.response.status == 500) {
          toast.error(error.response.data.message);
        }
        if (error.response.status == 401) {
          toast.info(error.response.data.message);
        }
        setError(apiErrors);
      });
  };
  const handleUpdate = async () => {
    const apiErrors = {};

    setload({ ...load, button: true });
    console.log(formValues);
    await axios
      .post(motsministres + `/${formValues.id_mots}?_method=PUT`, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((rep) => {
        toast.success("opération réussi !");
        setload({ ...load, button: false });
        handleShowModal("form");
        getdata();
      })
      .catch((error) => {
        console.log(formValues);
        console.log(error);
        const msg = error.response.data;
        setload({ ...load, button: false });
        setload(false);
        if (msg) {
          toast.error("Verifier votre saisi !");
          let champs = Object.keys(msg);
          for (let i = 0; i < champs.length; i++) {
            const element = champs[i];
            if (msg[element]) {
              apiErrors[element] = msg[element];
            }
          }
        }
        if (error.response.status == 500) {
          toast.error(error.response.data.message);
        }
        if (error.response.status == 401) {
          toast.info(error.response.data.message);
        }
        setError(apiErrors);
      });
  };

  const handledelete = async () => {
    setload({ ...load, button: true });
    try {
      axios
        .delete(motsministres + "/" + selectedRow.id_mots)
        .then(() => {
          toast.success("mots du ministre supprimé");
          getdata();
          setload({ ...load, button: false });
          handleShowModal("del");
        })
        .catch((e) => {
          console.log(e);
          toast.error("Un erreur est survenu !");
          handleShowModal("del");
          setload({ ...load, button: false });
        });
    } catch (error) {
      toast.error("Un erreur est survenu !");
      handleShowModal("del");
      setload({ ...load, button: false });
    }
  };

  useEffect(() => {
    getdata();

    return () => {
      abortController.abort();
    };
  }, []);
  return (
    <div>
      <Modal
        show={showmodal.del}
        size="sm"
        onHide={() => handleShowModal("del")}
      >
        <Modal.Header>
          <FontAwesomeIcon icon={faWarning} className="bg-danger me-2" />
          Supprimer
        </Modal.Header>
        <Modal.Body>Vous êtes sur de vouloir supprimer ?</Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button variant="secondary" onClick={() => handleShowModal("del")}>
              Annuler
            </Button>
            <Button
              variant="danger"
              disabled={load.button}
              onClick={() => handledelete()}
            >
              oui, je confirme
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showmodal.form} size="lg" animation>
        <Modal.Header>
          <FontAwesomeIcon icon={faWarning} className="bg-danger me-2" />
          Mot du ministre
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="my-3">
              <label className="form-label">Nom du Ministre actuel</label>
              <input
                className={
                  "form-control " +
                  (error.nom_ministre?.length > 0 && "is-invalid")
                }
                name="nom_ministre"
                value={formValues.nom_ministre}
                onChange={handleChange}
                type="email"
                placeholder="Saisir nom du ministre "
              />
              {error.nom_ministre?.length > 0 ? (
                <div className="invalid-feedback">{error.nom_ministre[0]}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3">
              <label className="form-label">Mot du Ministre</label>
              <textarea
                className={
                  "form-control " +
                  (error.text_ministre?.length > 0 && "is-invalid")
                }
                rows={4}
                name="text_ministre"
                value={formValues.text_ministre}
                onChange={handleChange}
                placeholder="Quelque message venant du Ministre"
              />
              {error.text_ministre?.length > 0 ? (
                <div className="invalid-feedback">{error.text_ministre[0]}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3">
              <div className="row">
                <div className="col-12">
                  <label className="form-label">
                    Photo du Ministre : (de préference avec un fond transparent){" "}
                  </label>
                  <input
                    className={
                      "form-control " +
                      (error.photo_ministre?.length > 0 && "is-invalid")
                    }
                    name="photo_ministre"
                    id="photo_ministre"
                    type="file"
                    onChange={onImageChange}
                    accept="image/*"
                  />
                  {error.photo_ministre?.length > 0 && (
                    <div className="invalid-feedback">
                      {error.photo_ministre[0]}
                    </div>
                  )}
                </div>
                <div className="col-6">
                  <Image src={imagescan} alt="Image" width="70" preview />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button variant="secondary" onClick={() => handleShowModal("form")}>
              Annuler
            </Button>
            <Button
              variant="primary"
              disabled={load.button}
              onClick={() => (mode ? handleUpdate() : handleSubmit())}
            >
              {mode ? "Modifier" : "Enregistrer"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <div className="container-lg container-fluid py-4">
        <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
          <div className="">
            <h2>
              <FontAwesomeIcon icon={faHSquare} className="me-1" /> Mot du
              ministère{" "}
            </h2>
          </div>
          <div>
            {data.length == 0 && (
              <Button
                variant="primary"
                onClick={() => {
                  setmode(false);
                  setImagescan(null);
                  setFormValues({});
                  setError({});
                  handleShowModal("form");
                }}
              >
                <FontAwesomeIcon icon={faAdd} className="me-2" />
                Ajouter
              </Button>
            )}
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-body">
            <div className="">
              <DataTable
                className="custom-table"
                title={<p className="fs-2">Mots du ministre</p>}
                columns={columns}
                data={data}
                progressPending={load.data}
                progressComponent={
                  <div className="w-100 d-flex justify-content-center py-5">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#22577A"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                }
                contextMessage={{
                  singular: "élement",
                  plural: "élements",
                  message: "sélectionnées",
                }}
                pagination
                paginationPerPage={5}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page",
                }}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
