import {
  faHourglass3,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import image from "../../../assets/image/imgtest/image";
import { cursus_Cand, file_url } from "../../../service/config";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";

export default function Evolution({ row }) {
  const [show, setshow] = useState(false);
  const [load, setload] = useState({
    button: false,
    erreur: false,
  });
  const [evoluti, setEvoluti] = useState({});

  const [moyenne, setmoyenne] = useState(0);
  function toHoursAndMinutes(totalSeconds) {
    const duration = moment.duration(parseInt(totalSeconds), "seconds");

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return (
      <>
        {hours < 10 ? `0${hours}` : hours}:
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </>
    );
  }
  const handleShow = () => setshow(!show);
  const getpour = () => {
    setload({ ...load, button: true });
    axios
      .get(cursus_Cand + row.id_cand)
      .then((rep) => {
        console.log(rep);
        setEvoluti(rep.data);

        let moy = 0;
        for (let i = 0; i < rep.data.exercice?.length; i++) {
          const element = rep.data.exercice[i];
          moy += Number(element.resultat_curs);
        }
        setmoyenne(moy / rep.data.exercice?.length);
        setload({ ...load, button: false });
        handleShow();
      })
      .catch((err) => {
        console.log(err);
        toast.error("erreur lors de récuperation de donnée de l'apprenant !");
        setload({ ...load, button: false });
      });
  };

  return (
    <>
      <Button variant="secondary" onClick={() => getpour()}>
        {load.button ? (
          <TailSpin
            height="15"
            width="15"
            color="white"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <FontAwesomeIcon icon={faUserGraduate} />
        )}
      </Button>
      <Modal
        backdrop="static"
        size="xl"
        scrollable
       
        animation
        show={show}
        onHide={handleShow}
      >
        <Modal.Header className="d-flex justify-content-between" closeButton>
          <h3>Pointer de l'apprenant</h3>
        </Modal.Header>
        <Modal.Body  className="section1">
          <div className="">
            <div className="card mb-3 p-3 rounded-2">
              <div className="row row-reverse ">
                <div className="col-lg col-12">
                  <div className="p-2">
                    <table className="border-none">
                      <tr>
                        <th className="pb-1">
                          <span className="fw-light text-muted me-2">
                            Nom et prenom :
                          </span>
                        </th>
                        <th className="pb-1">
                          <span className="fw-bold">
                            {row.nom_cand} {row.prenom_cand}
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <th className="pb-1">
                          <span className="fw-light text-muted me-2">
                            CIN :
                          </span>
                        </th>
                        <th className="pb-1">
                          <span className="fw-bold">{row.cin_cand}</span>
                        </th>
                      </tr>
                      {/* <tr>
                        <th className="pb-1">
                          <span className="fw-light text-muted me-2">
                            Sexe :
                          </span>
                        </th>
                        <th className="pb-1">
                          <span className="fw-bold">{row.sexe_cand}</span>
                        </th>
                      </tr> */}
                      <tr>
                        <th className="pb-1">
                          <span className="fw-light text-muted me-2">
                            Nationnalitée :
                          </span>
                        </th>
                        <th className="pb-1">
                          <span className="fw-bold">
                            {row.nationalite_cand}
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <th className="pb-1">
                          <span className="fw-light text-muted me-2">
                            Adresse :
                          </span>
                        </th>
                        <th className="pb-1">
                          <span className="fw-bold">{row.adresse_cand}</span>
                        </th>
                      </tr>
                      <tr>
                        <th className="pb-1">
                          <span className="fw-light text-muted me-2">CP :</span>
                        </th>
                        <th className="pb-1">
                          <span className="fw-bold">{row.cp_cand}</span>
                        </th>
                      </tr>
                      <tr>
                        <th className="pb-1">
                          <span className="fw-light text-muted me-2">
                            Region :
                          </span>
                        </th>
                        <th className="pb-1">
                          <span className="fw-bold">{row.province_cand}</span>
                        </th>
                      </tr>
                      <tr>
                        <th className="pb-1">
                          <span className="fw-light text-muted me-2">
                            Pays :
                          </span>
                        </th>
                        <th className="pb-1">
                          <span className="fw-bold">{row.pays_cand}</span>
                        </th>
                      </tr>
                      <tr>
                        <th className="pb-1">
                          <span className="fw-light text-muted me-2">
                            Teléphone :
                          </span>
                        </th>
                        <th className="pb-1">
                          <span className="fw-bold">{row.tel_cand}</span>
                        </th>
                      </tr>
                      <tr>
                        <th className="pb-1">
                          <span className="fw-light text-muted me-2">
                            Email :
                          </span>
                        </th>
                        <th className="pb-1">
                          <span className="fw-bold">{row.email_cand}</span>
                        </th>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="col-lg-4 col d-flex align-items-center justify-content0-center">
                  {row.photo_cand ? (
                    <img src={file_url + row.photo_cand} className="" />
                  ) : (
                    <img src={image.avatar} className="" />
                  )}
                </div>
              </div>
            </div>
            <div className="card p-3 rounded-2">
              <div className="row ">
                <div className="col-12 ">
                  <div className="row">
                    <div className="col-4 d-flex align-items-stretch">
                      <div className="card w-100">
                        <div className="card-body text-center">
                          <div className="display-6 fw-bold">
                            {evoluti.totalModuleUser}
                          </div>
                          <p>Modules parcourus</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 d-flex align-items-stretch">
                      <div className="card w-100">
                        <div className="card-body text-center">
                          <div className="display-6 fw-bold">
                            {evoluti.resteModuleUser}
                          </div>
                          <p>Modules non parcourus</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 d-flex align-items-stretch">
                      <div className="card w-100">
                        <div className="card-body text-center">
                          <div className="display-6 fw-bold">
                            {evoluti.totalModule}
                          </div>
                          <p>Total Module</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-5 d-flex align-items-stretch">
                      <div className="card w-100">
                        <div className="card-body">
                          <p>Note et resultats hypothétique</p>
                          <div className="display-6 fw-bold text-center">
                            {evoluti.meanResult <= 0 ? (
                              <>---</>
                            ) : evoluti.meanResult < 10 ? (
                              <>Mauvais</>
                            ) : evoluti.meanResult < 12 ? (
                              <>Passable</>
                            ) : evoluti.meanResult < 16 ? (
                              <>Assez-bien</>
                            ) : evoluti.meanResult < 18 ? (
                              <>Bien</>
                            ) : (
                              evoluti.meanResult < 19.5 && <>Tres Bien</>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-7 d-flex align-items-stretch">
                      <div className="card w-100 ">
                        <div className="card-body d-flex align-items-center justify-content-center">
                          <div className="display-5 fw-bold text-center">
                            {evoluti.meanResult
                              ? parseFloat(evoluti.meanResult).toFixed(2)
                              : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-3 py-3 mt-3">
              <h3>Modules choisis</h3>
              <div className="d-flex flex-wrap flex-row gap-2 p-2">
                {evoluti.moduleChoisi?.map((element, index) => (
                  <Link to={"/details/" + element.id_mod}>
                    <span
                      key={index}
                      className="badge bg-primary p-2 text-wrap"
                    >
                      {element.titre_mod}
                    </span>
                  </Link>
                ))}
              </div>
            </div>
            <div className="card p-3 py-3 mt-3">
              <h3>Modules non choisis</h3>
              <div className="d-flex flex-wrap flex-row gap-2 p-2">
                {evoluti.modulesNonChoisis?.length == 0
                  ? "Aucun"
                  : evoluti.modulesNonChoisis?.map((element, index) => (
                      <Link to={"/details/" + element.id_mod}>
                        <span
                          key={index}
                          className="badge bg-primary p-2 text-wrap"
                        >
                          {element.titre_mod}
                        </span>
                      </Link>
                    ))}
              </div>
            </div>
            <div className="card p-3 py-3 mt-3">
              <h3>Leçons parcourus</h3>
              <div className="p-3">
                <div className="list-group list-group-flush list-group-hoverable">
                  {evoluti.lessons?.map((element, index) => (
                    <Link
                      to={`/lessons/${element.id_mod}`}
                      state={{ rech: "oui", lessonrech: element.id_les }}
                      className="text-decoration-none"
                    >
                      <div key={index} className="list-group-item p-2">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                          <p className="">{element.titre_les}</p>
                          <span className="badge bg-secondary border-secondary">
                            <FontAwesomeIcon
                              className="pe-1"
                              icon={faHourglass3}
                            />
                            {toHoursAndMinutes(element.temps_pour)}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="card p-3 py-3 mt-3">
              <h3>Exercices parcourus</h3>
              <div className="p-3">
                <div className="list-group list-group-flush list-group-hoverable">
                  {evoluti.exercice?.map((element, index) => (
                    <div key={index} className="list-group-item p-2">
                      <div className="d-flex flex-wrap align-items-center justify-content-between">
                        <p className="">{element.titre_mod}</p>
                        <div className="w-25 d-flex flex-wrap  flex-row align-items-center justify-content-between">
                          <p className="fs-5">{element.resultat_curs}/10</p>
                          <span className="badge bg-secondary border-secondary">
                            <FontAwesomeIcon
                              className="pe-1"
                              icon={faHourglass3}
                            />
                            {toHoursAndMinutes(element.temps)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
