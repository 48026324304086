import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ResendEmail_API } from "../../service/config";

export default function Error() {
  const location = useLocation();
  const email = location.state?.email;
  const [load, setload] = useState(false);
  console.log(email);
  const resend = async () => {
    setload(true);
    try {
      await axios
        .post(ResendEmail_API + email)
        .then((response) => {
          toast.success("E-mail envoyé !");
          setload(false);
        })
        .catch((error) => {
          toast.error("erreur interne, veuillez réessayer plus tard !");
          setload(false);
        });
    } catch (error) {
      console.log(error);
      setload(false);
      toast.error("verifier votre connexion et réessayer !");
    }
  };
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="p-4 card rounded-4 test">
        <div className="card-header flex justify-content-center">
          <h3 className="text-center text-warning">
            Un erreur est survenu, vous êtes inscrit, mais l'email n'est pas
            envoyer ! <FontAwesomeIcon icon={faMailBulk} />
          </h3>
        </div>
        <div className="card-body">
          <div className="d-flex flex-column justify-content-center">
            <div>
              Voici quelques étapes à suivre :
              <ul>
                <li>Vérifiez que vous etes bien connecter à internet.</li>
                <li>Cliquer sur le bouton renvoyer email</li>
                <li>
                  Si le probleme persiste, veuillez informer un responsable
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="my-2 text-center">
          <p>Veuillez ré-cliquer ci-dessous si vous n'avez pas reçu d'email</p>
          <div className="d-flex flex-row flex-wrap align-items-center justify-content-center gap-1">
            <button
              className={"btn btn-success " + (load && "disabled")}
              type="button"
              onClick={() => resend()}
            >
              Renvoyer email
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
