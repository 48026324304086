import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";
import { Tag } from "primereact/tag";
import { Card } from "primereact/card";
import { Link, useNavigate } from "react-router-dom";


import { register } from "swiper/element/bundle";
import { useRef } from "react";
import sar1 from "./arrow-back-ios-new-rounded.svg";
import sar2 from "./arrow-forward-ios.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockFour } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { file_url } from "../../../service/config";


// register Swiper custom elements
register();

export default function Carrousel(props) {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiperContainer = swiperRef.current;
    const params = {
      navigation: true,
      slidesPerView: "auto",
      pagination: true,
      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 3,
        },
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      injectStyles: [
        `
     
        .swiper-button-next,
          .swiper-button-prev {
           
            background-position: center;
            background-size: 25px;
            background-repeat: no-repeat;
            color: #22577A;
          }

          .swiper-button-prev {
            background-image: url(${sar1});
            background-color: #41A9E0;
            border-radius:1px;
            transform:translate(-5px,0px);
          }

          .swiper-button-next {
            background-image: url(${sar2});
            background-color: #41A9E0;
            border-radius:1px;
            transform:translate(5px,0px);
          }

          .swiper-button-next::after,
          .swiper-button-prev::after {
            content: "";
          }
      `,
      ],
    };

    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }, []);
  function Separer(strin) {
    if (strin.length === 0 || strin === undefined || strin === null) {
      return null;
    } else {
      var tab = strin.split(";");

      return (
        <>
          {tab.map(
            (element, index) =>
              element !== "" && (
                <span
                  key={index}
                  className="badge bg-transparent text-primary border border-indigo"
                >
                  {element}
                </span>
              )
          )}
        </>
      );
    }
  }

  const data = props.data;

  return (
    <div className="px-lg-5 px-md-4">
 <swiper-container className="" ref={swiperRef} init="false">
          {props.data?.map((element, index) => (
            <swiper-slide key={index} className="mb-3 ">
              <div
                style={{ height: "98%", position: "relative" }}
                onClick={() =>
                  navigate("/détails sensibilisation/" + element.id_sens)
                }
                className="bg-card border-0 m-1 d-flex flex-column align-items-stretch card effet position-relative  rounded-3 px-3 "
              >
                
                <div className=" m-0 p-0">
                  <div
                    className="img-responsive card-img-top h-50 rounded-5"
                    style={{
                      height: "30% !important",
                      backgroundSize:'cover',
                      backgroundImage:
                        "url(" + file_url + element.img_sens + ")",
                    }}
                  >
                    {" "}
                  </div>
                  <div className="ombre"></div>
                </div>
                <div className="card-body px-3">
                  <h3 className="card-title h3 fw-bold mb-2 text-truncate">
                    {element.titre_sens}
                  </h3>
                  <div className="card-text text-muted truncate-4-lines">
                   
                    {element.desc_sens &&
                     
                        element.desc_sens === null ? "" : element.desc_sens
                      }
                 
                  </div>
                </div>
              </div>
            </swiper-slide>
          ))}
        </swiper-container>
    </div>
  );
}
