import React, { useEffect, useState } from "react";
import "./Print.css";
import { Button, Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faPrint } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import "moment/locale/fr";

export default function ListCandidatsPrint({ titre, candidats }) {
  const ref = React.useRef();
  const [open, setopen] = useState(false);
  return (
    <>
     {candidats?.length > 0 && (<ReactToPrint
        trigger={() => (
          <Button variant="success">
            <FontAwesomeIcon icon={faPrint} className="me-2" />
            Imprimer
          </Button>
        )}
        content={() => ref.current}
      />)}
      <div className="d-none">
        <div className="row justify-content-center">
          <div className="col-6">
            <div ref={ref}>
              <div>
                <h1 className="mb-4">
                {titre}
                </h1>
                <p>Type de permis visé : Permis de conduire de voiture</p>

                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nom</th>
                      <th>Prénom</th>
                      {/* <th>Sexe</th>
                    <th>Date de naissance</th>
                    <th>Adresse</th>
                    <th>Ville</th>
                    <th>Province</th> */}
                      <th>Catégorie de permis</th>
                    </tr>
                  </thead>
                  <tbody>
                    {candidats.map((candidat) => (
                      <tr key={candidat.id_cand}>
                        <td>{candidat.id_cand}</td>
                        <td>{candidat.nom_cand}</td>
                        <td>{candidat.prenom_cand}</td>
                        {/* <td>{candidat.sexe_cand ? "Homme" : "Femme"}</td>
                      <td>{candidat.naiss_cand}</td>
                      <td>{candidat.adresse_cand}</td> */}
                        {/* <td>{candidat.ville_cand}</td>
                      <td>{candidat.province_cand}</td> */}
                        <td>{candidat.categ_permis}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
   
    </>
  );
}
