import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Tag } from "primereact/tag";

export const StatusExam = (exam,dateProp,heureProp) => {
    const dateExamen = new Date(exam[dateProp] + "T" + exam[heureProp]);
    const maintenant = new Date();
    if (maintenant < dateExamen) {
      return { stat: "secondary", value: "L'examen n'a pas encore eu lieu." };
    } else if (
      maintenant >= dateExamen &&
      maintenant < new Date(dateExamen.getTime() + 2 * 60 * 60 * 1000)
    ) {
      return { stat: "success", value: "L'examen est en cours." };
    } else {
      return { stat: "danger", value: "L'examen est terminé." };
    }
  };

export const columnExamen = (dateProp,heureProp,nomcentreprop,lieucentreprop,setcandidatSelect,handleModal)=> {
  return [
    {
      name: "Date",
      format: (row) => (
        <b>{moment(row[dateProp]).format('LL LT')}</b>
      ),
      selector: (row) => row[dateProp],
      sortable: true,
    },
    {
      name: "Heure",
      format: (row) => (
        <b>
          <FontAwesomeIcon icon={faClock} className="me-2" />
          {row[heureProp]}
        </b>
      ),
      selector: (row) => row[heureProp],
      sortable: true,
    },
    {
      name: "centre",
      format: (row) => (
        <b>
        
          {row[nomcentreprop]}
        </b>
      ),
      selector: (row) => row[heureProp],
      sortable: true,
    },
    {
      name: "Lieu",
      format: (row) => (
        <b>
        
          {row[lieucentreprop]}
        </b>
      ),
      selector: (row) => row[heureProp],
      sortable: true,
    },

    {
      name: "Les candidats",
      format: (row) => (
        <Tag
          style={{ cursor: "pointer" }}
          onClick={() => {
            setcandidatSelect(row);
            setTimeout(() => {
              handleModal();
            }, 200);
          }}
          severity={"info"}
          value={`Voir les ${row?.listecandidatvalider?.length} candidats`}
        />
      ),
      selector: (row) => row.listecandidatvalider,
      sortable: true,
    },
    {
      name: "Statut",
      wrap:true,
      selector: (row) => {
        let dat = StatusExam(row,dateProp,heureProp);
        return <Tag severity={dat.stat} value={dat.value} />;
      },
      sortable: true,
    },
  ];
}