const actu = [];
const sensi = [];
const categorie = [];
const compteData = [];
const typeExam = [];
const mots  = {}
const listauto = [];

function actualite(state = actu, action) {
  switch (action.type) {
    case "add":
      return action.payload;
    case "remove":
      return state.filter((item) => item != action.payload);
    case "clear":
      return [];
    default:
      return state;
  }
}

function getMots(state = mots, action) {
  switch (action.type) {
    case "addmots":
      return action.payload;
    case "remove":
      return state.filter((item) => item != action.payload);
    case "clearmots":
      return {};
    default:
      return state;
  }
}

function TypeExamen(state = typeExam, action) {
  switch (action.type) {
    case "listtypeexam":
      return action.payload;
    case "remove":
      return state.filter((item) => item != action.payload);
    case "clear":
      return [];
    default:
      return state;
  }
}
function sensibilisation(state = sensi, action) {
  switch (action.type) {
    case "addsens":
      return action.payload;
    case "remove":
      return state.filter((item) => item != action.payload);
    case "clear":
      return [];
    default:
      return state;
  }
}

function categ(state = categorie, action) {
  switch (action.type) {
    case "addcateg":
      return action.payload;
    case "remove":
      return state.filter((item) => item != action.payload);
    case "clear":
      return [];
    default:
      return state;
  }
}


function Auto(state = listauto, action) {
  switch (action.type) {
    case "addAuto":
      return action.payload;
    case "remove":
      return state.filter((item) => item != action.payload);
    case "clear":
      return [];
    default:
      return state;
  }
}

function compte(state = compteData, action) {
  switch (action.type) {
    case "addcompte":
      if (action.payload) {
        return action.payload;
      }else{
        return null;
      }
       ;
    case "remove":
      return state.filter((item) => item != action.payload);
    case "clear":
      return [];
    default:
      return state;
  }
}




export {
  actualite,
  sensibilisation,
  categ,
  compte,
  TypeExamen,
  getMots,
  Auto
};
