import React from 'react'

const Pagination = ({ currentPage, itemsPerPage, length, onPageChanged }) => {

    const pagesCount = Math.ceil(length / itemsPerPage);
    const pages = [];

    for (let i = 1; i <= pagesCount; i++) {
        pages.push(i);
    }

    return (<div>
     {length > itemsPerPage &&   <ul className="pagination">
            <li className={"page-item rounded-5 border me-2 "   + (currentPage === 1 && " disabled ")}>
                <div  className="page-link"  type='button' onClick={() => onPageChanged(currentPage - 1)} >


                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="15 6 9 12 15 18"></polyline></svg>
                

                </div>
            </li> 
            {pages.map((page) => (
                <li
                    key={page}
                    className={"page-item mx-1 rounded-5 border "   + (currentPage === page && "active rounded-5")} 
                >
                    <div
                   
                        className={"page-link " + (currentPage === page && "text-white rounded-5")}
                        onClick={() => onPageChanged(page)}
                        type='button'
                    >
                        {page}
                    </div>
                </li>
            ))}
            <li className={"page-item rounded-5 border ms-2 "   + (currentPage === pagesCount && " disabled ")}>
                <div className="page-link" type='button' onClick={() => onPageChanged(currentPage + 1)}>
                   
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="9 6 15 12 9 18"></polyline></svg>

                </div>
            </li>
        </ul>}

        {/* <ul className="pagination pagination-sm">
            <li className={"page-item rounded-5 border "+ (currentPage === 1 && " disabled ")}>
                <button
                    className="page-link"
                    onClick={() => onPageChanged(currentPage - 1)}
                >
                    &laquo;
                </button>
            </li>
            {pages.map((page) => (
                <li
                    key={page}
                    className={"page-item rounded-5 border "+ (currentPage === page && " active ")}
                >
                    <button
                        className="page-link"
                        onClick={() => onPageChanged(page)}
                    >
                        {page}
                    </button>
                </li>
            ))}
            <li
                className={"page-item rounded-5 border "+ (currentPage === pagesCount && " disabled ")}
            >
                <button
                    className="page-link"
                    onClick={() => onPageChanged(currentPage + 1)}
                >
                    &raquo;
                </button>
            </li>
        </ul> */}
    </div>);
};

Pagination.getData = (items, currentPage, itemsPerPage) => {
    const start = currentPage * itemsPerPage - itemsPerPage;
    return items.slice(start, start + itemsPerPage);
};

export default Pagination;