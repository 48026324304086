import { faAdd, faCancel, faEdit, faEye, faFile, faSchool, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Pagination from '../../../outils/Pagination'
import LoadingBar from 'react-top-loading-bar'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { FichierLesson_API, file_url, LESSON_API, ListLessonHateaos_API, moduleByLesson, MODULE_API } from '../../../service/config'
import axios from 'axios'
import { toast } from 'react-toastify'



const animatedComponents = makeAnimated();
const Fichier = [
    { value: 'Video', label: 'Video' },
    { value: 'Photo', label: 'Photo' },
    { value: 'Document', label: 'Document' }
]
export default function GererLesson() {
    const navigate =useNavigate()
    const ref = useRef(null)
    const [ID, setID] = useState(null);
    const [loadModule, setLoadModule] = useState(false)
    let dataLes = []
    const [idLF, setidLF] = useState(null);
    const [load, setLoad] = useState(false)
    const [barcolor, setBarcolor] = useState("blue")
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const [error, setError] = useState([]);
    const [options, setOptions] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [lesson, setLesson] = useState({
        "titre_les": "",
        "desc_les": ""
    })
    const vide = {
        "titre_les": "",
        "desc_les": "",
        "img_les ": null,
        "id_mod ": ""
    }
    const [formValues, setFormValues] = useState([])
    const handleChange = ({ currentTarget }) => {
        const { name, value } = currentTarget;
        setLesson({ ...lesson, [name]: value });
    };

    const [image, setImage] = useState(null)
    const onImageChange = (e) => {
       
            if (e.target.files && e.target.files[0]) {
                setImage(URL.createObjectURL(e.target.files[0]));
                setLesson({ ...lesson, img_les: e.target.files[0] });
            }
        


    }
    const onChangefichier = (i, e) => {
        let formfichier = [...formValues];
        
            if (e.target.files && e.target.files[0]) {
                formfichier[i]['fichierl'] = e.target.files[0];
            }
            setFormValues(formfichier);
       
    }
    let handleChangeFichier = (i, e) => {
        let formfichier = [...formValues];
        formfichier[i][e.target.name] = e.target.value;
        formfichier[i]["index"] = i
        setFormValues(formfichier);
        if (e.target.name === "type_fichl") {
            formValues.forEach((element, index) => {
                document.getElementById(`type${index}`).innerHTML = ""
                if (element.type_fichl === "" || element.type_fichl === "choisir ici...") {
                    document.getElementById(`type${index}`).innerHTML = "<p className='text-danger fs-5'>type fichier requis</p>"
                } else {
                    document.getElementById(`type${index}`).innerHTML = ""
                }
            })
        }
    }


    const addInput = (e) => {
        e.preventDefault();
        const nvInput = [...formValues, { titre_fichl: "", type_fichl: "", fichierl: null, id_les: idLF }]
        setFormValues(nvInput)
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    const [ito, setIto] = useState([])
    const change = (choice) => {
        var text = "";
        setIto(choice)
        choice.forEach(element => {
            text += element.value + ","
        });
        console.log(text)
        setLesson({ ...lesson, "id_mod": text });

    }


    const handlePageChange = (page) => {
        setCurrentPage(page);

    };
    const ajoutLessonFichier = (e) => {
        e.preventDefault();
        var process = 0;
        setLoad(true)
        for (let index = 0; index < formValues.length; index++) {
            const element = formValues[index];
            document.getElementById(`type${index}`).innerHTML = ""
            if (element.type_fichl === "" || element.type_fichl === "choisir ici...") {

                document.getElementById(`type${index}`).innerHTML = "<p className='text-danger fs-5'>type fichier requis</p>"
                setLoad(false)
                break;
            } else {
                document.getElementById(`type${index}`).innerHTML = ""
                if (index === formValues.length - 1) {
                    formValues.forEach(element => {
                        process++
                        try {
                            axios.post(FichierLesson_API + "?_method=PUT", element, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            }).then(response => {
                                console.log(response);
                                if (process === formValues.length) {
                                    toast.success("Fichier ajouter avec success")
                                    document.getElementById('fermerModal').click();
                                    setLoad(false)
                                }

                            }).catch((error) => {
                                console.log(error)
                                const msg = error.response.data
                                console.log(msg)


                            })
                            setError([])
                        } catch (error) {
                            console.log(error)
                            setLoad(false)
                        }
                    });
                }
            }

        }



    }

    const paginatedInvoices = Pagination.getData(lessons, currentPage, itemsPerPage);
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formValues)
        console.log(lesson)
        setLoad(true)
        const apiErrors = {}
        try {
            await axios.post(LESSON_API, lesson, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then((response) => {
                toast.success("insertion reussi")
                getAllLesson();
                let id_les = response.data.data.id_les;
                setidLF(id_les);


                setLesson(vide);
                setLoad(false);

                console.log(response);
                console.log(id_les)


            }).catch((error) => {
                console.log(error)
                const msg = error.response.data
                setLoad(false)
                if (msg) {
                    apiErrors.titre_les = msg.titre_les
                    apiErrors.desc_les = msg.desc_les
                    apiErrors.img_les = msg.img_les
                    apiErrors.id_mod = msg.id_mod


                }


                console.log(error)
            })
            setError(apiErrors);
            setLoad(false)
        } catch (error) {
            toast.error("probleme de connexion au serveur !")
            console.log(error)
            setLoad(false)
        }
    }
    const GetModuleByoneLesson = async (module) => {

        const GETIO = [];
        try {
            var i = 0
            module.forEach(element => {
                i++
                GETIO.push({ "value": element.modules.id_mod, "label": element.modules.titre_mod })
                if (i === module.length) {
                    setIto(GETIO)
                    change(GETIO)
                }
            });

        } catch (error) {

        }




    }

    const getAllLesson = async () => {
        ref.current.continuousStart()
        setBarcolor("blue")

        try {
            axios.get(ListLessonHateaos_API).then((response) => {
                console.log(response)
                setLessons(response.data.data)
                ref.current.complete()
            }).catch((error) => {
                console.log(error)
                setBarcolor("red")
            })

        } catch (error) {
            console.log(error)
        }
    }
    const getAllModule = async () => {

        setBarcolor("blue")
        const getModules = [];
        try {
            axios.get(MODULE_API).then((response) => {
                console.log(response)
                const data = response.data.data;

                data.forEach(element => {
                    getModules.push({ "value": element.id_mod, "label": element.titre_mod })
                });

            }).catch((error) => {
                console.log(error)
                setBarcolor("red")
            })
            setOptions(getModules)
        } catch (error) {
            console.log(error)
        }
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        setLoad(true)
        const apiErrors = {}
        try {
            await axios.post(LESSON_API + "/" + ID + "?_method=PUT", lesson, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then((response) => {
                document.getElementById('fermermodif').click();
                toast.success("modification reussi")
                getAllLesson()
                setLoad(false)
                console.log(response);
            }).catch((error) => {
                toast.warn("un probleme est sruvenu")
                console.log(error)
                const msg = error.response.data
                setLoad(false)
                if (msg) {
                    apiErrors.titre_les = msg.titre_les
                    apiErrors.desc_les = msg.desc_les

                }


                console.log(error)
            })
            setError(apiErrors);
            setLoad(false)
        } catch (error) {
            toast.error("probleme de connexion au serveur !")
            console.log(error)
            setLoad(false)
        }
    }

    const supprimer = async () => {
        setLoad(true)
        try {
            await axios.delete(LESSON_API + "/" + ID).then((response) => {
                toast.success("Lesson numero " + ID + " supprimer")
                setLoad(false)
                document.getElementById('fermerdel').click();
                getAllLesson();
            }).catch((error) => {
                console.log(error)
                toast.error("module pas supprimer!")
                setLoad(false)
            })
        } catch (error) {
            toast.error("probleme interne");
            setLoad(false)
        }
    }

    const fetchUpdate = (les, module) => {
        setID(les.id_les)
        setLesson(vide)
        setIto([])
        GetModuleByoneLesson(module)
        const updt = {};
        setImage(file_url + les.img_les)
        updt.titre_les = les.titre_les
        updt.desc_les = les.desc_les
        var text = "";
        module.forEach(element => {
            text += element.id_mod + ","
        });
        updt.id_mod = text
        setLesson(updt)

    }

    const initialiser = () => {
        setIto([])
        setLesson(vide)
        setFormValues([])
        setImage(null)
        setError(vide)
        setidLF(null)
        console.log("click")
        document.getElementById("reset_form").click()
    };


    // calls whenever state will be change , another approach
    useEffect(() => {
        getAllLesson();
        getAllModule();
    }, [])

    return (
        <>
            <LoadingBar ref={ref} color={barcolor} />
            <div className='container-lg container-fluid py-4'>
                <div className="modal modal-blur" id="modal-danger" tabIndex="-1" aria-modal="true" role="dialog">
                    <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="modal-status bg-danger"></div>
                            <div className="modal-body text-center py-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-danger icon-lg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 9v2m0 4v.01"></path><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path></svg>
                                <h3>vous etes sur ?</h3>
                                <div className="text-muted">vous-voulez vraiment supprimer cette leçon? c'est irreversible.</div>
                            </div>
                            <div className="modal-footer">
                                <div className="w-100">
                                    <div className="row">
                                        <div className="col"><button type='button' id='fermerdel' className="btn w-100" data-bs-dismiss="modal">
                                            Annuler
                                        </button></div>
                                        <div className="col"><button type='button' onClick={supprimer} className={"btn btn-danger w-100 " + (load && "disabled")}>
                                            Supprimer leçon
                                        </button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal modal-blur" tabIndex="-1" id='exampleModal'>
                    <div className="modal-dialog modal-lg" role="document">
                        <form className='my-3' onSubmit={idLF == null ? handleSubmit : ajoutLessonFichier}>

                            <div className="modal-content">
                                <div className='d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black'>
                                    <h2 className='p-2'>Formulaire leçon</h2>

                                    <button className="btn-close p-0" type='button' data-bs-dismiss="modal" id='fermerModal' aria-label="Close"></button>
                                </div>
                                <button type="reset" className="p-0 m-0" style={{ visibility: "hidden" }} id="reset_form"></button>
                                {idLF == null ? (<div className="modal-body py-0" style={{ height: "550px", overflowY: "auto" }}>

                                    <div className='my-3'>
                                        <label className="form-label">Titre</label>
                                        <input className={'form-control ' + (error.titre_les && "is-invalid")} type="text" name='titre_les' value={lesson.titre_les} onChange={handleChange} placeholder='Saisir titre leçon' />
                                        {error.titre_les && <p className='invalid-feedback'>{error.titre_les}</p>}
                                    </div>
                                    <div className='my-3 row'>
                                        <div className='col'>
                                            <label className="form-label">Modules</label>
                                            <Select
                                                closeMenuOnSelect
                                                components={animatedComponents}
                                                defaultValue={[options[4], options[5]]}
                                                isMulti
                                                name='id_mod'
                                                placeholder="choix module"
                                                options={options}
                                                value={ito}
                                                onChange={(choice) => change(choice)}
                                                noOptionsMessage={() => "plus d'options"}
                                            />
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <label className="form-label">Image de representation du leçon</label>
                                        <div className='row'>
                                            <div className='col-lg-6 col-12 mb-2'>
                                                <input type="file" className={'form-control ' + (error.img_les && "is-invalid")} id="img_les" onChange={onImageChange} placeholder='choisir un image' accept="image/png, image/gif, image/jpeg" multiple />
                                                {error.img_les && <p className='invalid-feedback'>{error.img_les}</p>}
                                            </div>

                                            {image && (<span className="avatar avatar-sm" style={{ backgroundImage: `url(${image})` }}></span>)}

                                        </div>
                                    </div>
                                    <div className='my-3'>
                                        <label className="form-label">Description du leçon</label>
                                        <textarea rows={5} className={'form-control ' + (error.desc_les && "is-invalid")} name="desc_les" value={lesson.desc_les} onChange={handleChange} placeholder='saisir description'></textarea>
                                        {error.desc_les && <p className='invalid-feedback'>{error.desc_les}</p>}
                                    </div>
                                </div>) :
                                    (<div className="modal-body py-0" style={{ height: "550px", overflowY: "auto" }}>
                                        <p className='lead my-2'>S'il y a des fichier qui doit etre enregistrer avec le leçon veuillez les ajouter ici, sinon cliquer sur le bouton <span className='fw-bold text-primary'>Terminer</span></p>
                                        <div className='mt-1'>
                                            <div className='row my-3'>
                                                <div className='col d-flex align-items-center gap-3'>
                                                    <label className="form-label">Fichier rattaché</label>  <button className="btn btn-primary" onClick={addInput}><FontAwesomeIcon icon={faAdd} className="pe-2" />Ajouter un champs</button>
                                                </div>

                                            </div>
                                            <div className='row'>

                                                {formValues.map((element, index) => (
                                                    <div className="col-6 d-flex gap-2 my-2" key={index}>
                                                        <div className='card p-3 w-100'>
                                                            <div className='mt-1'>
                                                                <label className="form-label">Fichier du leçon </label>
                                                                <input type="file" name="fichierl" id={`fichier_les${index}`} className='form-control' onChange={(e) => onChangefichier(index, e)} required />
                                                            </div>
                                                            <div className='mt-3'>
                                                                <label className="form-label">Titre du fichier</label>
                                                                <input value={element.titre_fichl} className='form-control' name='titre_fichl' onChange={(e) => handleChangeFichier(index, e)} required />
                                                            </div>
                                                            <div className='my-3'>
                                                                <label className="form-label">Type du fichier</label>
                                                                <select  className="form-control" value={element.type_fichl} name='type_fichl' id='type_fichl' onChange={(e) => handleChangeFichier(index, e)} required>
                                                                    <option>choisir ici...</option>
                                                                    <option value="Photo">Photo</option>
                                                                    <option value="Video">Video</option>
                                                                    <option value="Audio">Audio</option>
                                                                    <option value="Document">Document</option>
                                                                    <option value="Autre">Autre</option>

                                                                </select>
                                                                <div style={{ color: "red" }} id={`type${index}`}></div>
                                                            </div>
                                                            {
                                                                index ?
                                                                    <button type="button" className="btn btn-danger" onClick={() => removeFormFields(index)}><FontAwesomeIcon icon={faTrash} /></button>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>


                                    </div>)}
                                <div className="modal-footer">
                                    <div className="w-100">
                                        <div className="row justify-content-between">
                                            <div className={"col d-flex justify-content-start"}>{idLF == null ? (<button type='button' className="btn" data-bs-dismiss="modal">
                                                <FontAwesomeIcon icon={faCancel} className="me-2" />   Annuler
                                            </button>) :
                                                (<button type='button' className="btn" data-bs-dismiss="modal">
                                                    <FontAwesomeIcon icon={faCancel} className="me-2" />Terminer
                                                </button>)}
                                            </div>
                                            <div className="col d-flex justify-content-end"><button type='submit' className={"btn btn-primary " + (load && "disabled")} >
                                                <FontAwesomeIcon icon={faAdd} className="me-2" /> {idLF == null ? <>Suivant</> : <>Ajouter</>}
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <div className="modal modal-blur" tabIndex="-1" id='modif'>
                    <div className="modal-dialog modal-lg" role="document">
                        <form className='my-3' onSubmit={handleUpdate}>
                            <div className="modal-content">
                                <div className='d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black'>
                                    <h2 className='p-2'>Formulaire leçon</h2>
                                    <button className="btn-close p-0" type='button' data-bs-dismiss="modal" id='fermermodif' aria-label="Close"></button>
                                </div>

                                <div className="modal-body py-0" style={{ height: "550px", overflowY: "auto" }}>

                                    <div className='my-3'>
                                        <label className="form-label">Titre</label>
                                        <input className={'form-control ' + (error.titre_les && "is-invalid")} type="text" name='titre_les' value={lesson.titre_les} onChange={handleChange} placeholder='Saisir titre du module' />
                                        {error.titre_les && <p className='invalid-feedback'>{error.titre_les}</p>}
                                    </div>
                                    <div className='my-3 row'>
                                        <div className='col'>
                                            <label className="form-label">Modules</label>
                                            <Select
                                                closeMenuOnSelect
                                                components={animatedComponents}
                                                defaultValue={[options[4], options[5]]}
                                                isMulti
                                                name='id_mod'
                                                placeholder="choix module"
                                                options={options}
                                                value={ito}
                                                onMenuOpen={() => getAllModule()}
                                                onChange={(choice) => change(choice)}
                                                noOptionsMessage={() => "plus d'options"}
                                                isDisabled={loadModule}
                                                isLoading={loadModule}
                                            />
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <label className="form-label">Image de representation du leçon</label>
                                        <div className='row'>
                                            <div className='col-lg-6 col-12 mb-2'>
                                                <input type="file" className={'form-control ' + (error.img_les && "is-invalid")} id="img_les" onChange={onImageChange} placeholder='choisir un image' accept="image/png, image/gif, image/jpeg" multiple />
                                                {error.img_les && <p className='invalid-feedback'>{error.img_les}</p>}
                                            </div>

                                            {image && (<span className="avatar avatar-sm" style={{ backgroundImage: `url(${image})` }}></span>)}

                                        </div>
                                    </div>
                                    <div className='my-3'>
                                        <label className="form-label">Description du leçon</label>
                                        <textarea rows={5} className={'form-control ' + (error.desc_les && "is-invalid")} name="desc_les" value={lesson.desc_les} onChange={handleChange} placeholder='saisir description'></textarea>
                                        {error.desc_les && <p className='invalid-feedback'>{error.desc_les}</p>}
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <div className="w-100">
                                        <div className="row justify-content-between">
                                            <div className={"col d-flex justify-content-start"}>
                                                <button type='button' className="btn" data-bs-dismiss="modal" aria-label="Close">
                                                    <FontAwesomeIcon icon={faCancel} className="me-2" />   Annuler
                                                </button>

                                            </div>
                                            <div className="col d-flex justify-content-end"><button type='submit' className={"btn btn-primary " + (load && "disabled")} >
                                                <FontAwesomeIcon icon={faAdd} className="me-2" />Modifier
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <div className='d-flex flex-row align-items-center justify-content-between'>
                    <div>
                        <h2>Leçons</h2>
                        <p className=''>Liste des leçons</p></div>
                    <div>
                        <button type="button" onClick={() => navigate("/admin/gerer_lesson/new")} className='btn btn-primary px-4 py-2'><FontAwesomeIcon icon={faAdd} className="me-2" /> Nouvelle leçon</button>
                    </div>
                </div>

                <div className='overflow-auto'>
                    <table className='table table-hover table-bordered table-striped table-light '>
                        <thead>
                            <tr className=''>
                                <th>ID</th>
                                <th>Titre</th>
                                <th className='col-2'>Module</th>
                                <th>Description</th>
                                <th>Image</th>
                                <th>Fichier</th>
                                <th className='col-1'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedInvoices.map((element, index) => <tr key={index}>
                                <td>{element.id_les}</td>
                                <td>{element.titre_les}</td>
                                <td className='text-center'><div className='d-flex flex-wrap gap-1'>{element.modulelessons.map((el, index) => <span key={index} className='badge bg-cyan'>{el.modules.titre_mod}</span>)}</div></td>
                                <td>{element.desc_les}</td>
                                <td> <span className="avatar avatar-sm" style={{ backgroundImage: `url(${file_url}/${element.img_les})` }}></span></td>

                                <td className='text-center'><Link to={`/admin/fichier_les/${element.id_les}`} className='nav-link '><span className='badge bg-info py-2 px-2'><FontAwesomeIcon icon={faFile} className="me-2" />Gerer</span></Link></td>
                                <td className='d-flex gap-2 justify-content-center'>
                                   <Link to={'/admin/gerer_lesson/edit'} state={{data:element}} className="text-decoration"> <button  className='btn btn-primary'>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button></Link>
                                    <button type='button' onClick={() => setID(element.id_les)} data-bs-toggle="modal" data-bs-target="#modal-danger" className='btn btn-danger'>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button></td>
                            </tr>)}
                        </tbody>
                    </table>
                    <div className='d-flex justify-content-end'>
                        <Pagination currentPage={currentPage} itemsPerPage={itemsPerPage} onPageChanged={handlePageChange} length={lessons.length}
                        />
                    </div>


                </div>
            </div>
        </>
    )
}