import React, { useRef } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import image from "../../assets/image/imgtest/image";
import './CardHover.css'
import { Tag } from "primereact/tag";
export default function CardHover({candidat}) {
  const op = useRef(null);

const style = {color:'default',fontWeight:'600'}

  return (
    <div className="">
      <div className="fw-bold" style={{cursor:'pointer'}} onClick={(e) => op.current.toggle(e)}> {candidat?.nom_cand} {candidat?.prenom_cand}</div>
     <div >
     <OverlayPanel className="cardHover" style={{zIndex:'5000 !important'}} ref={ op }>
        <div className="d-flex gap-3">
          <div>
            <span
              class="avatar avatar-md mb-3 rounded"
              style={{ backgroundImage: `url(${image.avatar})` }}
            ></span>
          </div>
         <div>
         <div class="d-flex gap-2">
            <h4 class="">{candidat?.nom_cand} {candidat?.prenom_cand} </h4>
          </div>
         <div>
         <div class="text-muted my-1"><span style={style}>ID : </span>{candidat?.id_cand}</div>
        {candidat?.cin_cand && (<div class="text-muted my-1"><span style={style}>CIN : </span>{candidat?.cin_cand}</div>)}
        {candidat?.email_cand && (<div class="text-muted my-1"><span style={style}>Email : </span>{candidat?.email_cand}</div>)}
        {candidat?.date_insc_cand && (<div class="text-muted my-1"><span style={style}>Date inscription : </span>{candidat?.date_insc_cand}</div>)}
          {candidat?.status_examen_cand && ( <div class="text-muted my-1"><span style={style}>Statut : </span><Tag value={candidat?.status_examen_cand }/></div>)}
         </div>
         </div>
        </div>
      </OverlayPanel>
     </div>
    </div>
  );
}
