import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";

const PrintOrExportPDF = ({ children }) => {
  const componentRef = useRef();

  return (
    <div>
      <ReactToPrint
        trigger={() => <div className="w-100 d-flex justify-content-end">
            <Button variant="success">
            <FontAwesomeIcon icon={faPrint} className="me-2" />
            Exporter
          </Button>
        </div>}
        pageStyle="@page { size: auto; margin: 0; } @media print { body { margin: 0; padding: 0; } }"
        content={() => componentRef.current}
      />
      <div ref={componentRef}>{children}</div>
    </div>
  );
};

export default PrintOrExportPDF;
