import { faAdd, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { ACTU_API, CATEGORIE_API, file_url, SENSI_API } from "../../../service/config";
import LoadingBar from "react-top-loading-bar";
import { TagsInput } from "react-tag-input-component";

export default function GererSens() {
    var text = "";
  const [id, setID] = useState(null);
  const ref = useRef(null);
  const [barColor, setBarColor] = useState("blue");
  const [tags, settags] = useState([]);
  const [mode, setMode] = useState(false);
  const [categorie, setCategorie] = useState([]);
  const [load, setLoad] = useState(false);
  const [formValues, setFormValues] = useState({
    titre_sens: "",
    desc_sens: "",
    img_sens: null,
  });
  const vide = {
    titre_sens: "",
    desc_sens: "",
    img_sens: null,
  };
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    if (value==="") {
      setError({...error,[name]:"ce champs est réquis !"})
    }else{
      setError({...error,[name]:null})
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const [image, setImage] = useState(null);

  const onImageChange = (e) => {
    // const formData = [];
    // const files = e.target.files;
    // for (let i = 0; i < files.length; i++) {
    //     formData.push(files[i]);
    //   }
    console.log(e.target.files[0].size);

    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setError({...error,img_sens:null})
      setFormValues({ ...formValues, img_sens: e.target.files[0] });
    }
  };

  function manova() {
    text = "";
    formValues.tag_sens = "";
    let nb=0
      tags.forEach((element) => {
        nb++;
        text += element + ";";
        if (nb === tags.length) {
            formValues.tag_sens = text;
          setError({ ...error, tag_sens: null });
        }
      });
      console.log(formValues.tag_sens);
  }
   useEffect(() => {
    manova()
   }, [tags])

  const getCategorie = async () => {
    setBarColor("blue");
    ref.current.continuousStart();

    try {
      await axios
        .get(SENSI_API)
        .then((response) => {
          const data = response.data.data;
          console.log(data);
          ref.current.complete();
          setCategorie(data);
        })
        .catch((error) => {
          setBarColor("red");
          console.log(error);
          console.log(error);
        });
    } catch (error) {
      toast.error("un erreur est survenu");
      setBarColor("red");
      console.log(error);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoad(true);
    const data = {};
    const msgErreur = {};
    if (formValues.img_sens == null) {
      data.titre_sens = formValues.titre_sens;
      data.desc_sens = formValues.desc_sens;
    } else {
      data.titre_sens = formValues.titre_sens;
      data.tag_sens = formValues.tag_sens;
      data.desc_sens = formValues.desc_sens;
      data.img_sens = formValues.img_sens;
    }
    setFormValues(data);
    if (mode) {
      try {
        await axios
          .post(SENSI_API + "/" + id + "?_method=PUT", formValues, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            document.getElementById("fermer").click();
            toast.success("Element modifiée");
            setLoad(false);

            getCategorie();
          })
          .catch((error) => {
            console.log(error);
            const msg = error.response.data;
            console.log(msg);
            if (msg) {
              msgErreur.titre_sens = msg.titre_sens;
              msgErreur.tag_sens = msg.tag_sens;
              msgErreur.desc_sens = msg.desc_sens;
              msgErreur.img_sens = msg.img_sens;
            }
            toast.error("un erreur survenu");
            setLoad(false);
            setError(msgErreur);
          });
        setError([]);
      } catch (error) {
        console.log(error);
        toast.error("probleme liee au serveur");
        setLoad(false);
      }
    } else {
      try {
        await axios
          .post(SENSI_API, formValues, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            document.getElementById("fermer").click();
            toast.success("Sensibilisation Ajouté");
            setLoad(false);
            setError([]);
            getCategorie();
          })
          .catch((error) => {
            const msg = error.response.data;
            console.log(error);
            console.log(msg);
            if (msg) {
              msgErreur.titre_sens = msg.titre_sens;
              msgErreur.tag_sens = msg.tag_sens;
              msgErreur.desc_sens = msg.desc_sens;
              msgErreur.img_sens = msg.img_sens;
            }
            toast.error("un erreur survenu");
            setLoad(false);
            setError(msgErreur);
          });
      } catch (error) {
        console.log(error);
        toast.error("probleme liee au serveur");
        setLoad(false);
      }
    }
 
  };
  function Separer(strin) {
    if (strin.length === 0 || strin === undefined || strin ===null) {
      return null;
    } else {
      var tab = strin.split(";");

      return (
        <>
          {tab.map((element, index) => (
           element!=="" && <span key={index} className="badge bg-cyan px-2 rounded-3 mx-1">
              {element}
            </span>
          ))}
        </>
      );
    }
  }
  const fetchUpdate = async (element) => {
    setError([]);
    setMode(true);
    setID(element.id_sens);
  
    formValues.titre_sens = element.titre_sens;
    formValues.desc_sens = element.desc_sens;
  
    setImage(`${file_url}${element.img_sens}`);
  };
  const initialiser = () => {
    setMode(false);
    setFormValues(vide);
    setImage(null);
    setError([]);
  };

  const supprimer = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      await axios
        .delete(SENSI_API + "/" + id)
        .then((response) => {
          toast.success("Sensibilisation " + id + " supprimer");
          setLoad(false);
          document.getElementById("fermerdel").click();
          getCategorie();
        })
        .catch((error) => {
          console.log(error);
          toast.error("Sensibilisation pas supprimer!");
          setLoad(false);
        });
    } catch (error) {
      toast.error("probleme interne");
      setLoad(false);
    }
  };

  useEffect(() => {
    getCategorie();
  }, []);

  return (
    <>
      <LoadingBar color={barColor} ref={ref} />
      <div className="container-lg container-fluid py-4">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <h2>Sensibilisations</h2>
            <p className="">Liste des Sensibilisations</p>
          </div>
          <div>
            <button
              type="button"
              onClick={() => initialiser()}
              className="btn btn-primary px-4 py-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <FontAwesomeIcon icon={faAdd} className="me-2" /> Nouvelle
              Sensibilisation
            </button>
          </div>
        </div>
        <div className="row">
          <div className="overflow-auto">
            <table className="table table-hover table-bordered table-striped table-light ">
              <thead>
                <tr className="">
                  <th>ID</th>
                  <th>Titre</th>
                  <th>déscription</th>
                  <th>Image</th>
                  <th className="col-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {categorie.map((element) => (
                  <tr key={element.id_sens}>
                    <td>{element.id_sens}</td>
                    <td>{element.titre_sens}</td>
                    <td>{element.desc_sens}</td>
                    <td>
                      {" "}
                      <span
                        className="avatar avatar-sm"
                        style={{
                          backgroundImage: `url(${file_url}/${element.img_sens})`,
                        }}
                      ></span>
                    </td>
                    <td className="d-flex gap-2 justify-content-center">
                      <button
                        type="button"
                        className="btn-action"
                        onClick={() => fetchUpdate(element)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <FontAwesomeIcon color="blue" icon={faEdit} />
                      </button>
                      <button
                        type="button"
                        className="btn-action"
                        onClick={() => setID(element.id_sens)}
                        data-bs-toggle="modal"
                        data-bs-target="#modal-danger"
                      >
                        <FontAwesomeIcon color="red" icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="modal modal-blur"
        id="modal-danger"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
      >
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-status bg-danger"></div>
            <div className="modal-body text-center py-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon mb-2 text-danger icon-lg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 9v2m0 4v.01"></path>
                <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
              </svg>
              <h3>vous etes sur ?</h3>
              <div className="text-muted">
                vous-voulez vraiment supprimer cette Sensibilisation ? c'est
                irréversible.
              </div>
            </div>
            <div className="modal-footer">
              <div className="w-100">
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      id="fermerdel"
                      className="btn w-100"
                      data-bs-dismiss="modal"
                    >
                      Annuler
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      onClick={supprimer}
                      className={"btn btn-danger w-100 " + (load && "disabled")}
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal modal-blur" tabIndex="-1" id="exampleModal">
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <form
              className=""
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black">
                <h2 className="p-2">Formulaire Sensibilisation</h2>
                <button
                  className="btn-close p-0"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body py-0">
                <div className="my-3">
                  <label className="form-label">Titre</label>
                  <input
                    className={
                      "form-control " + (error.titre_sens && "is-invalid")
                    }
                    name="titre_sens"
                    value={formValues.titre_sens}
                    onChange={handleChange}
                    type="text"
                    placeholder="Saisir titre"
                  />
                  {error.titre_sens ? (
                    <div className="invalid-feedback">{error.titre_sens}</div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="my-3">
                  <label className="form-label">Image </label>
                  <input
                    className={
                      "form-control " + (error.img_sens && "is-invalid")
                    }
                    name="img_sens"
                    id="img_sens"
                    type="file"
                    onChange={onImageChange}
                    accept="image/*"
                  />
                  {error.img_sens && (
                    <div className="invalid-feedback">{error.img_sens}</div>
                  )}
                </div>
                <div className="my-3">
                  {image && (
                    <span
                      className="avatar avatar-sm"
                      style={{ backgroundImage: "url(" + image + ")" }}
                    ></span>
                  )}
                </div>
                <div className="my-3">
                  <label className="form-label">Déscription du Sensibilisation</label>
                  <textarea
                    rows={5}
                    className={
                      "form-control " + (error.desc_sens && "is-invalid")
                    }
                    name="desc_sens"
                    value={formValues.desc_sens}
                    onChange={handleChange}
                    placeholder="saisir description"
                  ></textarea>
                  {error.desc_sens && (
                    <div className="invalid-feedback">{error.desc_sens}</div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <div className="w-100">
                  <div className="row justify-content-between ">
                    <div className="col-3">
                      <button
                        id="fermer"
                        type="button"
                        className="btn w-100"
                        data-bs-dismiss="modal"
                      >
                        Annuler
                      </button>
                    </div>
                    <div className="col-3">
                      <button
                        type="submit"
                        className={
                          "btn btn-primary w-100 " + (load && "disabled")
                        }
                      >
                        <FontAwesomeIcon icon={faAdd} className="me-2" />{" "}
                        {mode ? <>Modifier</> : <>Ajouter</>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
