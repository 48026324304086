import jwtDecode from "jwt-decode";
import { ExamenList } from "./config";

const Authenticated= () => {
    const token =  window.localStorage.getItem("authToken");
    if (token) {
        const { nom_util } = jwtDecode(token)
        if (nom_util) {
            return true;
        }
        return false;
    }
    return false;
}


const user= () => {
    const token =  window.localStorage.getItem("authToken");
    if (token) {
        const user_data = jwtDecode(token)
        if (user_data) {
            return user_data;
        }
        return false;
    }
    return false;
}


export default {Authenticated,user};