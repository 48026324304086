import {
    faAdd,
    faCheckCircle,
    faCheckSquare,
    faEdit,
    faEraser,
    faFilter,
    faHouse,
    faRotate,
    faTrash,
    faUndoAlt,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import axios from "axios";
  import React, { useState } from "react";
  import { useEffect } from "react";
  import {
    Button,
    Modal,
    FormControl,
    Form,
    Overlay,
    OverlayTrigger,
    Tooltip,
    Popover,
  } from "react-bootstrap";
  import DataTable from "react-data-table-component";
  import { TailSpin } from "react-loader-spinner";
  import { toast } from "react-toastify";
  import InputMask from "react-input-mask";
  import { Tag } from "primereact/tag";
import { affecttheorie_API, candidat_API, getCandidatBeforeVld, listeCandTheorie, organisme_API, updateTypeCand_API } from "../../../../../service/config";
import CardHover from "../../../../../outils/HoverCard/CardHover";
import SelectOneId from "../../../../../outils/Select/selectCustomId";
import { useSelector } from "react-redux";
import ListCandidatsPrint from "../../../../../service/Print/ListCandidatsPrint";
import SelectFinal from "../../../../../outils/Select/SelectFinal";
  

  
  export default function CandAttente() {
    const abortController = new AbortController();
    const [seteventy,eventy] = useState("")
    const categ = useSelector((state) => state.categ);
    const columns = [
     
        {
            width: "23%",
            name: "Nom et prenom",
            selector: (row) => (
          <CardHover candidat={row}/>
            ),
            sortable: true,
          },
        {
          name: "Permis",
          selector: (row) => row.categ_permis,
          sortable: true,
        },
        {
          name: "Email",
          selector: (row) => row.email_cand,
          sortable: true,
        },
        {
          name: "Date d'inscription",
          selector: (row) => row.date_insc_cand,
          sortable: true,
        },
        {
          name: "Statut",
          selector: (row) => <Tag severity={'info'} value={row.status_examen_cand}/>,
          sortable: true,
        },
    
        /*{
          width: "105px",
          name: "statut",
          selector: (row) => (
            <span className="badge bg-cyan">{row.active_cand}</span>
          ),
          sortable: true,
        },*/
        // ,
        // {
        //   width: "105px",
        //   cell: (row) => (
        //     <div className="">
        //       <button
        //         className="btn btn-primary"
        //         onClick={() => {
        //           // alert(JSON.stringify(row));
        //           setmode(true);
        //           setFormValues(row);
        //           setShow(true);
        //           setImage(`${file_url}/${row.photo_cand}`);
        //           setcertificat(`${file_url}/${row.scan_certifica_cand}`);
        //           setcin(`${file_url}/${row.scan_cin_can}`);
        //         }}
        //       >
        //         <FontAwesomeIcon icon={faEdit} />
        //       </button>
        //     </div>
        //   ),
        //   ignoreRowClick: true,
        //   allowOverflow: true,
        //   button: true,
        // },
      ];
    const columns_valid = [
        {
            width: "23%",
            name: "Nom et prenom",
            selector: (row) => (
          <CardHover candidat={row}/>
            ),
            sortable: true,
          },
      {
        name: "Adresse",
        selector: (row) => row.adresse_cand,
        sortable: true,
      },
      {
        name: "Téléphone",
        selector: (row) => row.tel_cand,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email_cand,
        sortable: true,
      },
      {
        name: "type",
        selector: (row) => row.type_cand,
        sortable: true,
      },
      /*,
      {
        width: "105px",
        name: "statut",
        selector: (row) => (
          <span className="badge bg-cyan">{row.active_cand}</span>
        ),
        sortable: true,
      }*/ {
        name: "action",
        cell: (row) => (
          <div className="">
            <button
              className="btn btn-primary"
              onClick={() => {
                let mis = candidatSelect.filter(
                  (el) => el.id_cand != row.id_cand
                );
                setcandidatSelect(mis);
              }}
            >
              <FontAwesomeIcon icon={faEraser} className="me-2" /> retirer
            </button>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];
    const confCand = [
      // {
      //   name: "ID",
      //   selector: (row) => row.id_cand,
      //   sortable: true,
      // },
      {
        width: "23%",
        name: "Nom et prenom",
        selector: (row) => (
          <OverlayTrigger
            placement="bottom"
            trigger={["hover"]}
            overlay={
              <Popover id={`popover${row.id_cand}`}>
                <Popover.Header as="h3">
                  {row.nom_cand} {row.prenom_cand}
                </Popover.Header>
                <Popover.Body>
                  <p>
                    Identifiant : <strong>{row.id_cand}</strong>
                  </p>
                  <p>
                    Permis : <strong>{row.categ_permis}</strong>
                  </p>
                </Popover.Body>
              </Popover>
            }
          >
            <div className="text-primary text-underline">
              {" "}
              {row.nom_cand} {row.prenom_cand}
            </div>
          </OverlayTrigger>
        ),
        sortable: true,
      },
      {
        name: "Permis",
        selector: (row) => row.categ_permis,
        sortable: true,
      },
      {
        name: "Date de naissance",
        selector: (row) => row.naiss_cand,
        sortable: true,
      },
      {
        name: "Ville",
        selector: (row) => row.ville_cand,
        sortable: true,
      },
    ];
    const [data, setdata] = useState([
      {
        nom: "Razafindrakoto",
        prenom: "Tiana",
        adresse: "2 Rue des Baobabs, Antsirabe",
        telephone: "+261 34 98 765 43",
        email: "tiana.razafindrakoto@example.com",
        apprenant: "non valide",
        status: "repassage",
        datainsc: "2022-01-03",
      },
      {
        nom: "Rakoto",
        prenom: "Jean",
        adresse: "12 Rue des Fleurs, Antananarivo",
        telephone: "+261 32 12 345 67",
        email: "jean.rakoto@example.com",
        apprenant: "valide",
        status: "passant",
        datainsc: "2022-02-05",
      },
      {
        nom: "Raharisoa",
        prenom: "Tina",
        adresse: "4 Rue des Coquelicots, Antsiranana",
        telephone: "+261 34 23 456 78",
        email: "tina.raharisoa@example.com",
        apprenant: "valide",
        status: "repassage",
        datainsc: "2021-12-31",
      },
      {
        nom: "Andrianasolo",
        prenom: "Luc",
        adresse: "1 Rue des Lilas, Antananarivo",
        telephone: "+261 32 87 654 32",
        email: "luc.andrianasolo@example.com",
        apprenant: "non valide",
        status: "repassage",
        datainsc: "2022-01-01",
      },
      {
        nom: "Randriamanantena",
        prenom: "Sandra",
        adresse: "8 Rue des Roses, Fianarantsoa",
        telephone: "+261 34 56 789 01",
        email: "sandra.randriamanantena@example.com",
        apprenant: "valide",
        status: "passant",
        datainsc: "2022-01-23",
      },
      {
        nom: "Ratovonirina",
        prenom: "Feno",
        adresse: "7 Rue de la Mer, Toamasina",
        telephone: "+261 33 45 678 90",
        email: "feno.ratovonirina@example.com",
        apprenant: "valide",
        status: "repassage",
        datainsc: "2022-02-01",
      },
      {
        nom: "Ratsimba",
        prenom: "Fara",
        adresse: "5 Rue des Narcisses, Antsirabe",
        telephone: "+261 32 34 567 89",
        email: "fara.ratsimba@example.com",
        apprenant: "valide",
        status: "repassage",
        datainsc: "2022-01-01",
      },
    ]);
  
    const [textfiltre, settextfiltre] = useState("");
    const [toggledClearRows, settoggledClearRows] = useState(false);
    const [showvalid, setshowvalid] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [candidatSelect, setcandidatSelect] = useState([]);
    const [categorie, setcategorie] = useState([]);
    const [load, setLoad] = useState(false);
    const [rotation, setRotation] = useState(0);
    const handleClearRows = () => {
      settoggledClearRows(!toggledClearRows);
    };
    const [formValues, setFormValues] = useState({
    });
  
 
  

    const handleValidation = () => setshowvalid(!showvalid);
    const [error, setError] = useState([]);
   
    const getCandidats = () => {
      setLoad(true);
      axios
        .get(candidat_API + `?active_apprenant=true&type_cand=Apprenants`,{
          signal: abortController.signal,
          })
        .then((rep) => {
          console.log("first");
          console.log(rep);
          setdata(rep.data.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const getCandidatsValider = () => {
      setLoad(true);
      axios
        .get(listeCandTheorie,{
          signal: abortController.signal,
          })
        .then((rep) => {
          console.log("first");
          console.log(rep.data);
          setdata(rep.data.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const getCandidatBeforeValidation = () => {
      setLoad(true);
      axios
        .get(getCandidatBeforeVld,{
          signal: abortController.signal,
          })
        .then((rep) => {
          console.log("first");
          console.log(rep);
          setdata(rep.data.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  

    const ValiderCandidat = () => {
      setLoad(true);
      for (let index = 0; index < candidatSelect.length; index++) {
        const element = candidatSelect[index];
        axios
          .put(
            updateTypeCand_API + "/" + element.id_cand,
           { type_cand: "Apprenants" }
          )
          .then((reponse) => {
            console.log(reponse.data);
            if (index == candidatSelect.length - 1) {
              setLoad(false);
              toast.success(
             
                   "la candidature des candidats selectionnée ont été annuler"
              );
              handleValidation();
              handleClearRows();
              getCandidatBeforeValidation();
            }
          })
          .catch((error) => {
            toast.error(error.response.message);
            if (index == candidatSelect.length - 1) {
              console.log(error);
              setLoad(false);
              toast.error(error.response.message);
              handleValidation();
              handleClearRows();
            }
          });
      }
    };
    const EnvoyerCandidat = () => {
      setLoad(true);
      for (let index = 0; index < candidatSelect.length; index++) {
        const element = candidatSelect[index];
        axios
          .post(affecttheorie_API, { id_cand: element.id_cand,categ:formValues?.categ })
          .then((reponse) => {
            console.log(reponse.data);
  
            if (index == candidatSelect.length - 1) {
              setLoad(false);
              toast.success("liste des candidats envoyée  avec succées");
              handleValidation();
              handleClearRows();
              getCandidatBeforeValidation();
            }
          })
          .catch((error) => {
            error.response.data?.id_cand?.length >0 && (toast.info("Candidats déjà affecter !"));
            
           
            if (index == candidatSelect.length - 1) {
              console.log(error);
              setLoad(false);
              toast.error(error.response.message);
              handleValidation();
              handleClearRows();
            }
          });
      }
    };
  
    const datafiltrer = data.filter((item) => {
     
        return (
          (item.id_cand && item.id_cand === textfiltre) ||
          (item.email_cand &&
            item.email_cand.toLowerCase().includes(textfiltre.toLowerCase()))
        );
      
    });
 
    useEffect(() => {
        getCandidatBeforeValidation();
      settextfiltre("");
    }, []);
    return (
      <div className="container-lg container-fluid">
        <Modal
          show={showvalid}
          onHide={() => {
            handleValidation();
            handleClearRows();
          }}
          backdrop="static"
          keyboard={false}
          size={"xl"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Les apprenants qui sont sélectionnées pour être candidats </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <DataTable
                  className="custom-table"
                  title={
                    <p className="fs-2">Liste des candidats sélectionnées</p>
                  }
                  columns={columns_valid}
                  data={candidatSelect}
                  contextActions={
                    <button
                      className="btn btn-cyan me-2"
                      onClick={() => {
                        ValiderCandidat();
                      }}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                      Valider
                    </button>
                  }
                  progressComponent={
                    <div className="w-100 d-flex justify-content-center py-5">
                      <TailSpin
                        height="80"
                        width="80"
                        color="#22577A"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  }
                  contextMessage={{
                    singular: "élement",
                    plural: "élements",
                    message: "sélectionnées",
                  }}
                  onSelectedRowsChange={(rows) => {
                    setSelectedRows(rows);
                  }}
                  pagination
                  paginationPerPage={5}
                  paginationComponentOptions={{
                    rowsPerPageText: "Lignes par page",
                  }}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
          <div className="d-flex gap-2 align-items-center">
            <b>Catégorie :</b>
            <SelectFinal
                      options={categ}
                      load={false}
                      value={"label"}
                      label={"label"}
                      choix={""}
                      placeholder="choix catégorie"
                      setFormValues={setFormValues}
                      formValues={formValues}
                      setError={setError}
                      error={error}
                      name={"categ"}
                      seteventy={seteventy}
                    />
            {/* <SelectOneId
              options={categ}
              load={false}
              placeholder="choix catégorie"
              setFormValues={setFormValues}
              formValues={formValues}
              setError={setError}
              error={error}
              name={"id_categ"}
            /> */}
          </div>
        
       
          {candidatSelect.length > 0 && formValues.categ ? (
            <Button
              variant={"primary"}
              className={" " + (load && "disabled")}
            
              onClick={() => EnvoyerCandidat()}
            >
              {"Affecter les candidats"}
            </Button>
          ) : (
            <Button variant={"primary"} disabled>
              {"Affecter les candidats"}
            </Button>
          )}
            
          </Modal.Footer>
        </Modal>
     
        <div className="">
          <div className="row g-0">
         
            <div className="col-12 d-flex flex-column">
            <div className="">
                  <DataTable
                    className="custom-table"
                    title={
                     <div className="d-flex justify-content-between align-items-center">
                       <p className="h3">
                        {
                          "La liste des candidats"
                         }
                      </p>
                         <ListCandidatsPrint titre={"La liste des candidats"} candidats={datafiltrer}/>
                     </div>
                      
                    }
                    columns={columns}
                    data={datafiltrer}
                    selectableRows={true}
                    clearSelectedRows={toggledClearRows}
                    onSelectedRowsChange={(rows) => {
                      setSelectedRows(
                        rows.selectedRows.map((row) => row.id_cand)
                      );
                      setcandidatSelect(rows.selectedRows.map((row) => row));
                    }}
                    progressPending={load}
                    selectedRow={selectedRows}
                    contextActions={
                      <div className="d-flex">
                        <Button
                          className="me-2"
                          variant="secondary"
                          onClick={() => {
                            settoggledClearRows(!toggledClearRows);
                          }}
                        >
                          <FontAwesomeIcon icon={faUndoAlt} className="" />
                        </Button>
                        <button
                          className="btn btn-cyan me-2"
                          onClick={() => {
                            handleValidation();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="pe-2"
                          />{" "}
                          voir la liste des selectionné
                        </button>
                      </div>
                    }
                    progressComponent={
                      <div className="w-100 d-flex justify-content-center py-5">
                        <TailSpin
                          height="80"
                          width="80"
                          color="#22577A"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </div>
                    }
                    contextMessage={{
                      singular: "élement",
                      plural: "élements",
                      message: "sélectionnées",
                    }}
                    pagination
                    paginationPerPage={5}
                    paginationComponentOptions={{
                      rowsPerPageText: "Lignes par page",
                    }}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                  />
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  