export const regions = [
  { value: "Alaotra-Mangoro", label: "Alaotra-Mangoro" },
  { value: "Amoron'i Mania", label: "Amoron'i Mania" },
  { value: "Analamanga", label: "Analamanga" },
  { value: "Analanjirofo", label: "Analanjirofo" },
  { value: "Androy", label: "Androy" },
  { value: "Anosy", label: "Anosy" },
  { value: "Atsinanana", label: "Atsinanana" },
  { value: "Atsimo-Andrefana", label: "Atsimo-Andrefana" },
  { value: "Atsimo-Atsinanana", label: "Atsimo-Atsinanana" },
  { value: "Betsiboka", label: "Betsiboka" },
  { value: "Boeny", label: "Boeny" },
  { value: "Bongolava", label: "Bongolava" },
  { value: "Diana", label: "Diana" },
  { value: "Haute Matsiatra", label: "Haute Matsiatra" },
  { value: "Ihorombe", label: "Ihorombe" },
  { value: "Itasy", label: "Itasy" },
  { value: "Melaky", label: "Melaky" },
  { value: "Menabe", label: "Menabe" },
  { value: "Sava", label: "Sava" },
  { value: "Sofia", label: "Sofia" },
  { value: "Vakinankaratra", label: "Vakinankaratra" },
  { value: "Vatovavy-Fitovinany", label: "Vatovavy-Fitovinany" }
];





