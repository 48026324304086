import {
  faAdd,
  faEdit,
  faHouse,
  faTrash,
  faUndoAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal, FormControl, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { file_url, membre_API, organisme_API } from "../../../service/config";
import InputMask from "react-input-mask";
import isconnected from "../../../service/isconnected";
import ChangerStatusMembre from "./ChangerStatusMembre";
import SelectOne from "../../../outils/Select/selectCustom";
import { regions } from "../../../service/auth/UtilApi";
import { Image } from "primereact/image";

export default function MembreOrganisme() {
  const [recharger, setrecharger] = useState("");
  const columns = [
    // {
    //   name: "ID",
    //   width:'65px',
    //   selector: (row) => row.id_memb,
    //   sortable: true,
    // },
    {
      name: "Nom et Prenom",
      selector: (row) => row.nom_memb + " " + row.prenom_memb,
      sortable: true,
      wrap:true,
    },
    {
      name: "Fonction",
      selector: (row) => row.fonction_memb,
      sortable: true,
    },
   
    // {
    //   name: "Rôle",
    //   selector: (row) => row.role_memb,
    //   sortable: true,
    // },
     {
      name: "Email",
      wrap:true,
      width: "305px",
      selector: (row) => row.email_memb,
      sortable: true,
    },
    {
      name: "Télephone",
      selector: (row) => row.tel_memb,
      sortable: true,
    },
    {
      width: "115px",
      name: "statut",
      selector: (row) => (
        <ChangerStatusMembre row={row} setrecharger={setrecharger} />
      ),
      sortable: true,
    },
    {
      cell: (row) => (
        <div className="d-flex gap-1">
          <button
            className="btn btn-primary"
            onClick={() => {
              // alert(JSON.stringify(row))
              setmode(true);
              setFormValues(row);
              setShow(true);
              setImage({
                photo_memb:`${file_url}/${row.photo_memb}`,
                scan_capp:`${file_url}/${row.scan_capp}`
              });
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [data, setdata] = useState([]);

  const [dataOrg, setdataorg] = useState([]);
  const abortController = new AbortController();
  const [show, setShow] = useState(false);
  const [mode, setmode] = useState(false);
  const [supp, setsupp] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [load, setLoad] = useState(false);
  const [formValues, setFormValues] = useState({
    nom_memb: "",
    prenom_memb: "",
    cin_memb: "",
    photo_memb: "",
    sexe_memb: "",
    naiss_memb: "",
    nationalite_memb: "",
    fonction_memb: "",
    adresse_memb: "",
    ville_memb: "",
    active_memb: "",
    province_memb: "",
    cp_memb: "",
    pays_memb: "",
    tel_memb: "",
    email_memb: "",
    role_memb: "",
    id_org: "",
  });
  const [vide, setvide] = useState({
    nom_memb: "",
    prenom_memb: "",
    cin_memb: "",
    photo_memb: null,
    sexe_memb: "",
    naiss_memb: "",
    nationalite_memb: "",
    fonction_memb: "",
    adresse_memb: "",
    ville_memb: "",
    active_memb: "",
    province_memb: "",
    cp_memb: "",
    pays_memb: "",
    tel_memb: "",
    email_memb: "",
    role_memb: "",
    id_org: "",
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlesup = () => setsupp(!supp);
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
  };

  const [image, setImage] = useState({});

  const onImageChange = (e) => {
    let { name } = e.target;
    console.log(e.target.files[0].size);

    if (e.target.files && e.target.files[0]) {
      setImage({ ...image, [name]: URL.createObjectURL(e.target.files[0]) });
      setFormValues({ ...formValues, [name]: e.target.files[0] });
      setError({ ...error, [name]: null });
    }
  };

  const handleSubmit = () => {
    let io = isconnected.user().id_type;
    formValues.id_org = io;
    if (!formValues.nom_memb) {
      setError({ ...error, nom_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("nom_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.prenom_memb) {
      setError({ ...error, prenom_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("prenom_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cin_memb) {
      setError({ ...error, cin_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("cin_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.sexe_memb) {
      setError({ ...error, sexe_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("sexe_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.naiss_memb) {
      setError({ ...error, naiss_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("naiss_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.nationalite_memb) {
      setError({ ...error, nationalite_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("nationalite_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.email_memb) {
      setError({ ...error, email_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("email_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.ville_memb) {
      setError({ ...error, ville_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("ville_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.province_memb) {
      setError({ ...error, province_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("province_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.adresse_memb) {
      setError({ ...error, adresse_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("adresse_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cp_memb) {
      setError({ ...error, cp_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("cp_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.tel_memb) {
      setError({ ...error, tel_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("tel_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.fonction_memb) {
      setError({ ...error, fonction_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("fonction_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.role_memb) {
      setError({ ...error, role_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("role_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (formValues.photo_memb === null) {
      setError({ ...error, photo_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("photo_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else {
      setLoad(true);
      const apiErrors = {};
      axios
        .post(membre_API, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          setLoad(false);
          setShow(false);
          toast.success("enregistrement reussi !");
          getAllMembre();
          setImage(null);
          setFormValues(vide);
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            apiErrors.nom_memb = msg.nom_memb;
            apiErrors.prenom_memb = msg.prenom_memb;
            apiErrors.cin_memb = msg.cin_memb;
            apiErrors.photo_memb = msg.photo_memb;
            apiErrors.sexe_memb = msg.sexe_memb;
            apiErrors.naiss_memb = msg.naiss_memb;
            apiErrors.nationalite_memb = msg.nationalite_memb;
            apiErrors.fonction_memb = msg.fonction_memb;
            apiErrors.adresse_memb = msg.adresse_memb;
            apiErrors.ville_memb = msg.ville_memb;
            apiErrors.active_memb = msg.active_memb;
            apiErrors.province_memb = msg.province_memb;
            apiErrors.cp_memb = msg.cp_memb;
            apiErrors.pays_memb = msg.pays_memb;
            apiErrors.tel_memb = msg.tel_memb;
            apiErrors.email_memb = msg.email_memb;
            apiErrors.role_memb = msg.role_memb;
            apiErrors.id_org = msg.id_org;
          }
          if (error.response.status == 500) {
            toast.error(error.response.data.message);
            setShow(false);
            setFormValues(vide);
            setImage(null);
          }
          if (error.response.status == 401) {
            toast.info(error.response.data.message);
          }
        });
      setError(apiErrors);
    }
  };
  const handleUpdate = () => {
    formValues.id_org = isconnected.user().id_type;
    if (!formValues.nom_memb) {
      setError({ ...error, nom_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("nom_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.prenom_memb) {
      setError({ ...error, prenom_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("prenom_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cin_memb) {
      setError({ ...error, cin_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("cin_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (formValues.sexe_memb === "") {
      setError({ ...error, sexe_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("sexe_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (formValues.sexe_memb === true) {
      setFormValues({ ...formValues, sexe_memb: "1" });
    } else if (formValues.sexe_memb === false) {
      setFormValues({ ...formValues, sexe_memb: "0" });
    } else if (!formValues.naiss_memb) {
      setError({ ...error, naiss_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("naiss_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.nationalite_memb) {
      setError({ ...error, nationalite_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("nationalite_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.email_memb) {
      setError({ ...error, email_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("email_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.ville_memb) {
      setError({ ...error, ville_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("ville_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.province_memb) {
      setError({ ...error, province_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("province_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.adresse_memb) {
      setError({ ...error, adresse_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("adresse_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.cp_memb) {
      setError({ ...error, cp_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("cp_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.tel_memb) {
      setError({ ...error, tel_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("tel_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.fonction_memb) {
      setError({ ...error, fonction_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("fonction_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (!formValues.role_memb) {
      setError({ ...error, role_memb: "ce champs est réquis !" });
      const input = document.getElementsByName("role_memb")[0];
      const inputTop = input.offsetTop;
      window.scrollTo(0, inputTop);
      setLoad(false);
    } else if (typeof formValues.photo_memb === "string") {
      const { photo_memb, ...formfiltrer } = formValues;
      setFormValues(formfiltrer);
    }else if (typeof formValues.scan_capp === "string") {
      const { scan_capp, ...formfiltrer } = formValues;
      setFormValues(formfiltrer);
    } else {
      setLoad(true);
      const apiErrorss = {};
      axios
        .post(membre_API + `/${formValues.id_memb}?_method=PUT`, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          setLoad(false);
          setShow(false);
          toast.success("Modification reussi !");
          getAllMembre();
          setImage(null);
          setFormValues(vide);
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (error.response.status == 401) {
            toast.info("Vous n'êtes pas autorisée a effectuer cette action !");
          }
          if (msg) {
            apiErrorss.nom_memb = msg.nom_memb;
            apiErrorss.prenom_memb = msg.prenom_memb;
            apiErrorss.cin_memb = msg.cin_memb;
            apiErrorss.photo_memb = msg.photo_memb;
            apiErrorss.sexe_memb = msg.sexe_memb;
            apiErrorss.naiss_memb = msg.naiss_memb;
            apiErrorss.nationalite_memb = msg.nationalite_memb;
            apiErrorss.fonction_memb = msg.fonction_memb;
            apiErrorss.adresse_memb = msg.adresse_memb;
            apiErrorss.ville_memb = msg.ville_memb;
            apiErrorss.active_memb = msg.active_memb;
            apiErrorss.province_memb = msg.province_memb;
            apiErrorss.cp_memb = msg.cp_memb;
            apiErrorss.pays_memb = msg.pays_memb;
            apiErrorss.tel_memb = msg.tel_memb;
            apiErrorss.email_memb = msg.email_memb;
            apiErrorss.role_memb = msg.role_memb;
            apiErrorss.id_org = msg.id_org;
          }
        });
      setError(apiErrorss);
    }
  };

  const getAllMembre = () => {
    setLoad(true);
    axios
      .get(membre_API + "/organisme/" + isconnected.user().id_type, {
        signal: abortController.signal,
      })
      .then((rep) => {
        console.log(rep);
        setdata(rep.data.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllOrganisme = async () => {
    setLoad(true);
    await axios
      .get(organisme_API + "?order=asc&limit=", {
        signal: abortController.signal,
      })
      .then((rep) => {
        console.log(rep);
        setdataorg(rep.data.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Supprimer = () => {
    setsupp(false);

    for (let index = 0; index < selectedRows.length; index++) {
      const id = selectedRows[index];
      axios
        .delete(membre_API + "/" + id)
        .then((rep) => {
          console.log(rep);

          if (index === selectedRows.length - 1) {
            toast.success("element(s) supprimé(s)");
            getAllOrganisme();
            handleClearRows();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 401) {
            toast.info(err.response.data.message);
            handleClearRows();
          }
          toast.error("element(s) pas suppprimé");
        });
    }
  };

  useEffect(() => {
    getAllMembre();
    return () => {
      abortController.abort();
    };
  }, [recharger]);
  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={supp}
        onHide={handlesup}
        backdrop="static"
        keyboard={false}
        fullscreen={false}
        size={"sm"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Supprimer</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlesup}>
            Annuler
          </Button>
          <Button variant="danger" disabled={load} onClick={() => Supprimer()}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="">
          <h2>
        Membres{" "}
          </h2>
        </div>
        <div>
          <Button
            variant="primary"
            onClick={() => {
              setmode(false);
              setFormValues(vide);
              setImage(null);
              handleShow();
            }}
          >
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            Nouveau membre
          </Button>
        </div>
      </div>

      <div className="">
        <DataTable
          className="custom-table"
          title={<p className="fs-4">Liste des membres</p>}
          columns={columns}
          data={data}
          selectableRows
          selectedRow={selectedRows}
          clearSelectedRows={toggledClearRows}
          onSelectedRowsChange={(rows) => {
            setSelectedRows(rows.selectedRows.map((row) => row.id_memb));
          }}
          progressPending={load}
          contextActions={
            <div className="flex">
              <Button
                className="me-2"
                variant="secondary"
                onClick={() => {
                  setToggleClearRows(!toggledClearRows);
                }}
              >
                <FontAwesomeIcon icon={faUndoAlt} className="" />
              </Button>
              <button
                className="btn btn-danger me-2"
                onClick={() => {
                  setsupp(true);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          }
          progressComponent={
            <div className="w-100 d-flex justify-content-center py-5">
              <TailSpin
                height="80"
                width="80"
                color="#22577A"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          }
          contextMessage={{
            singular: "élement",
            plural: "élements",
            message: "sélectionnées",
          }}
          pagination
          paginationPerPage={5}
          paginationComponentOptions={{
            rowsPerPageText: "Lignes par page",
          }}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Formulaire du membre</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-2">
            <div className="col-lg-6 col-12 d-flex align-item-stretch">
              <div className="card w-100">
                <div className="card-body">
                  <div className="card-title">Information personnelles :</div>
                  <div className="">
                    <label className="form-label">Nom</label>
                    <input
                      className={
                        "form-control " + (error.nom_memb && "is-invalid")
                      }
                      name="nom_memb"
                      value={formValues.nom_memb}
                      onChange={handleChange}
                      type="text"
                      placeholder="Saisir nom "
                    />
                    {error.nom_memb ? (
                      <div className="invalid-feedback">{error.nom_memb}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="my-3">
                    <label className="form-label">Prenom</label>
                    <input
                      className={
                        "form-control " + (error.prenom_memb && "is-invalid")
                      }
                      name="prenom_memb"
                      value={formValues.prenom_memb}
                      onChange={handleChange}
                      type="text"
                      placeholder="Saisir prenom"
                    />
                    {error.prenom_memb ? (
                      <div className="invalid-feedback">
                        {error.prenom_memb}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="row g-2">
                    <div className="col-lg-6">
                      <label className="form-label">CIN</label>
                      <input
                        className={
                          "form-control " + (error.cin_memb && "is-invalid")
                        }
                        name="cin_memb"
                        value={formValues.cin_memb}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir CIN"
                      />
                      {error.cin_memb ? (
                        <div className="invalid-feedback">{error.cin_memb}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Sexe</label>
                      <select
                        className={
                          "form-control " + (error.sexe_memb && "is-invalid")
                        }
                        name="sexe_memb"
                        value={
                          formValues.sexe_memb === true
                            ? "1"
                            : formValues.sexe_memb === false
                            ? "0"
                            : formValues.sexe_memb === ""
                            ? formValues.sexe_memb
                            : formValues.sexe_memb
                        }
                        onChange={handleChange}
                      >
                        <option>choisir sexe ...</option>
                        <option value={0}>Femme</option>
                        <option value={1}>Homme</option>
                      </select>
                      {error.sexe_memb ? (
                        <div className="invalid-feedback">
                          {error.sexe_memb}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Date de naissance</label>
                      <input
                        className={
                          "form-control " + (error.naiss_memb && "is-invalid")
                        }
                        name="naiss_memb"
                        value={formValues.naiss_memb}
                        onChange={handleChange}
                        type="date"
                        placeholder="Chosir date"
                      />
                      {error.naiss_memb ? (
                        <div className="invalid-feedback">
                          {error.naiss_memb}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Nationalité</label>
                      <input
                        className={
                          "form-control " +
                          (error.nationalite_memb && "is-invalid")
                        }
                        name="nationalite_memb"
                        value={formValues.nationalite_memb}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir son nationalité"
                      />
                      {error.nationalite_memb ? (
                        <div className="invalid-feedback">
                          {error.nationalite_memb}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="my-3">
                    <label className="form-label">Email</label>
                    <input
                      className={
                        "form-control " + (error.email_memb && "is-invalid")
                      }
                      name="email_memb"
                      value={formValues.email_memb}
                      onChange={handleChange}
                      type="email"
                      placeholder="Saisir email"
                    />
                    {error.email_memb ? (
                      <div className="invalid-feedback">{error.email_memb}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="my-3">
                    <label className="form-label">Telephone (*)</label>
                    <InputMask
                      onKeyUp={(e) => {
                        console.log(e.target.value);
                        if (e.target.value === "") {
                          setError({
                            ...error,
                            tel_memb: ["le champs telephone est requis"],
                          });
                        } else if (e.target.value !== "") {
                          setError({ ...error, tel_memb: [] });
                        }
                      }}
                      onChange={handleChange}
                      mask="+261 39 99 999 99"
                      type="text"
                      className={
                        "form-control " +
                        (error.tel_memb?.length > 0 && " is-invalid")
                      }
                      value={formValues.tel_memb}
                      name="tel_memb"
                      placeholder="Telephone"
                    />

                    {error.tel_memb && (
                      <p className="invalid-feedback p-0 m-1">
                        {error.tel_memb}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 d-flex align-item-stretch">
              <div className="card w-100">
                <div className="card-body">
                  <div className="row g-2">
                    <div className="col-lg-6">
                      <label className="form-label">Ville</label>
                      <input
                        className={
                          "form-control " + (error.ville_memb && "is-invalid")
                        }
                        name="ville_memb"
                        value={formValues.ville_memb}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir ville"
                      />
                      {error.ville_memb ? (
                        <div className="invalid-feedback">
                          {error.ville_memb}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Région</label>
                      <SelectOne
                        options={regions}
                        load={false}
                        choix={formValues.province_memb}
                        placeholder="choix région"
                        setFormValues={setFormValues}
                        formValues={formValues}
                        setError={setError}
                        error={error}
                        name={"province_memb"}
                      />
                    </div>
                    <div className="col-12">
                      <label className="form-label">Pays</label>
                      <input
                        className={
                          "form-control " + (error.pays_memb && "is-invalid")
                        }
                        name="pays_memb"
                        value={formValues.pays_memb}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir adresse du membre"
                      />
                      {error.pays_memb ? (
                        <div className="invalid-feedback">
                          {error.pays_memb}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-12 col-lg-8">
                      <label className="form-label">Adresse</label>
                      <input
                        className={
                          "form-control " + (error.adresse_memb && "is-invalid")
                        }
                        name="adresse_memb"
                        value={formValues.adresse_memb}
                        onChange={handleChange}
                        type="text"
                        placeholder="Saisir adresse du membre"
                      />
                      {error.adresse_memb ? (
                        <div className="invalid-feedback">
                          {error.adresse_memb}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className=" col-12 col-lg-4">
                      <label className="form-label">Code postal</label>
                      <input
                        className={
                          "form-control " + (error.cp_memb && "is-invalid")
                        }
                        name="cp_memb"
                        value={formValues.cp_memb}
                        onChange={handleChange}
                        type="number"
                        placeholder="son code postal"
                      />
                      {error.cp_memb ? (
                        <div className="invalid-feedback">{error.cp_memb}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className="row my-3">
                    <div className="col-lg-6">
                      <label className="form-label">Fonction</label>
                      <select
                        className={
                          "form-control " +
                          (error.fonction_memb && "is-invalid")
                        }
                        name="fonction_memb"
                        value={formValues.fonction_memb}
                        onChange={handleChange}
                      >
                        <option>choisir fonction ...</option>
                        <option value="directeur">directeur</option>
                        <option value="secrétaire">secrétaire</option>
                        <option value="Moniteur">moniteur</option>
                        {/* <option value="Examinateur">Examinateur</option> */}
                        <option value="Autre">Autre</option>
                      </select>
                      {error.fonction_memb ? (
                        <div className="invalid-feedback">
                          {error.fonction_memb}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="form-label">Rôle du membre</label>
                      <select
                        className={
                          "form-control " + (error.role_memb && "is-invalid")
                        }
                        name="role_memb"
                        value={formValues.role_memb}
                        onChange={handleChange}
                      >
                        <option>choisir role ...</option>

                        <option value="Administrateur">Administrateur</option>
                        <option value="Moderateur">Modérateur</option>
                        <option value="Observateur">Observateur</option>

                        {/* <option value="Examinateurs">Examinateurs</option>
                        <option value="Autre">Autre</option> */}
                      </select>
                      {error.role_memb ? (
                        <div className="invalid-feedback">
                          {error.role_memb}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-8">
                      <div className="">
                        <label className="form-label">PHOTO </label>
                        <input
                          className={
                            "form-control " + (error.photo_memb && "is-invalid")
                          }
                          name="photo_memb"
                          id="photo_memb"
                          type="file"
                          onChange={onImageChange}
                          accept="image/*"
                        />
                        {error.photo_memb && (
                          <div className="invalid-feedback">
                            {error.photo_memb}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-4 align-self-end">
                      {image?.photo_memb && (
                        <span
                          className="avatar avatar-md"
                          style={{
                            backgroundImage: "url(" + image?.photo_memb + ")",
                          }}
                        ></span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isconnected.user().type_util === "Auto-écoles" && (
              <div className="col-12 d-flex align-item-stretch">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="card-title">Informations Professionnelle :</div>
                    <div className="row g-2">
                      <div className="col-6">
                        <label className="form-label">
                          N°CAPP (Certificat d’Aptitude Professionnelle et
                          Pédagogique)
                        </label>
                        <input
                          className={
                            "form-control " + (error.num_capp && "is-invalid")
                          }
                          name="num_capp"
                          value={formValues.num_capp}
                          onChange={handleChange}
                          type="text"
                          placeholder="Saisir numéro CAPP"
                        />
                        {error.num_capp ? (
                          <div className="invalid-feedback">
                            {error.num_capp}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-6">
                        <label className="form-label">
                          Date de délivrance du CAPP
                        </label>
                        <input
                          className={
                            "form-control " + (error.date_capp && "is-invalid")
                          }
                          name="date_capp"
                          value={formValues.date_capp}
                          onChange={handleChange}
                          type="date"
                          placeholder="Date de délivrance du CAPP"
                        />
                        {error.date_capp ? (
                          <div className="invalid-feedback">
                            {error.date_capp}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-6">
                            <div className="">
                              <label className="form-label">
                                SCAN du CAPP{" "}
                              </label>
                              <input
                                className={
                                  "form-control " +
                                  (error.scan_capp && "is-invalid")
                                }
                                name="scan_capp"
                                id="scan_capp"
                                type="file"
                                onChange={onImageChange}
                                accept="image/*"
                              />
                              {error.scan_capp && (
                                <div className="invalid-feedback">
                                  {error.scan_capp}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-4 align-self-end">
                      {image?.scan_capp && (
                        <span
                          className="avatar avatar-md"
                          style={{
                            backgroundImage: "url(" + image?.scan_capp + ")",
                          }}
                        ></span>
                      )}
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button
            variant="primary"
            disabled={load}
            onClick={() => (mode ? handleUpdate() : handleSubmit())}
          >
            {mode ? <>Modifier</> : <>Enregistrer</>}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
