import { faPaperPlane, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import avatar from "../../assets/image/avatar.jpg";
import {
  AvisLesson_API,
  AvisModule_API,
  commentlesson_API,
  commentmodule_API,
  LESSON_API,
  MODULE_API,
} from "../../service/config";
import isconnected from "../../service/isconnected";

export default function Comment_Les() {
  moment.locale('fr', {
    months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact : true,
    weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek : 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek : 'dddd [dernier à] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : 'quelques secondes',
        m : 'une minute',
        mm : '%d minutes',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
    },
    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,
    isPM : function (input) {
        return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // Used to determine first week of the year.
    }
});
  // eslint-disable-next-line
  const [rating, setRating] = useState(0);
  let { id } = useParams();
  // eslint-disable-next-line
  const [moyenne, setMoyenne] = useState(0);
  const [module, setmodule] = useState({});
  const [loadcomment, setloadcomment] = useState(true);
  const [info, setInfo] = useState([])
  const [loadcom, setloadcom] = useState(false);
  const [loadmod, setloadmod] = useState(true);
  const [comments, setcommments] = useState([]);
  const [comment, setComment] = useState({
    com_les: "",
    id_les:id
  });
  const vide = useState({
    com_les: "",
    id_les:id
  });
  const [avis, setAvis] = useState({});
  const tooltipArraylist = [
    "très mauvais",
    "mauvais",
    "modérée",
    "très bon",
    "excellent",
  ];
  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    // other logic
  };
  // Optinal callback functions
  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);
  const getLesson = async () => {
    setloadmod(true);
    try {
      axios.get(LESSON_API + "/" + id).then((reponse) => {
        setmodule(reponse.data.lesson);
        setloadmod(false);
      });
    } catch (error) {}
  };

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setComment({ ...comment, [name]: value });
  };

  const addcommentaire = async e => {
    e.preventDefault();
    setloadcom(true);
    console.log("io lesy izy" +id);
    try {
      await axios
        .post(commentlesson_API, {
          com_les: comment.com_les,
          id_les: id
        })
        .then(response => {
          console.log(response);
          toast.success("commentaire publier");
          setComment({ ...comment, com_les: "" });
          setloadcom(false);
          getAllComment()
        })
        .catch(error => {
          console.log(error);
          setloadcom(false);
          toast.warn(error.response.data.message)
        });
    } catch (error) {
      console.log(error);
      setloadcom(false);
    }
  };
  const getAvis = async () => {
    try {
      await axios
        .get(AvisLesson_API + "/moyenne/" + id)
        .then((reponse) => {
          console.log(reponse.data);
          setAvis(reponse.data);
          const io = reponse.data.moyenneAvis;
          setMoyenne(io);
        })
        .catch((err) => {
          console.log(err);
          toast.warn(err.response.data.message);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const initialize = () => {
    console.log(isconnected.user())
  };
  const getAllComment = async () => {
    setloadcomment(true);
    try {
      await axios
        .get(commentlesson_API + "/lessons/" + id)
        .then((response) => {
          console.log(response);
          setcommments(response.data.commentairelessons);
          setloadcomment(false);
          console.log(info)
        })
        .catch((er) => {
          console.log(er);
          setloadcomment(false);
        });
    } catch (error) {
      console.log(error);
      setloadcomment(false);
    }
  };
  useEffect(() => {
    initialize()
    getAllComment();
    getLesson();
    getAvis();
  
  }, []);
  return (
    <div className="container-fluid mx-0 px-0 pt-0">
      <div className="bg-white p-4 mx-0 mb-3 shadow">
        <div className="container">
          {loadmod ? (
            <h4 className="placeholder px-3 py-1">dsfdfdfdfdd</h4>
          ) : (
            <h4>{module.titre_les}</h4>
          )}
          <p style={{ fontSize: "12px" }} className="fw-bold mb-0">
            Moyenne de note de ce module
          </p>
          <Rating
            onClick={handleRating}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
            onPointerMove={onPointerMove}
            showTooltip={true}
            /* tooltipArray={tooltipArraylist}*/
            tooltipDefaultText={Math.round(moyenne * 100) / 100}
            titleSeparator="sur"
            initialValue={moyenne}
            readonly={true}
            /* Available Props */
          />
        </div>
      </div>
      <div className="container">
        <div className="row gap-2 pb-3 pt-2  d-flex justify-content-center">
          {loadcomment ? (
            <div className="col-12 p-5 display-5 text-center">
              Chargement des commentaires
            </div>
          ) : comments.length === 0 ? (
            <div className="col-12 p-5 display-5 text-center">
              Aucun commentaire
            </div>
          ) : (
            comments.map((element, index) => (
              <div key={index} className={"col-lg-11 col-12 ps-3 bg-white py-3 rounded-3 "}>
                <div className={"d-flex"} >
                  <div className="flex-shrink-0">
                    <img
                      src={avatar}
                      className="rounded-circle"
                      alt="Sample avatar"
                      width={45}
                    />
                  </div>
                  <div className="flex-grow-1 ms-3" style={{marginLeft:"45px"}}>
                    <h5>
                     {element.user.nom_util}{" "} {(isconnected.user().email === element.user.email ) && <i className="text-primary pe-2">vous</i>}
                      <small className="text-muted">
                        <i>Posté {moment(element.created_at).fromNow()}</i>
                      </small>
                    </h5>
                    <p>
                     {element.com_les}
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        {id && <div className="row">
       <div className="col-12 bg-white p-lg-5 p-md-3 p-sm-3 p-3 my-2">
              <form className="w-100" onSubmit={addcommentaire}>
                <div className="row gap-3 d-flex align-items-center">
                  <div className="col">
                    <h2 className="mb-3">Commentaire et avis</h2>
                    <div>
                      <textarea
                        className={"form-control "}
                        id="exampleFormControlTextarea1"
                        rows="5"
                        value={comment.com_les}
                        placeholder="Votre commentaire ici..."
                        onChange={handleChange}
                        name="com_les"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row gap-3">
                      <div className="col-12">
                        {isconnected.Authenticated() === false ||
                        comment.com_les.length < 2 ? (
                          <button
                            type="button"
                            className={
                              "text-white btn btn-primary py-2 text-nowrap w-100 disabled"
                            }
                          >
                            Publier{" "}
                            <FontAwesomeIcon
                              icon={faPaperPlane}
                              className="px-2"
                              color="white"
                            />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className={
                              "text-white btn btn-primary py-2 text-nowrap w-100 " +
                              (loadcom && "disabled")
                            }
                          >
                            Publier{" "}
                            <FontAwesomeIcon
                              icon={faPaperPlane}
                              className="px-2"
                              color="white"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
       </div>}
      </div>
    </div>
  );
}
