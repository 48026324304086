import { useState } from "react";
import { Button, Container, Form, ProgressBar } from "react-bootstrap";

export default function Testyyy() {
const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Envoyer les données à votre backend ou effectuer une action appropriée
  };

  const percent = (step / 3) * 100;

  return (
    <Container>
      <ProgressBar now={percent} label={`${percent}%`} />
      <Form onSubmit={handleSubmit}>
        {step === 1 && (
          <>
            <Form.Group controlId="firstName">
              <Form.Label>Prénom</Form.Label>
              <Form.Control
                type="text"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="lastName">
              <Form.Label>Nom de famille</Form.Label>
              <Form.Control
                type="text"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="button" onClick={() => setStep(step + 1)}>
              Étape suivante
            </Button>
          </>
        )}
        {step === 2 && (
          <>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="button" onClick={() => setStep(step - 1)}>
              Étape précédente
            </Button>{' '}
            <Button variant="primary" type="button" onClick={() => setStep(step + 1)}>
              Étape suivante
            </Button>
          </>
        )}
        {step === 3 && (
          <>
            <p>Confirmez vos informations :</p>
            <p>Prénom : {firstName}</p>
            <p>Nom de famille : {lastName}</p>
            <p>Email : {email}</p>
            <Button variant="primary" type="submit">
              Envoyer
            </Button>
          </>
        )}
      </Form>
    </Container>
  );
}
  