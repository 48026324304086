import { faHourglass3, faUserTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import image from '../../../assets/image/imgtest/image'

export default function Pointer() {
    return (
        <>
            <div className="container-fluid py-4">
                <div className='container-lg container-fluid'>
                 
                    <div className='section1 p-3 py-3 mt-3'>
                        <h3>Modules choisis</h3>
                        <div className='d-flex flex-row gap-2 p-2'>
                            <span className='badge bg-primary p-2'>Module 1</span>
                            <span className='badge bg-primary p-2'>Module 2</span>
                            <span className='badge bg-primary p-2'>Module de formation 1ere</span>
                            <span className='badge bg-primary p-2'>Module choisi à s'informer</span>
                        </div>
                    </div>
                    <div className='section1 p-3 py-3 mt-3'>
                        <h3>Leçons parcourus</h3>
                       <div className='p-3'>
                       <div className="list-group list-group-flush list-group-hoverable">
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule</p>
                                        <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                    </div>
                                </div>
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule a 2 roues</p>
                                        <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                    </div>
                                </div>
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule</p>
                                        <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                    </div>
                                </div>
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule a 2 roues</p>
                                        <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                    </div>
                                </div>
                            </div>
                       </div>
                    </div>
                    <div className='section1 p-3 py-3 mt-3'>
                        <h3>Exercices parcourus</h3>
                       <div className='p-3'>
                       <div className="list-group list-group-flush list-group-hoverable">
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule</p>
                                      <div className='w-25 d-flex  flex-row align-items-center justify-content-between'>
                                          <p className='fs-5'>9/10</p>
                                          <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                      </div>
                                    </div>
                                </div>
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule a 2 roues</p>
                                      <div className='w-25 d-flex  flex-row align-items-center justify-content-between'>
                                          <p className='fs-5'>9/10</p>
                                          <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                      </div>
                                    </div>
                                </div>
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule</p>
                                      <div className='w-25 d-flex  flex-row align-items-center justify-content-between'>
                                          <p className='fs-5'>9/10</p>
                                          <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                      </div>
                                    </div>
                                </div>
                                <div className="list-group-item p-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="">Apprendre la base du vehicule a 2 roues</p>
                                      <div className='w-25 d-flex  flex-row align-items-center justify-content-between'>
                                          <p className='fs-5'>9/10</p>
                                          <span className='badge bg-secondary border-secondary'><FontAwesomeIcon className='pe-1' icon={faHourglass3} />05:00</span>
                                      </div>
                                    </div>
                                </div>
                            </div>
                       </div>
                    </div>
                    <div className='section1 p-3 py-3 mt-3'>
                        <h3>Localisation actuelle du candidat</h3>
                       <div className='p-3'>
                        <p>Données sur adresse de localisation du candidat</p>
                      </div>
                    </div>
                </div>
            </div>
        </>
    )
}
