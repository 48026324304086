import Resizer from 'react-image-file-resizer';

export const resizeImage = (file, width, height, format = 'PNG', quality = 100, rotation = 0, outputType = 'blob') => {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      format,
      quality,
      rotation,
      (uri) => {
        resolve(uri);
      },
      outputType
    );
  });
};
