import {
    faAdd,
    faEdit,
    faEye,
    faHouse,
    faTrash,
    faUndoAlt,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import axios from "axios";
  import React, { useState } from "react";
  import { useEffect } from "react";
  import { Button, Modal, FormControl, Form } from "react-bootstrap";
  import DataTable from "react-data-table-component";
  import { TailSpin } from "react-loader-spinner";
  import { toast } from "react-toastify";

  import InputMask from "react-input-mask";
import ChangerStatusMembre from "../ChangerStatusMembre";
import { file_url, membre_API, organisme_API } from "../../../../service/config";
import isconnected from "../../../../service/isconnected";
import SelectOne from "../../../../outils/Select/selectCustom";
import { regions } from "../../../../service/auth/UtilApi";
import { Tag } from "primereact/tag";
 
  
  export default function SelectionExaminateur(props) {
    const [recharger, setrecharger] = useState("");
    const columns = [
      // {
      //   name: "ID",
      //   selector: (row) => row.id_memb,
      //   sortable: true,
      // },
      {
        name: "Nom et Prenom",
        selector: (row) => row.nom_memb + " " + row.prenom_memb,
        sortable: true,
      },
      {
        name: "Fonction",
        selector: (row) => row.fonction_memb,
        sortable: true,
      },
      {
        name: "Rôle",
        selector: (row) => row.role_memb,
        sortable: true,
      },
      {
        name: "Télephone",
        selector: (row) => row.tel_memb,
        sortable: true,
      },
      {
        width: "115px",
        name: "statut",
        selector: (row) => (
          <Tag
          severity={
            row.active_memb === "Activer"
              ? "success"
              : row.active_memb === "Desactiver"
              ? "secondary"
              : "danger"
          }
          value={row.active_memb}
        ></Tag>
        ),
        sortable: true,
      },
      {
        cell: (row) => (
          <div className="d-flex gap-1">
            <button
              className="btn btn-primary"
              onClick={() => {
                // alert(JSON.stringify(row))
                setmode(true);
                setFormValues(row);
                setShow(true);
                setImage(`${file_url}/${row.logo_org}`);
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];
  
    const [data, setdata] = useState([]);
    const [showBig,setShowBig]= useState(false)
    const [dataOrg, setdataorg] = useState([]);
  
    const [show, setShow] = useState(false);
    const [mode, setmode] = useState(false);
    const [supp, setsupp] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);
    const [load, setLoad] = useState(false);
    const [formValues, setFormValues] = useState({
      nom_memb: "",
      prenom_memb: "",
      cin_memb: "",
      photo_memb: "",
      sexe_memb: "",
      naiss_memb: "",
      nationalite_memb: "",
      fonction_memb: "",
      adresse_memb: "",
      ville_memb: "",
      active_memb: "",
      province_memb: "",
      cp_memb: "",
      pays_memb: "",
      tel_memb: "",
      email_memb: "",
      role_memb: "",
      id_org: "",
    });
    const [vide, setvide] = useState({
      nom_memb: "",
      prenom_memb: "",
      cin_memb: "",
      photo_memb: null,
      sexe_memb: "",
      naiss_memb: "",
      nationalite_memb: "",
      fonction_memb: "",
      adresse_memb: "",
      ville_memb: "",
      active_memb: "",
      province_memb: "",
      cp_memb: "",
      pays_memb: "",
      tel_memb: "",
      email_memb: "",
      role_memb: "",
      id_org: "",
    });
    const handleClose = () => setShow(false);
    const handleBigClose = ()=>setShowBig(false)
    const handleShow = () => setShow(true);
    const handlesup = () => setsupp(!supp);
    const handleClearRows = () => {
      setToggleClearRows(!toggledClearRows);
    };
    const [error, setError] = useState([]);
    const handleChange = ({ currentTarget }) => {
      const { name, value } = currentTarget;
      setFormValues({ ...formValues, [name]: value });
      setError({ ...error, [name]: null });
    };
  
    const [image, setImage] = useState(null);
  
    const onImageChange = (e) => {
      console.log(e.target.files[0].size);
  
      if (e.target.files && e.target.files[0]) {
        setImage(URL.createObjectURL(e.target.files[0]));
        setFormValues({ ...formValues, photo_memb: e.target.files[0] });
        setError({ ...error, photo_memb: null });
      }
    };

  
    const getAllMembre = () => {
    
          setdata(props.data);
      
    };

 
    useEffect(() => {
      getAllMembre();
    }, [recharger]);
    return (
      <div className="pt-2">
        <p className="text-primary" onClick={()=>setShowBig(true)}>Choisir</p>
  <Modal 
   show={showBig}
   onHide={handleBigClose}
   backdrop="static"
   keyboard={false}
   size={"xl"}
  >
    <Modal.Body>
    <div className="">
          <DataTable
            className="custom-table"
            title={<p className="fs-4">Liste des membres: examinateurs</p>}
            columns={columns}
            data={props.data}
            selectableRows
            selectableRowsSingle
            selectedRow={[props.selectExam]}
            clearSelectedRows={toggledClearRows}
            onSelectedRowsChange={(rows) => {
              setSelectedRows(rows.selectedRows.map((row) => row.id_memb));
              props.setselectExam(rows.selectedRows[0])
            }}
            progressPending={props.load}
            contextActions={
              <div className="flex">
                <Button
                  className="me-2"
                  variant="secondary"
                  onClick={() => {
                    setToggleClearRows(!toggledClearRows);
                  }}
                >
                  <FontAwesomeIcon icon={faUndoAlt} className="" />
                </Button>
               
              </div>
            }
            progressComponent={
              <div className="w-100 d-flex justify-content-center py-5">
                <TailSpin
                  height="80"
                  width="80"
                  color="#22577A"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            }
            contextMessage={{
              singular: "Examinateur",
              plural: "Examinateur",
              message: "sélectionné",
            }}
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page",
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
          />
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size={"xl"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Information de l'examinateur</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row g-2">
              <div className="col-lg-6 col-12 d-flex align-item-stretch">
                <div className="card w-100">
                  <div className="card-body">
                  <div className="row align-items-center">
                      <div className="col-12">
                       <p>Photo</p>
                        {image && (
                          <span
                            className="avatar avatar-md"
                            style={{ backgroundImage: "url(" + image + ")" }}
                          ></span>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <label className="form-label">Nom</label>
                      <input
                        className={
                          "form-control " + (error.nom_memb && "is-invalid")
                        }
                        name="nom_memb"
                        value={formValues.nom_memb}
                        type="text"
                        placeholder="Saisir nom "
                      />
                      {error.nom_memb ? (
                        <div className="invalid-feedback">{error.nom_memb}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="my-3">
                      <label className="form-label">Prenom</label>
                      <input
                        className={
                          "form-control " + (error.prenom_memb && "is-invalid")
                        }
                        name="prenom_memb"
                        value={formValues.prenom_memb}
                        type="text"
                        placeholder="Saisir prenom"
                      />
                      {error.prenom_memb ? (
                        <div className="invalid-feedback">
                          {error.prenom_memb}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
  
                    <div className="row g-2">
                      <div className="col-lg-6">
                        <label className="form-label">CIN</label>
                        <input
                          className={
                            "form-control " + (error.cin_memb && "is-invalid")
                          }
                          name="cin_memb"
                          value={formValues.cin_memb}
                          type="text"
                          placeholder="Saisir CIN"
                        />
                        {error.cin_memb ? (
                          <div className="invalid-feedback">{error.cin_memb}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">Sexe</label>
                        <select
                          className={
                            "form-control " + (error.sexe_memb && "is-invalid")
                          }
                          name="sexe_memb"
                          value={
                            formValues.sexe_memb === true
                              ? "1"
                              : formValues.sexe_memb === false
                              ? "0"
                              : formValues.sexe_memb === ""
                              ? formValues.sexe_memb
                              : formValues.sexe_memb
                          }
                        >
                          <option>choisir sexe ...</option>
                          <option value={0}>Femme</option>
                          <option value={1}>Homme</option>
                        </select>
                        {error.sexe_memb ? (
                          <div className="invalid-feedback">
                            {error.sexe_memb}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">Date de naissance</label>
                        <input
                          className={
                            "form-control " + (error.naiss_memb && "is-invalid")
                          }
                          name="naiss_memb"
                          value={formValues.naiss_memb}
                          type="date"
                          placeholder="Chosir date"
                        />
                        {error.naiss_memb ? (
                          <div className="invalid-feedback">
                            {error.naiss_memb}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">Nationalité</label>
                        <input
                          className={
                            "form-control " +
                            (error.nationalite_memb && "is-invalid")
                          }
                          name="nationalite_memb"
                          value={formValues.nationalite_memb}
                          type="text"
                          placeholder="Saisir son nationalité"
                        />
                        {error.nationalite_memb ? (
                          <div className="invalid-feedback">
                            {error.nationalite_memb}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 d-flex align-item-stretch">
                <div className="card w-100">
                  <div className="card-body">
                  <div className="my-3">
                      <label className="form-label">Email</label>
                      <input
                        className={
                          "form-control " + (error.email_memb && "is-invalid")
                        }
                        name="email_memb"
                        value={formValues.email_memb}
                        type="email"
                        placeholder="Saisir email"
                      />
                      {error.email_memb ? (
                        <div className="invalid-feedback">{error.email_memb}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="my-3">
                      <label className="form-label">Telephone (*)</label>
                      <InputMask
                        onKeyUp={(e) => {
                          console.log(e.target.value);
                          if (e.target.value === "") {
                            setError({
                              ...error,
                              tel_memb: ["le champs telephone est requis"],
                            });
                          } else if (e.target.value !== "") {
                            setError({ ...error, tel_memb: [] });
                          }
                        }}
                        mask="+261 39 99 999 99"
                        type="text"
                        className={
                          "form-control " +
                          (error.tel_memb?.length > 0 && " is-invalid")
                        }
                        value={formValues.tel_memb}
                        name="tel_memb"
                        placeholder="Telephone"
                      />
  
                      {error.tel_memb && (
                        <p className="invalid-feedback p-0 m-1">
                          {error.tel_memb}
                        </p>
                      )}
                    </div>
                    <div className="row g-2">
                      <div className="col-lg-6">
                        <label className="form-label">Ville</label>
                        <input
                          className={
                            "form-control " + (error.ville_memb && "is-invalid")
                          }
                          name="ville_memb"
                          value={formValues.ville_memb}
                          type="text"
                          placeholder="Saisir ville"
                        />
                        {error.ville_memb ? (
                          <div className="invalid-feedback">
                            {error.ville_memb}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">Région</label>
                        <SelectOne
                          options={regions}
                          load={false}
                          choix={formValues.province_memb}
                          placeholder="choix région"
                        //   setFormValues={setFormValues}
                          formValues={formValues}
                          setError={setError}
                          error={error}
                          name={"province_memb"}
                        />
                      </div>
                      <div className="col-12">
                        <label className="form-label">Pays</label>
                        <input
                          className={
                            "form-control " + (error.pays_memb && "is-invalid")
                          }
                          name="pays_memb"
                          value={formValues.pays_memb}
                          type="text"
                          placeholder="Saisir adresse du membre"
                        />
                        {error.pays_memb ? (
                          <div className="invalid-feedback">
                            {error.pays_memb}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-12 col-lg-8">
                        <label className="form-label">Adresse</label>
                        <input
                          className={
                            "form-control " + (error.adresse_memb && "is-invalid")
                          }
                          name="adresse_memb"
                          value={formValues.adresse_memb}
                          type="text"
                          placeholder="Saisir adresse du membre"
                        />
                        {error.adresse_memb ? (
                          <div className="invalid-feedback">
                            {error.adresse_memb}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className=" col-12 col-lg-4">
                        <label className="form-label">Code postal</label>
                        <input
                          className={
                            "form-control " + (error.cp_memb && "is-invalid")
                          }
                          name="cp_memb"
                          value={formValues.cp_memb}
                          type="number"
                          placeholder="son code postal"
                        />
                        {error.cp_memb ? (
                          <div className="invalid-feedback">{error.cp_memb}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
  
                    <div className="row my-3">
                      <div className="col-lg-6">
                        <label className="form-label">Fonction</label>
                        <select
                          className={
                            "form-control " +
                            (error.fonction_memb && "is-invalid")
                          }
                          name="fonction_memb"
                          value={formValues.fonction_memb}
                        >
                          <option>choisir fonction ...</option>
                          <option value="directeur">directeur</option>
                          <option value="secrétaire">secrétaire</option>
                          <option value="Examinateur">Examinateur</option>
                          <option value="Moniteur">moniteur</option>
                          <option value="Autre">Autre</option>
                        </select>
                        {error.fonction_memb ? (
                          <div className="invalid-feedback">
                            {error.fonction_memb}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">Rôle du membre</label>
                        <select
                          className={
                            "form-control " + (error.role_memb && "is-invalid")
                          }
                          name="role_memb"
                          value={formValues.role_memb}
                        >
                          <option>choisir role ...</option>
                          <option value="administrateur">administrateur</option>
                          <option value="modérateur">modérateur</option>
                          <option value="observateur">observateur</option>
  
                          {/* <option value="Examinateurs">Examinateurs</option>
                          <option value="Autre">Autre</option> */}
                        </select>
                        {error.role_memb ? (
                          <div className="invalid-feedback">
                            {error.role_memb}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
               
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
          
          </Modal.Footer>
        </Modal>
        
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={handleBigClose}>
              Fermer
            </Button>
        </Modal.Footer>
  </Modal>
       
      </div>
    );
  }
  