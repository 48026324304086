import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import './cssSelect.css'
export default function SelectPower({formValues,className,setFormValues,setError,error,name,label,value,options,load,choix,placeholder}) {
  const animatedComponents = makeAnimated();
  const [data,setData] = useState([{label:"",value:""}])
  let text = "";
  const [ito, setIto] = useState([]);
  const change = (choice) => {
   
   
    text = "";
    text = choice.value;
    setFormValues({ ...formValues, [name]: text });
    setIto(choice);
    setError({ ...error, [name]: null });
    
  };

 const fetchData = () => {
    let optionsValue = [];

    if (options) {
        if (options.length > 0 ) {
            for (let i = 0; i < options.length; i++) {
                const element = options[i];
                optionsValue.push({value:element[value],label:element[label]})
            }
            setData(optionsValue)
        }
    }
 }
  useEffect(() => {
    if (choix == null || choix == "") {
      setIto(null);
      fetchData()
    } else {
      let categ = choix;
      let valueCat = [];
      fetchData()
      valueCat.push({ value: choix[value], label: choix[label] });
      setIto(valueCat);
    }
  }, [choix]);

  return (
    <div className="select-power" >
<Select
    closeMenuOnSelect
    components={animatedComponents}
    placeholder={placeholder}
    options={data}
    name={name}
    defaultValue={choix}
    className={"w-100"}
    onChange={(choice) => change(choice)}
    noOptionsMessage={() => "plus d'options"}
    isDisabled={load}
    isLoading={load}
   
  />
    </div>
  );
}
