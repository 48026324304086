import {
  faCheckCircle,
  faEdit,
  faEraser,
  faFilter,
  faFilterCircleXmark,
  faHouse,
  faPrint,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { Calendar } from "primereact/calendar";
import { toast } from "react-toastify";
import Select from "react-select";

import { useSelector } from "react-redux";

import moment from "moment";
import {
  candidat_API,
  examenspratiques_API,
  getCandidatoncim,
  getCandidatoncimall,
  getListExaminateur,
  getcentreByatt,
  listcandexampratique,
  organisme_API,
} from "../../../../service/config";
import { generateRandomString } from "../../../../service/RandomString";
import { regions } from "../../../../service/auth/UtilApi";
import SelectMultiCustomId from "../../../../outils/Select/SelectMultiCustomId";
import SelectionExaminateur from "../Examinateur/SelectionExaminateur";
import SelectFinal from "../../../../outils/Select/SelectFinal";
import SelectFinalOne from "../../../../outils/Select/SelectFinalOne";
import VerifyCandidature from "./VerifyCandidature";
import ControleCandidature from "./ControleCandidature";
import { useLocation } from "react-router-dom";
import { XLSexport } from "../../../../service/Print/XLSexport";

export default function CandidatOnCimPra() {
  const [choix, setchoix] = useState("");
  const { pathname } = useLocation();
  const abortController = new AbortController();
  const [error, setError] = useState({
    categ: "",
  });
  const columns = [
    // {
    //   name: "id",
    //   width: "8%",
    //   selector: (row) => row.id_cand,
    //   sortable: true,
    // },
    {
      name: "Permis",
      width: "170px",
      format: (row) => <div className="py-3">{permis(row.categ_permis)}</div>,
      selector: (row) => row.categ_permis,
      sortable: true,
    },
    {
      name: "numéro permis",
      width: "170px",
      selector: (row) => "non fourni",
      sortable: true,
    },
    {
      name: "Visa CIM",
      width: "170px",
      selector: (row) => row.visa_cim || "non fourni",
      sortable: true,
    },
    {
      name: "Nom et prenom",
      width: "170px",
      selector: (row) => (
        <>
          {row.nom_cand} {row.prenom_cand}
        </>
      ),
      sortable: true,
    },
    {
      name: "Date d'inscription",
      selector: (row) => moment(row.date_insc_cand).locale("fr").format("LL"),
      sortable: true,
      width: "170px",
    },
    {
      name: "Date d'envoi",
      width: "200px",
      cell: (row) => moment(row.date_status_cand).locale("fr").format("LL"),
      selector: (row) => row.date_status_cand,
      sortable: true,
    },
    {
      name: "nombre de session",
      width: "200px",
      selector: (row) => 0,
      sortable: true,
    },
    {
      name: "derogation",
      width: "200px",
      selector: (row) => "Aucun",
      sortable: true,
    },
    {
      name: "Adresse",
      width: "170px",
      selector: (row) => row.adresse_cand,
      sortable: true,
    },
    {
      name: "Ville",
      width: "200px",
      wrap: true,
      selector: (row) => row.ville_cand,
      sortable: true,
    },
    {
      width: "200px",
      name: "Region",
      wrap: true,
      selector: (row) => row.province_cand,
      sortable: true,
    },
    {
      name: "statut",
      minWidth: "280px",
      selector: (row) => (
        <span className="badge bg-cyan p-2">{row.status_examen_cand}</span>
      ),
      sortable: true,
    },
  ];
  const columns_valid = [
    //   {
    //     name: "id",
    //     width: "8%",
    //     selector: (row) => row.id_cand,
    //     sortable: true,
    //   },
    {
      name: "Permis",
      format: (row) => <div className="py-3">{permis(row.categ_permis)}</div>,
      selector: (row) => row.categ_permis,
      sortable: true,
    },
    {
      name: "Nom et prenom",
      width: "170px",
      wrap: true,
      selector: (row) => (
        <>
          {row.nom_cand} {row.prenom_cand}
        </>
      ),
      sortable: true,
    },

    {
      name: "Date d'inscription",
      selector: (row) => moment(row.date_affectation).format("DD-MM-yyyy"),
      sortable: true,
      width: "170px",
    },
    {
      name: "Date d'envoi",
      width: "200px",
      selector: (row) => moment(row.date_status_cand).format("DD-MM-yyyy"),
      sortable: true,
    },
    {
      name: "Adresse",
      width: "170px",
      wrap: true,
      selector: (row) => row.adresse_cand,
      sortable: true,
    },

    {
      name: "Ville",
      width: "170px",
      selector: (row) => row.ville_cand,
      sortable: true,
    },
    {
      name: "Region",
      width: "170px",
      selector: (row) => row.province_cand,
      sortable: true,
    },
    {
      name: "statut",
      width: "180px",
      selector: (row) => (
        <span className="badge bg-cyan p-2">{row.status_examen_cand}</span>
      ),
      sortable: true,
    },
    {
      name: "action",
      cell: (row) => (
        <div className="">
          <button
            className="btn btn-primary"
            onClick={() => {
              let mis = candidatSelect.filter(
                (el) => el.id_cand != row.id_cand
              );
              setcandidatSelect(mis);
            }}
          >
            <FontAwesomeIcon icon={faEraser} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const permis = (perm) => {
    let beforSplt = perm?.split(",");
    beforSplt?.pop();
    if (perm) {
      return (
        <div className="d-flex gap-1 flex-wrap">
          {beforSplt.map((el, i) => (
            <span className="badge bg-cyan">{el}</span>
          ))}
        </div>
      );
    } else {
      return "Aucun permis !";
    }
  };
  const [selectExam, setselectExam] = useState({
    nom_memb: "",
    prenom_memb: "",
  });
  const [eventy, seteventy] = useState("");
  const [data, setdata] = useState([]);
  const [autoecole, setautoecole] = useState([]);
  const [centre, setcentre] = useState([]);
  const [Examinateur, setExaminateur] = useState([]);
  const [loadExamin, setloadexamin] = useState(false);
  const [filtre, setfiltre] = useState({
    categorie: "",
    auto: "",
    dateinsc: "",
  });
  const [load, setLoad] = useState(false);
  const [loadValid, setLoadValid] = useState(false);
  const [candidatSelect, setcandidatSelect] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, settoggledClearRows] = useState(false);
  const categ = useSelector((state) => state.categ);
  console.log(categ);
  const Alltype = useSelector((state) => state.listtypeexam);

  const handleFiltre = (e) => {
    const { name, value } = e.target;
    setfiltre({ ...filtre, [name]: value });
  };


  const getExaminateur = (id) => {
    setloadexamin(true);
    axios
      .get(getListExaminateur + id, {
        signal: abortController.signal,
      })
      .then((rep) => {
        console.log(rep);
        setExaminateur(rep.data.data);
        setloadexamin(false);
      });
  };
  useEffect(() => {
    getExaminateur(filtre?.centre);
  }, [filtre?.centre]);
  const getAutoecole = () => {
    let auto = [];
    try {
      axios
        .get(organisme_API + "?type_org=Auto-écoles", {
          signal: abortController.signal,
        })
        .then((reponse) => {
          for (let index = 0; index < reponse.data.data.length; index++) {
            const element = reponse.data.data[index];
            auto.push({ label: element.nom_org, value: element.id_org });
          }
          setautoecole(auto);
          console.log(reponse.data);
        })
        .catch((err) => {
          toast.error("erreur lors de la récuperation liste auto-école");
        });
    } catch (error) {
      toast.error("erreur interne");
    }
  };
  const getcentre = () => {
    let cent = [];
    try {
      axios
        .get(getcentreByatt + "Examinateurs", {
          signal: abortController.signal,
        })
        .then((reponse) => {
          for (let index = 0; index < reponse.data.data.length; index++) {
            const element = reponse.data.data[index];
            cent.push({ label: element.nom_org, value: element.id_org });
          }
          setcentre(cent);
          console.log(reponse.data);
        })
        .catch((err) => {
          toast.error("erreur lors de la récuperation liste centre");
        });
    } catch (error) {
      toast.error("erreur interne");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setfiltre({ ...filtre, [name]: value });
  };
  const [showvalid, setshowvalid] = useState(false);
  const handleClearRows = () => {
    settoggledClearRows(!toggledClearRows);
  };
  const handleValidation = () => setshowvalid(!showvalid);
  useEffect(() => {
    getAutoecole();
    getcentre();
    return () => {
      abortController.abort();
    };
  }, []);

  const filtredCandidat = async () => {
    setLoad(true);
    console.log(filtre.categorie);
    var dateDebut = null;
    var dateFin = null;
    if (filtre?.date) {
      let datefiltre = filtre?.date;
      dateDebut = datefiltre[0];
      dateFin = datefiltre[1];
    }
    try {
      await axios
        .get(
          pathname == "/cimVerifycandidat"
            ? getCandidatoncim
            : getCandidatoncim,
          {
            params: {
              date_debut: dateDebut
                ? moment(dateDebut).format("YYYY-MM-DD")
                : null,
              date_fin: dateFin ? moment(dateFin).format("YYYY-MM-DD") : null,
              categ_permis: filtre.categorie || null,
              id_org: filtre.auto || null,
              type_examen:filtre.type_examen || null,
              // type_examen: pathname == "/cimVerifycandidat" ? null : "pratique",
              status:
                pathname == "/cimVerifycandidatsvalidated"
                  ? "valides"
                  : pathname == "/cimVerifycandidatsrefused"
                  ? "refuses"
                  : null,
            },
          },
          {
            signal: abortController.signal,
          }
        )
        .then((reponse) => {
          console.log(reponse);
          setdata(reponse.data.data);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setLoad(false);
        });
    } catch (error) {
      setLoad(false);
    }
  };
  const validerCand = () => {
    setLoadValid(true);
    try {
      axios
        .post(examenspratiques_API, {
          id_org: filtre?.centre,
          date_examp: filtre?.date_exam,
          heurs_examp: filtre?.heurs_exam,
          id_typeexam: filtre?.id_categ,
          id_memb: selectExam?.id_memb,
          candidats: candidatSelect,
          // id_categ: filtre.id_categ,
        })
        .then((reponse) => {
          setLoad(false);
          console.log(reponse);
          toast.success("candidats valider !");
          setLoadValid(false);
          handleValidation();
          settoggledClearRows(!toggledClearRows);
          setcandidatSelect([]);
          setfiltre({});
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        })
        .catch((err) => {
          setLoad(false);
          toast.error("un erreur est arrivée");
          handleValidation();
          console.log(err);
          setLoadValid(false);
        });
    } catch (error) {
      setLoadValid(false);
      console.log(error);
      handleValidation();
    }
  };

  useEffect(() => {
    setselectExam({});
  }, [filtre.centre]);

  useEffect(() => {
    filtredCandidat();
  }, [eventy, filtre?.date, pathname]);

  return (
    <div key={pathname} className="container-lg container-fluid py-4">
      <Modal
        show={showvalid}
        onHide={handleValidation}
        backdrop="static"
        keyboard={false}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Validation de liste des candidats selectionnés à l'examen </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="">
              <DataTable
                className="custom-table"
                title={
                  <div className="d-flex justify-content-between">
                    <p className="fs-2">Liste des candidats</p>
                    <div>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() =>
                          XLSexport(data, "Les candidats", "Liste")
                        }
                      >
                        <FontAwesomeIcon icon={faPrint} className="me-2" />
                        Export Excel
                      </Button>
                    </div>
                  </div>
                }
                columns={columns_valid}
                data={candidatSelect.sort(
                  (a, b) =>
                    new Date(b.date_status_cand) - new Date(a.date_status_cand)
                )}
                contextActions={
                  <button
                    className="btn btn-cyan me-2"
                    onClick={() => {
                      // ValiderCandidat();
                    }}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                    Valider
                  </button>
                }
                progressComponent={
                  <div className="w-100 d-flex justify-content-center py-5">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#22577A"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                    <strong></strong>
                  </div>
                }
                contextMessage={{
                  singular: "élement",
                  plural: "élements",
                  message: "sélectionnées",
                }}
                onSelectedRowsChange={(rows) => {
                  setSelectedRows(rows);
                }}
                pagination
                paginationPerPage={5}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page",
                }}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={loadValid}
            onClick={handleValidation}
          >
            Fermer
          </Button>
          <Button
            variant="primary"
            disabled={loadValid}
            onClick={() => validerCand()}
          >
            <>Valider</>
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body pb-4">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Gestion des examens et validation des candidats</h3>
              </div>
              <div className="row align-items-center">
              <div className="col-lg-3 col-md-6 col-12">
                  
                  <div className="my-2">
                    <label className="form-label">Type d'examen</label>
                    <SelectFinalOne
                      options={[{
                        "value":"theorie","label":"theorie"
                      },{
                        "value":"pratique","label":"pratique"
                      }]}
                      load={false}
                      value={"value"}
                      label={"label"}
                      choix={choix}
                      placeholder="choix type d'examen"
                      setFormValues={setfiltre}
                      formValues={filtre}
                      setError={setError}
                      error={error}
                      name={"type_examen"}
                      seteventy={seteventy}
                      
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  
                  <div className="my-2">
                    <label className="form-label">Auto-école</label>
                    <SelectFinalOne
                      options={autoecole}
                      load={false}
                      value={"value"}
                      label={"label"}
                      choix={choix}
                      placeholder="choix auto-école"
                      setFormValues={setfiltre}
                      formValues={filtre}
                      setError={setError}
                      error={error}
                      name={"auto"}
                      seteventy={seteventy}
                      
                    />
                  </div>
                </div>
                {/* <div className="col-lg-3 col-md-6 col-12">
                    <div className="my-2">
                      <label className="form-label">Région</label>
                      <Select
                        options={regions}
                        value={filtre?.region}
                        onChange={(select) => {
                          setfiltre({ ...filtre, region: select });
                          seteventy(generateRandomString(5));
                        }}
                        className="w-100"
                        placeholder="Sélectionnez région..."
                      />
                    </div>
                  </div> */}

                <div className="col-lg-3 col-md-6 col-12">
                  <div className="my-2">
                    <label className="form-label">Catégorie permis</label>
                    <SelectFinal
                      options={categ}
                      load={false}
                      value={"label"}
                      label={"label"}
                      choix={choix}
                      placeholder="choix catégorie"
                      setFormValues={setfiltre}
                      formValues={filtre}
                      setError={setError}
                      error={error}
                      name={"categorie"}
                      seteventy={seteventy}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <label htmlFor="" className="form-label">
                    Date d'envoi :(choisir entre deux dates)
                  </label>
                  <Calendar
                    style={{ height: "35px" }}
                    className="w-100"
                    locale={"fr"}
                    value={filtre?.date}
                    name="date"
                    appendTo={"self"}
                    placeholder="Selectionner les dates"
                    onChange={handleFiltre}
                    selectionMode="range"
                    readOnlyInput
                    showButtonBar
                    showIcon
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-body">
              <div className="">
                <DataTable
                  className="custom-table"
                  title={
                    <div className="d-flex justify-content-between">
                      <p className="fs-2">Liste des candidats sélectionnées</p>
                      <div>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() =>
                            XLSexport(data, "Les candidats", "Liste")
                          }
                        >
                          <FontAwesomeIcon icon={faPrint} className="me-2" />
                          Export Excel
                        </Button>
                      </div>
                    </div>
                  }
                  columns={columns}
                  data={data.sort(
                    (a, b) =>
                      new Date(b.date_status_cand) -
                      new Date(a.date_status_cand)
                  )}
                  selectableRows
                  selectableRowsSingle
                  clearSelectedRows={toggledClearRows}
                  onSelectedRowsChange={(rows) => {
                    setSelectedRows(
                      rows.selectedRows.map((row) => row.id_cand)
                    );
                    setcandidatSelect(rows.selectedRows.map((row) => row));
                  }}
                  progressPending={load}
                  selectedRow={selectedRows}
                  contextActions={
                    <div className="d-flex">
                      <Button
                        className="me-2"
                        variant="light"
                        onClick={() => {
                          settoggledClearRows(!toggledClearRows);
                          setcandidatSelect([]);
                        }}
                      >
                        <FontAwesomeIcon icon={faUndoAlt} className="" />
                      </Button>
                      <VerifyCandidature row={candidatSelect[0]} />
                      <ControleCandidature
                        settoggledClearRows={settoggledClearRows}
                        toggledClearRows={toggledClearRows}
                        candidatSelect={candidatSelect}
                        seteventy={seteventy}
                      />
                      {/* <button
                        className="btn btn-cyan me-2"
                        onClick={() => {
                          handleValidation();
                        }}
                      >
                        <FontAwesomeIcon icon={faCheckCircle} className="pe-2" />{" "}
                        voir la liste des selectionné
                      </button> */}
                    </div>
                  }
                  progressComponent={
                    <div className="w-100 d-flex justify-content-center py-5">
                      <TailSpin
                        height="80"
                        width="80"
                        color="#22577A"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  }
                  contextMessage={{
                    singular: "élement",
                    plural: "élements",
                    message: "sélectionnées",
                  }}
                  pagination
                  paginationPerPage={5}
                  paginationComponentOptions={{
                    rowsPerPageText: "Lignes par page",
                  }}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
