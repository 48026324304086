import {
  faCheck,
  faEdit,
  faUser,
  faUserCircle,
  faUserClock,
  faUserGroup,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Bars } from "react-loader-spinner";
import image from "../../../assets/image/imgtest/image";
import { PROFILE_API, file_url } from "../../../service/config";
import EditAccount from "./EditAccount";
import { getCompte } from "../../../service/getApi/getCompte";
import { useSelector } from "react-redux";
import "./compte.css";
import { Image } from "primereact/image";
import Permis from "./Permis";
import EditProfileAuto from "./EditProfileAuto";
import { TabPanel, TabView } from "primereact/tabview";
import EditProfileInfo from "./EditProfileInfo";

export default function Account() {
  const [user, setUser] = useState({});
  const [load, setLoad] = useState(true);
  const [edit, setedit] = useState(false);
  const [refresh,setrefresh] = useState(null)
  const MonCompte = useSelector((state) => state.compte);
  const profile = async () => {
    setLoad(true);
    try {
      await axios
        .get(PROFILE_API)
        .then((response) => {
          console.log(response.data);
          setUser(response.data);
          setLoad(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const tab1HeaderTemplate = (options) => {
    return (
      <button
        type="button"
        onClick={options.onClick}
        className={options.className}
      >
        <FontAwesomeIcon className="me-2" icon={faUserGroup} />
        {options.titleElement}
      </button>
    );
  };

  const tab2HeaderTemplate = (options) => {
    return (
      <button
        type="button"
        onClick={options.onClick}
        className={options.className}
      >
        <FontAwesomeIcon className="me-2" icon={faUser} />
        {options.titleElement}
      </button>
    );
  };
  useEffect(() => {
    getCompte();
    profile();
  }, [refresh]);

  return edit ? (
    <EditAccount data={user} setedit={setedit} />
  ) : (
    <div className="container-lg container-fluid py-2">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-12">
          <div className="card card-xl">
            <div className="card-header">
              <h1>
                <FontAwesomeIcon
                  icon={faUserCircle}
                  size="xl"
                  className="me-2"
                />
                Information compte
              </h1>
            </div>
            {load ? (
              <div className="col-12 d-flex justify-content-center my-5">
                <Bars
                  height="80"
                  width="80"
                  color="#22577A"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="card-body p-3">
                <TabView>
              <TabPanel
                    header="Organisme"
                    headerTemplate={tab1HeaderTemplate}
                  >
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-12 d-flex justify-content-center">
                        <div className="w-75 rounded-5">
                          <Image
                            src={file_url + MonCompte?.logo_org}
                            alt="avatar"
                            preview
                          />
                          <div className="text-center">
                            {MonCompte?.web_org}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          MonCompte?.type_org == "Auto-écoles"
                            ? "col-lg-5 col-md-5 col-12"
                            : "col-lg-9 col-md-9 col-12"
                        }
                      >
                        <table className="border-none ">
                          <tr>
                            <th className="pb-3">
                              <span className=" text-muted me-2">Status :</span>
                            </th>
                            <th className="pb-3">
                              <span
                                className={
                                  "fw-bold " +
                                  (MonCompte?.active_org === "Activer" &&
                                    "text-success ") +
                                  (load && "placeholder")
                                }
                              >
                                {MonCompte?.active_org}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th className="pb-3">
                              <span className=" text-muted me-2">Nom :</span>
                            </th>
                            <th className="pb-3">
                              <span
                                className={"fw-bold " + (load && "placeholder")}
                              >
                                {MonCompte.nom_org} 
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th className="pb-3">
                              <span className=" text-muted me-2">email :</span>
                            </th>
                            <th className="pb-3">
                              <span
                                className={"fw-bold " + (load && "placeholder")}
                              >
                                {MonCompte.email_org}{" "}
                                {MonCompte.is_email_verified &&
                                  (MonCompte.is_email_verified ? (
                                    <FontAwesomeIcon
                                      color="green"
                                      icon={faCheck}
                                    />
                                  ) : (
                                    <button className="btn btn-danger">
                                      email pas encore verifier, verifier
                                    </button>
                                  ))}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th className="pb-3">
                              <span className=" text-muted me-2">Type :</span>
                            </th>
                            <th className="pb-3">
                              <span
                                className={"fw-bold " + (load && "placeholder")}
                              >
                                {MonCompte?.type_org}
                              </span>
                            </th>
                          </tr>

                          <tr>
                            <th className="pb-3">
                              <span className=" text-muted me-2">
                                Telephone :
                              </span>
                            </th>
                            <th className="pb-3">
                              <span
                                className={"fw-bold " + (load && "placeholder")}
                              >
                                {MonCompte?.tel_org}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th className="pb-3">
                              <span className=" text-muted me-2">
                                Adresse :
                              </span>
                            </th>
                            <th className="pb-3">
                              <span
                                className={"fw-bold " + (load && "placeholder")}
                              >
                                {MonCompte?.adresse_org}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th className="pb-3">
                              <span className=" text-muted me-2">
                                Province :
                              </span>
                            </th>
                            <th className="pb-3">
                              <span
                                className={"fw-bold " + (load && "placeholder")}
                              >
                                {MonCompte?.province_org}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th className="pb-3">
                              <span className=" text-muted me-2">Pays :</span>
                            </th>
                            <th className="pb-3">
                              <span
                                className={"fw-bold " + (load && "placeholder")}
                              >
                                {MonCompte?.pays_org}
                              </span>
                            </th>
                          </tr>
                        </table>
                      </div>
                      {MonCompte?.type_org == "Auto-écoles" && (
                        <div className="col-lg-4 col-md-4 col-12">
                          <table className="border-none">
                            <tr>
                              <th className="pb-3">
                                <span className=" text-muted me-2 text-nowrap">
                                  Catégories Permis :
                                </span>
                              </th>
                              <th className="pb-3">
                                <span
                                  className={
                                    "fw-bold " + (load && "placeholder")
                                  }
                                >
                                  <Permis
                                    permisdata={MonCompte?.categ_permis}
                                  />
                                </span>
                              </th>
                            </tr>
                            <tr>
                              <th className="pb-3">
                                <span className=" text-muted me-2">
                                  Numéro agrément :
                                </span>
                              </th>
                              <th className="pb-3">
                                <span
                                  className={
                                    "fw-bold " + (load && "placeholder")
                                  }
                                >
                                  {MonCompte?.numero_agrement == null
                                    ? "pas encore informé"
                                    : MonCompte?.numero_agrement}
                                </span>
                              </th>
                            </tr>
                            <tr>
                              <th className="pb-3">
                                <span className=" text-muted me-2">
                                  Délai agrément :
                                </span>
                              </th>
                              <th className="pb-3">
                                <span
                                  className={
                                    "fw-bold " + (load && "placeholder")
                                  }
                                >
                                  {MonCompte?.delais_agrement == null
                                    ? "pas encore informé"
                                    : MonCompte?.delais_agrement}
                                </span>
                              </th>
                            </tr>
                            <tr>
                              <th className="pb-3">
                                <span className=" text-muted me-2">
                                  Scan agrément :
                                </span>
                              </th>
                              <th className="pb-3">
                                <Image
                                  src={file_url + MonCompte?.scan_agrement}
                                  height="45"
                                  alt="avatar"
                                  preview
                                />
                              </th>
                            </tr>
                          </table>
                        
                        </div>

                      )}
                      {user.colonne_util=="Admin" && <div className="mt-3 d-flex justify-content-end gap-3">
                       <div className="">
                            <button
                              className="btn btn-secondary p-3"
                              onClick={() => setedit(true)}
                            >
                              <FontAwesomeIcon
                                icon={faUserLock}
                                className="me-2"
                              />
                              Changer mot de passe
                            </button>
                          </div>
                          <EditProfileAuto dataPermis={MonCompte} />
                       </div>}
                    </div>
                  </TabPanel>
                  <TabPanel
                    headerTemplate={tab2HeaderTemplate}
                    header={"utilisateur"}
                    headerClassName="flex align-items-center"
                  >
                    <div className="row g-5">
                      <div className="col-3 d-flex justify-content-center">
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          size="9x"
                          className="text-center"
                        />
                      </div>
                      <div className="col-lg col-12">
                        <table className="border-none">
                          <tr>
                            <th className="pb-3">
                              <span className="fw-light text-muted me-2">
                                Status :
                              </span>
                            </th>
                            <th className="pb-3">
                              <span
                                className={
                                  "fw-bold " +
                                  (user.status_util === "Activer" &&
                                    "text-success ") +
                                  (load && "placeholder")
                                }
                              >
                                {user.status_util}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th className="pb-3">
                              <span className="fw-light text-muted me-2">
                                Nom et prenom :
                              </span>
                            </th>
                            <th className="pb-3">
                              <span
                                className={"fw-bold " + (load && "placeholder")}
                              >
                                {user.nom_util} {user.prenom_util}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th className="pb-3">
                              <span className="fw-light text-muted me-2">
                                CIN :
                              </span>
                            </th>
                            <th className="pb-3">
                              <span
                                className={"fw-bold " + (load && "placeholder")}
                              >
                                {user.cin_util ? user.cin_util : "pas fournis"}{" "}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th className="pb-3">
                              <span className="fw-light text-muted me-2">
                                email :
                              </span>
                            </th>
                            <th className="pb-3">
                              <span
                                className={"fw-bold " + (load && "placeholder")}
                              >
                                {user.email}{" "}
                                {user.is_email_verified &&
                                  (user.is_email_verified ? (
                                    <FontAwesomeIcon
                                      color="green"
                                      icon={faCheck}
                                    />
                                  ) : (
                                    <button className="btn btn-danger">
                                      email pas encore verifier, verifier
                                    </button>
                                  ))}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th className="pb-3">
                              <span className="fw-light text-muted me-2">
                                Type :
                              </span>
                            </th>
                            <th className="pb-3">
                              <span
                                className={"fw-bold " + (load && "placeholder")}
                              >
                                {user.type_util}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <th className="pb-3">
                              <span className="fw-light text-muted me-2">
                                Telephone :
                              </span>
                            </th>
                            <th className="pb-3">
                              <span
                                className={"fw-bold " + (load && "placeholder")}
                              >
                                {user.tel_util}
                              </span>
                            </th>
                          </tr>
                        </table>
                      </div>
                     <div className="d-flex mt-3 justify-content-end gap-3">
                     <div className="">
                            <button
                              className="btn btn-secondary p-3"
                              onClick={() => setedit(true)}
                            >
                              <FontAwesomeIcon
                                icon={faUserLock}
                                className="me-2"
                              />
                              Changer mot de passe
                            </button>
                          </div>
                     {load ? null : <EditProfileInfo user={user} setrefresh={setrefresh}/>}
                     </div>
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            )}
            <div className="card-footer d-flex gap-2 justify-content-end"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
