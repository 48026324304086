import React from 'react'

export default function Inpt({error,formValues,handleChange,name,label,type ,placeholder}) {
  return (
    <>
       <label className="form-label">{label}</label>
              <input
                className={
                  "form-control " + (error[name] && "is-invalid")
                }
                name={name}
                value={formValues[name]}
                onChange={handleChange}
                type={type ? type : "text"}
                placeholder={placeholder}
              />
              {error[name] ? (
                <div className="invalid-feedback">{error[name]}</div>
              ) : (
                <></>
              )}
    </>
  )
}
