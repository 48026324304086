import { faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

export default function ConfirmationAnnulation(props) {
    const [show,setshow] = useState(false)
    const handleShow = ()=> {
        setshow(!show)
    }
  return (
    <div>
         <Button variant="secondary" className="py-2 rounded-2" onClick={()=>handleShow()}>Retour</Button>
          <Modal show={show} onHide={handleShow} animation  >
            <Modal.Header className='h3'>
                <FontAwesomeIcon icon={faWarning} color="red" size="2x" className="me-2"/> Annulation d'inscription 
            </Modal.Header>
            <Modal.Body>
                Vous-êtes sur de vouloir abandonner l'inscription ?
            </Modal.Body>
            <Modal.Footer>
                <div className="ms-auto d-flex gap-2">
                    <Button variant="danger"  onClick={()=>props.handleVide()}>Oui, je confirme</Button>
                    <Button variant="secondary" onClick={()=>handleShow()}>Non</Button>

                </div>
            </Modal.Footer>
        </Modal>
    </div>
  )
}
