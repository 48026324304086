import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./animation.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@tabler/core/dist/css/tabler.min.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import "./non-editable-ck.css";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import { Provider } from "react-redux";
import { persistor, store } from "./service/Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import axios from 'axios'
import Fr from './service/Primelocale.json'
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import ScrollToTopOnMount from "./service/remonter";
        
addLocale('fr', Fr)
locale('fr')
const root = ReactDOM.createRoot(document.getElementById("root"));
axios.defaults.timeout = 60000;
console.log(document.getElementsByClassName('app'))
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
