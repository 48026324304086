import React from "react";
import "@tabler/core";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faBan,
  faBrain,
  faBuilding,
  faChartSimple,
  faCircleNotch,
  faCommentSms,
  faDriversLicense,
  faHome,
  faLinesLeaning,
  faList12,
  faMap,
  faMapLocation,
  faNoteSticky,
  faPerson,
  faPersonCane,
  faPrescription,
  faRoad,
  faSchoolCircleXmark,
  faSms,
  faThList,
  faToolbox,
  faTools,
  faTrophy,
  faUserGraduate,
  faUserGroup,
  faUsersRectangle,
} from "@fortawesome/free-solid-svg-icons";
import isconnected from "../../../service/isconnected";
import { HashLink } from "react-router-hash-link";

export default function HeaderOrganisme() {
  const type = ["SuperAdmin", "MTM"];

  const activeClassName =
    " border-bottom border-2 border-primary rounded-0 w-100 d-flex justify-content-start";
  return (
    <div className={"bg-light m-0 p-0 "} style={{ zIndex: 45 }}>
      <div class="container-lg container-fluid ps-0">
        <div class="row">
          <div class="col-12">
            <nav class="navbar navbar-expand-lg navbar-light bg-light ">
              <div class="container-fluid ">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav d-flex flex-wrap">
                    <li
                      className="nav-item"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 " + activeClassName
                            : " nav-link pb-1"
                        }
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faHome}
                        />
                        <span className="nav-link-title">Accueil</span>
                      </NavLink>
                    </li>

                    {type.includes(isconnected.user().type_util) && (
                      <li
                        className="nav-item"
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                      >
                        <NavLink
                          to="/gerer"
                          className={({ isActive }) =>
                            isActive
                              ? "nav-link pb-1 " + activeClassName
                              : " nav-link pb-1"
                          }
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            color="#8692A5"
                            fontSize={"15px"}
                            icon={faBuilding}
                          />

                          <span className="nav-link-title">Organismes</span>
                        </NavLink>
                      </li>
                    )}
                    {isconnected.user().type_util === "MTM" ||
                    isconnected.user().type_util === "ATT" ? (
                      <li
                        className="nav-item"
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                      >
                        <NavLink
                          to="/centre"
                          className={({ isActive }) =>
                            isActive
                              ? "nav-link pb-1 " + activeClassName
                              : " nav-link pb-1"
                          }
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            color="#8692A5"
                            fontSize={"15px"}
                            icon={faCircleNotch}
                          />
                          <span className="nav-link-title">
                            Centre d'examen
                          </span>
                        </NavLink>
                      </li>
                    ) : null}
                    {isconnected.user().type_util === "MTM" && (
                      <>
                        <li
                          className="nav-item"
                          data-bs-toggle="collapse"
                          data-bs-target=".navbar-collapse.show"
                        >
                          <NavLink
                            to="/examinateur"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faPerson}
                            />
                            <span className="nav-link-title">Examinateurs</span>
                          </NavLink>
                        </li>

                        <li
                          className="nav-item"
                          data-bs-toggle="collapse"
                          data-bs-target=".navbar-collapse.show"
                        >
                          <NavLink
                            to="/auto-école"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faDriversLicense}
                            />
                            <span className="nav-link-title">Auto-écoles</span>
                          </NavLink>
                        </li>
                      </>
                    )}
                    <li
                      className="nav-item"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/membres"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link pb-1 " + activeClassName
                            : " nav-link pb-1"
                        }
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faUserGroup}
                        />
                        <span className="nav-link-title">Membres</span>
                      </NavLink>
                    </li>

                    {isconnected.user().type_util === "Examinateurs" && (
                      <>
                        <li
                          className="nav-item"
                          data-bs-toggle="collapse"
                          data-bs-target=".navbar-collapse.show"
                        >
                          <NavLink
                            to="/examinateur"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faPerson}
                            />
                            <span className="nav-link-title">Examinateurs</span>
                          </NavLink>
                        </li>
                      </>
                    )}

                    {isconnected.user().type_util === "Auto-écoles" && (
                      <>
                        <li
                          className="nav-item"
                          data-bs-toggle="collapse"
                          data-bs-target=".navbar-collapse.show"
                        >
                          <NavLink
                            to="/moniteurs"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faUsersRectangle}
                            />
                            <span className="nav-link-title">Moniteurs</span>
                          </NavLink>
                        </li>
                        <li
                          className="nav-item"
                          data-bs-toggle="collapse"
                          data-bs-target=".navbar-collapse.show"
                        >
                          <NavLink
                            to="/apprenants"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faUserGraduate}
                            />
                            <span className="nav-link-title">Apprenants</span>
                          </NavLink>
                        </li>

                        <li className="nav-item dropdown ">
                          <a
                            className="nav-link dropdown-toggle "
                            href="/#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faAddressCard}
                            />
                            <span className="nav-link-title">Candidats</span>
                          </a>
                          <div className="dropdown-menu  dropdown-menu-end dropdown-menu-arrow bg-light animate slideIn w-100">
                            {isconnected.user().type_util === "CIM" ? null : (
                              <NavLink
                                to="/theories/attente"
                                className={({ isActive }) =>
                                  isActive
                                    ? "  dropdown-item " + "bg-blue-lt"
                                    : "   dropdown-item"
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faBrain}
                                  color="#8692A5"
                                  className="me-2"
                                />
                                Examen théorique
                              </NavLink>
                            )}
                            <NavLink
                              to="/pratiques"
                              className={({ isActive }) =>
                                isActive
                                  ? " dropdown-item " + "bg-blue-lt"
                                  : "  dropdown-item"
                              }
                            >
                              <FontAwesomeIcon
                                icon={faRoad}
                                color="#8692A5"
                                className="me-2"
                              />
                              Examen pratique
                            </NavLink>
                          </div>
                        </li>
                      </>
                    )}

                    {isconnected.user().type_util === "CIM" && (
                      <>
                        <li className="nav-item">
                          <NavLink
                            to="/cimVerifycandidat"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faUserGroup}
                            />
                            <span className="nav-link-title">Candidats</span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/cimVerifycandidatsvalidated"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faUserGraduate}
                            />
                            <span className="nav-link-title">
                              Candidatures validés
                            </span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/cimVerifycandidatsrefused"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faBan}
                            />
                            <span className="nav-link-title">
                              Candidature refusés
                            </span>
                          </NavLink>
                        </li>

                        {/* <li className="nav-item">
                          <NavLink
                            to="/localisation"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faMapLocation}
                            />
                            <span className="nav-link-title">Localisation</span>
                          </NavLink>
                        </li> */}
                      </>
                    )}
                    {isconnected.user().type_util === "ATT" && (
                      <>
                        {/* <li
                          className="nav-item"
                          data-bs-toggle="collapse"
                          data-bs-target=".navbar-collapse.show"
                        >
                          <NavLink
                            to="/moniteurs"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faUsersRectangle}
                            />
                            <span className="nav-link-title">Moniteurs</span>
                          </NavLink>
                        </li> */}
                        <li className="nav-item dropdown ">
                          <a
                            className="nav-link dropdown-toggle "
                            href="/#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faPrescription}
                            />
                            <span className="nav-link-title">Examens</span>
                          </a>

                          <div className="dropdown-menu  dropdown-menu-end dropdown-menu-arrow bg-light animate slideIn w-100">
                            {isconnected.user().type_util === "CIM" ? null : (
                              <NavLink
                                to="/examens_theoriques"
                                className={({ isActive }) =>
                                  isActive
                                    ? "  dropdown-item " + "bg-blue-lt"
                                    : "   dropdown-item"
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faBrain}
                                  color="#8692A5"
                                  className="me-2"
                                />
                                Examen théorique
                              </NavLink>
                            )}
                            <NavLink
                              to="/examens_pratiques"
                              className={({ isActive }) =>
                                isActive
                                  ? " dropdown-item " + "bg-blue-lt"
                                  : "  dropdown-item"
                              }
                            >
                              <FontAwesomeIcon
                                icon={faRoad}
                                color="#8692A5"
                                className="me-2"
                              />
                              Examen pratique
                            </NavLink>
                          </div>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/localisation"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faMapLocation}
                            />
                            <span className="nav-link-title">Localisation</span>
                          </NavLink>
                        </li>
                      </>
                    )}
                    {isconnected.user().type_util !== "CIM" && (
                      <>
                        <li className="nav-item dropdown ">
                          <a
                            className="nav-link dropdown-toggle "
                            href="/#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faTrophy}
                            />
                            <span className="nav-link-title">Les passants</span>
                          </a>

                          <div className="dropdown-menu  dropdown-menu-end dropdown-menu-arrow bg-light animate slideIn w-100">
                            {isconnected.user().type_util === "CIM" ? null : (
                              <NavLink
                                to="/resultats"
                                className={({ isActive }) =>
                                  isActive
                                    ? "  dropdown-item " + "bg-blue-lt"
                                    : "   dropdown-item"
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faBrain}
                                  color="#8692A5"
                                  className="me-2"
                                />
                                Examen théorique
                              </NavLink>
                            )}
                            <NavLink
                              to="/resultats_pratique"
                              className={({ isActive }) =>
                                isActive
                                  ? " dropdown-item " + "bg-blue-lt"
                                  : "  dropdown-item"
                              }
                            >
                              <FontAwesomeIcon
                                icon={faRoad}
                                color="#8692A5"
                                className="me-2"
                              />
                              Examen pratique
                            </NavLink>
                          </div>
                        </li>
                        <li className="nav-item dropdown ">
                          <a
                            className="nav-link dropdown-toggle "
                            href="/#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faThList}
                            />
                            <span className="nav-link-title">
                              Les redoublants
                            </span>
                          </a>

                          <div className="dropdown-menu  dropdown-menu-end dropdown-menu-arrow bg-light animate slideIn w-100">
                            {isconnected.user().type_util === "CIM" ? null : (
                              <NavLink
                                to="/redoublants"
                                className={({ isActive }) =>
                                  isActive
                                    ? "  dropdown-item " + "bg-blue-lt"
                                    : "   dropdown-item"
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faBrain}
                                  color="#8692A5"
                                  className="me-2"
                                />
                                Examen théorique
                              </NavLink>
                            )}
                            <NavLink
                              to="/redoublant_pratique"
                              className={({ isActive }) =>
                                isActive
                                  ? " dropdown-item " + "bg-blue-lt"
                                  : "  dropdown-item"
                              }
                            >
                              <FontAwesomeIcon
                                icon={faRoad}
                                color="#8692A5"
                                className="me-2"
                              />
                              Examen pratique
                            </NavLink>
                          </div>
                        </li>
                       {isconnected.user().type_util === "Auto-écoles" && <li className="nav-item">
                          <NavLink
                            to="/materiel"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faToolbox}
                            />
                            <span className="nav-link-title">
                              Moyens matériels
                            </span>
                          </NavLink>
                        </li>}
                      </>
                    )}

                    {isconnected.user().type_util === "MTM" && (
                      <li className="nav-item">
                        <li className="nav-item dropdown ">
                          <a
                            className="nav-link dropdown-toggle "
                            href="/#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faAddressCard}
                            />
                            <span className="nav-link-title">
                              Gerer Acceuil
                            </span>
                          </a>
                          <div className="dropdown-menu  dropdown-menu-end dropdown-menu-arrow bg-light animate slideIn  w-auto">
                            <NavLink
                              to="/mot_min"
                              className={({ isActive }) =>
                                isActive
                                  ? " dropdown-item " + "bg-blue-lt"
                                  : "  dropdown-item"
                              }
                            >
                              <FontAwesomeIcon
                                className="me-2"
                                color="#8692A5"
                                fontSize={"15px"}
                                icon={faNoteSticky}
                              />
                              <span className="nav-link-title">
                                Mot du ministre
                              </span>
                            </NavLink>
                            <NavLink
                              to="/gerer_message_all"
                              className={({ isActive }) =>
                                isActive
                                  ? " dropdown-item " + "bg-blue-lt"
                                  : "  dropdown-item"
                              }
                            >
                              <FontAwesomeIcon
                                className="me-2"
                                color="#8692A5"
                                fontSize={"15px"}
                                icon={faCommentSms}
                              />
                              <span className="nav-link-title">
                                Message aux organismes
                              </span>
                            </NavLink>
                          </div>
                        </li>
                      </li>
                    )}
                    {isconnected.user().type_util === "Examinateurs" && (
                      <>
                        <li className="nav-item dropdown ">
                          <a
                            className="nav-link dropdown-toggle "
                            href="/#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faPrescription}
                            />
                            <span className="nav-link-title">Examens</span>
                          </a>

                          <div className="dropdown-menu  dropdown-menu-end dropdown-menu-arrow bg-light animate slideIn w-100">
                            <NavLink
                              to="/candidatCentre"
                              className={({ isActive }) =>
                                isActive
                                  ? " dropdown-item " + "bg-blue-lt"
                                  : "  dropdown-item"
                              }
                            >
                              <FontAwesomeIcon
                                className="me-2"
                                color="#8692A5"
                                fontSize={"15px"}
                                icon={faNoteSticky}
                              />
                              <span className="nav-link-title">
                                Les candidats
                              </span>
                            </NavLink>
                            {isconnected.user().type_util === "CIM" ? null : (
                              <NavLink
                                to="/exam_theories"
                                className={({ isActive }) =>
                                  isActive
                                    ? "  dropdown-item " + "bg-blue-lt"
                                    : "   dropdown-item"
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faBrain}
                                  color="#8692A5"
                                  className="me-2"
                                />
                                Les rétardataires
                              </NavLink>
                            )}
                          </div>
                        </li>
                      </>
                    )}

                    <li className="nav-item dropdown justify-self-end ms-3">
                      <button
                        className="btn btn-dark dropdown-toggle  dropdown position-static my-2"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Plus
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {(isconnected.user().colonne_util == "Admin" &&
                          isconnected.user().type_util == "MTM") ||
                        (isconnected.user().colonne_util == "Admin" &&
                          isconnected.user().type_util == "ATT") ? (
                          <li className="dropdown-item">
                            <NavLink
                              to="/GererNote"
                              className={({ isActive }) =>
                                isActive
                                  ? "nav-link pb-1 " + activeClassName
                                  : " nav-link pb-1"
                              }
                            >
                              <FontAwesomeIcon
                                className="me-2"
                                color="#8692A5"
                                fontSize={"15px"}
                                icon={faChartSimple}
                              />
                              <span className="nav-link-title">
                                Paramètres examens et exercices
                              </span>
                            </NavLink>
                          </li>
                        ) : null}
                        <li className="dropdown-item">
                          <NavLink
                            to="/statistique de suivi"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faChartSimple}
                            />
                            <span className="nav-link-title">
                              Statistique de suivi
                            </span>
                          </NavLink>
                        </li>
                        <li className="dropdown-item">
                          <NavLink
                            to="/gestion sms"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faSms}
                            />
                            <span className="nav-link-title">SMS</span>
                          </NavLink>
                        </li>
                        {/* <li className="dropdown-item">
                          <NavLink
                            to="/cursus"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faLinesLeaning}
                            />
                            <span className="nav-link-title">Cursus</span>
                          </NavLink>
                        </li> */}
                        <li className="dropdown-item">
                          <NavLink
                            to="/cartographie"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              color="#8692A5"
                              fontSize={"15px"}
                              icon={faMap}
                            />
                            <span className="nav-link-title">Cartographie</span>
                          </NavLink>
                        </li>

                        {/* <li className="dropdown-item">
                          <NavLink
                            to="/statistique suivi"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link pb-1 " + activeClassName
                                : " nav-link pb-1"
                            }
                          >
                             <FontAwesomeIcon
                          className="me-2"
                          color="#8692A5"
                          fontSize={"15px"}
                          icon={faSchoolCircleXmark}
                        />
                            <span className="nav-link-title">
                              Statistique de suivi
                            </span>
                          </NavLink>
                        </li> */}
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
