import {
  faAdd,
  faEdit,
  faHSquare,
  faTrash,
  faUndoAlt,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Image } from "primereact/image";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";

import { toast } from "react-toastify";
import { paramNotes } from "../../../service/config";
import OnlineSvg from "./Online test-rafiki.svg";
import { BlockUI } from "primereact/blockui";
export default function GererParamNotes() {
  const [error, setError] = useState({});
  const [formValues, setFormValues] = useState({
    total_exercice: 0,
    total_pratique: 0,
    total_theorie: 0,
  });

  const [data, setdata] = useState([]);
  const [load, setload] = useState({
    button: false,
    data: true,
  });
  const abortController = new AbortController();

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;

    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
  };

  const postdata = () => {
    setload({ ...load, data: true });
    axios
      .post(paramNotes, formValues, {
        signal: abortController.signal,
      })
      .then((rep) => {
        console.log(rep);
        getdata();
        toast.success("Parametre mis à jour !");
      })
      .catch((err) => {
        setload({ ...load, data: false });
        console.log(err);
        let er = err.response.data;
        setError(er);
      });
  };

  const getdata = () => {
    setload({ ...load, data: true });
    axios
      .get(paramNotes, {
        signal: abortController.signal,
      })
      .then((rep) => {
        console.log(rep);
        setFormValues(rep.data.data);
        setload({ ...load, data: false });
      })
      .catch((err) => {
        setload({ ...load, data: false });
        console.log(err);
      });
  };

  useEffect(() => {
    getdata();

    return () => {
      abortController.abort();
    };
  }, []);
  return (
    <div>
      <div className="container-lg container-fluid py-4">
        <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
          <div className="">
            <h2>
              <FontAwesomeIcon icon={faHSquare} className="me-1" /> Paramètres examens et exercices
            </h2>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
           
            <div className="row justify-content-center">
              <div className="col-6 d-none d-md-block d-lg-block d-flex justify-content-center align-items-center">
                <img src={OnlineSvg} alt="test online" />
              </div>
              <div className="col-6" style={{minWidth:'320px'}}>
                <BlockUI blocked={load.data}>
                  <Card>
                    <Card.Body>
                    <h3 className="text-center fw-bold">Gérer total questionnaire</h3>
                      <div className="my-3">
                        <label className="form-label">Total d’exercices</label>
                        <input
                          className={
                            "form-control " +
                            (error.total_exercice?.length > 0 && "is-invalid")
                          }
                          name="total_exercice"
                          value={formValues.total_exercice}
                          onChange={handleChange}
                          type="number"
                        />
                        {error.total_exercice?.length > 0 ? (
                          <div className="invalid-feedback">
                            {error.total_exercice[0]}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="my-3">
                        <label className="form-label">
                        Total épreuves pratiques
                        </label>
                        <input
                          className={
                            "form-control " +
                            (error.total_pratique?.length > 0 && "is-invalid")
                          }
                          name="total_pratique"
                          value={formValues.total_pratique}
                          onChange={handleChange}
                          type="number"
                        />
                        {error.total_pratique?.length > 0 ? (
                          <div className="invalid-feedback">
                            {error.total_pratique[0]}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="my-3">
                        <label className="form-label">
                        Total épreuves théoriques
                        </label>
                        <input
                          className={
                            "form-control " +
                            (error.total_theorie?.length > 0 && "is-invalid")
                          }
                          name="total_theorie"
                          value={formValues.total_theorie}
                          onChange={handleChange}
                          type="number"
                        />
                        {error.total_theorie?.length > 0 ? (
                          <div className="invalid-feedback">
                            {error.total_theorie[0]}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <Card.Footer>
                        <div className="d-flex justify-content-end">
                          <Button variant="primary" disabled={load.data} onClick={() => postdata()}>
                            Enregister
                          </Button>
                        </div>
                      </Card.Footer>
                    </Card.Body>
                  </Card>
                </BlockUI>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
