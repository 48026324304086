import {
  faBookOpenReader,
  faBoxArchive,
  faCarSide,
  faChartPie,
  faCheckCircle,
  faFemale,
  faGraduationCap,
  faHouse,
  faListNumeric,
  faMale,
  faRectangleXmark,
  faSquare,
  faSquareCaretUp,
  faSquarePlus,
  faTimesCircle,
  faUserCheck,
  faUserTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import DonutChart from "./DonutChart";
import NavStat from "./NavStat";
import axios from "axios";
import { statgeneral } from "../../../../service/config";
import { regions } from "../../../../service/auth/UtilApi";
import { useSelector } from "react-redux";
import SelectFinalOne from "../../../../outils/Select/SelectFinalOne";
import PrintOrExportPDF from "../../../../service/Print/PrintOrExportPDF";

export default function StatGeneral() {
  const [donnee, setdonnee] = useState({});
  const [formValues,setFormValues] = useState({})
  const [error,setError] = useState({})
  const [eventy,seteventy] = useState({})
  const dataRedux = useSelector(state => state.auto)
 
  const StatCard = ({ value, label, icon, color, classN }) => {
    return (
      <div className={classN ? classN : "col-lg-6 col-md-6 col-12 mb-4"}>
        <div className="card">
          <div className="card-body">
            <p className="text-end fw-bold mb-1">{value}</p>
            <div className="d-flex justify-content-start w-100">
              <FontAwesomeIcon icon={icon} size={"2x"} className={color} />
            </div>
            <div className="text-center fw-bold">{label}</div>
          </div>
        </div>
      </div>
    );
  };

  const getData = () => {
    axios
      .get(statgeneral, {
        params: {
          date: formValues.date || null,
          id_org: formValues.id_org ||null
        }
      })
      .then((reponse) => {
        console.log(reponse);
        setdonnee(reponse.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, [formValues]);

  return (
    <div className="container-lg container-fluid py-4">
      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="d-flex flex-nowrap justify-content-between w-100 align-items-center">
          <div>
            <h2>
              <FontAwesomeIcon icon={faHouse} className="me-1" /> Statistique de
              suivi{" "}
            </h2>
         
          </div>
          <div>
            <NavStat />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
       
          <div className="row">
            <div className="col-lg-2 col-12">
              <div className="my-2">
                <label className="form-label">Années</label>
                <input
                  type={"date"}
                  onChange={(e)=>setFormValues({...formValues, date: e.target.value})}
                  className="form-control w-100"
                  name="annéé"
                />
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="my-2">
                <label className="form-label">Auto école</label>
                <SelectFinalOne
                      options={dataRedux}
                      load={false}
                      value={"value"}
                      label={"label"}
                      choix={""}
                      placeholder="choix auto école"
                      setFormValues={setFormValues}
                      formValues={formValues}
                      setError={setError}
                      error={error}
                      name={"id_org"}
                      seteventy={seteventy}
                      
                    />
              </div>
            </div>
            {/* <div className="col-lg-3 col-12">
              <div className="my-2">
                <label className="form-label">Auto école</label>
                <SelectFinalOne
                      options={dataRedux}
                      load={false}
                      value={"value"}
                      label={"label"}
                      choix={""}
                      placeholder="choix auto-école"
                      setFormValues={setFormValues}
                      formValues={formValues}
                      setError={setError}
                      error={error}
                      name={"auto"}
                      seteventy={seteventy}
                      
                    />
              </div>
            </div> */}
          </div>
         <PrintOrExportPDF>
         <h1 className="text-start">Statistique générale</h1>
         <div className="row my-3 justify-content-center align-items-center">
            <div className="col-6">
              <div
                className="w-100 d-flex justify-content-center"
                style={{ maxHeight: "280px" }}
              >
                <DonutChart
                  donnee1={donnee?.hommes}
                  donnee2={donnee?.femmes}
                  taille={2}
                  label={["Homme", "Femme"]}
                />
              </div>
            </div>
            <div className="col-6 h-100">
              <div className="row">
                <div className="col-lg-4 col-12 p-1">
                  <div className="card">
                    <div className="card-body">
                      <p className="text-end fw-bold mb-1">{donnee?.hommes}</p>
                      <div className="d-flex justify-content-start w-100">
                        <FontAwesomeIcon icon={faMale} size={"2x"} color="blue" />
                      </div>
                      <div className="text-center fw-bold">Hommes</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 p-1">
                  <div className="card">
                    <div className="card-body">
                      <p className="text-end fw-bold mb-1">{donnee?.femmes}</p>
                      <div className="d-flex justify-content-start w-100">
                        <FontAwesomeIcon icon={faFemale} size={"2x"} color="pink" />
                      </div>
                      <div className="text-center fw-bold">Femmes</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 p-1">
                  <div className="card">
                    <div className="card-body">
                      <p className="text-end fw-bold mb-1">{donnee?.total}</p>
                      <div className="d-flex justify-content-start w-100">
                        <FontAwesomeIcon icon={faSquarePlus} color="bg-cyan"  size={"2x"} />
                      </div>
                      <div className="text-center fw-bold">Total</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {/* Section Réussite */}
            <div className="col-6">
              <h3 className="text-success text-center">
                Statistiques de Réussite
              </h3>
              <div className="row">
                <StatCard
                  value={`${donnee.taux_reussite_pratique}%`}
                  label="Taux de Réussite Pratique"
                  icon={faCheckCircle}
                  color="text-success"
                  borderColor="success"
                />
                <StatCard
                  value={`${donnee.taux_reussite_theorie}%`}
                  label="Taux de Réussite Théorie"
                  icon={faCheckCircle}
                  color="text-success"
                  borderColor="success"
                />
                <StatCard
                  value={donnee.reussite_pratique}
                  label="Total Réussite Pratique"
                  icon={faUserCheck}
                  color="text-primary"
                  borderColor="primary"
                />
                <StatCard
                  value={donnee.reussite_theorie}
                  label="Total Réussite Théorie"
                  icon={faUserCheck}
                  color="text-primary"
                  borderColor="primary"
                />
              </div>
            </div>

            {/* Section Échec */}
            <div className="col-6">
              <h3 className="text-danger text-center">Statistiques d'Échec</h3>
              <div className="row">
                <StatCard
                  value={`${donnee.taux_echec_pratique}%`}
                  label="Taux d'Échec Pratique"
                  icon={faTimesCircle}
                  color="text-danger"
                  borderColor="danger"
                />
                <StatCard
                  value={`${donnee.taux_echec_theorie}%`}
                  label="Taux d'Échec Théorie"
                  icon={faTimesCircle}
                  color="text-danger"
                  borderColor="danger"
                />
                <StatCard
                  value={donnee.echec_pratique}
                  label="Total Échec Pratique"
                  icon={faUserTimes}
                  color="text-danger"
                  borderColor="danger"
                />
                <StatCard
                  value={donnee.echec_theorie}
                  label="Total Échec Théorie"
                  icon={faUserTimes}
                  color="text-danger"
                  borderColor="danger"
                />
              </div>
            </div>
          </div>
         </PrintOrExportPDF>
        </div>
      </div>
    </div>
  );
}
