import axios from 'axios';
import { CATEGORIE_API } from '../config';
import {store} from '../Redux/store';


export const GetCategorie = () => {
let getCateg = [];
 axios
  .get(CATEGORIE_API)
  .then((rep) => {
    console.log(rep);
    const data = rep.data.data;

    data.forEach((element) => {
      getCateg.push({ value: element.id_categ, label: element.categ });
    });
  })
  .catch((err) => {
    console.log(err);
  });
store.dispatch({type:'addcateg',payload:getCateg});
    
}

