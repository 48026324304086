import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Tag } from "primereact/tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faHouseLock,
  faUnlink,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import { statusMoniteur, validerExaminateurbyMtm } from "../../../../service/config";


export default function ChangerStatusMoniteur(props) {
  const [statusModif, setStatusModif] = useState(false);
  const [load,setload] = useState(false)
  const changerStatus = async (row, status) => {
 
    try {
        setload(true)
      await axios
        .put(statusMoniteur + row.id_memb +"?_method=PUT", { active_memb: status })
        .then((reponse) => {
          toast.success("Status mis a jour !");
     
          setload(false)

          setStatusModif(!statusModif);
          props.setrecharger(Math.floor(Math.random() * 11))
        })
        .catch((error) => {
          console.log(error);
          props.setrecharger(Math.floor(Math.random() * 11))
          setStatusModif(!statusModif);
          setload(false)
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Tag
        onClick={() => setStatusModif(true)}
        style={{cursor:'pointer'}}
        severity={
          props.row.active_memb === "Activer"
            ? "success"
            : props.row.active_memb === "Desactiver"
            ? "secondary"
            : "danger"
        }
        value={props.row.active_memb == "Activer" ? "Activé" : props.row.active_memb == "Desactiver"  ? "Desactivé" : "Bloqué"}
      ></Tag>
      <Modal
        show={statusModif}
        onHide={() => {setStatusModif(!statusModif);  setload(false)}}
        backdrop="static"
        keyboard={false}
        fullscreen={false}
        centered
        size={"sm"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Status du moniteur</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center w-100 gap-3">
            {props.row.active_memb !== "Activer" && (
              <Button
              className={" "+ (load && 'disabled')}  
              variant="success"
                onClick={() => changerStatus(props.row, "Activer")}
              >
                <FontAwesomeIcon icon={faCheck} className="me-2" />
                Activer
              </Button>
            )}
            {props.row.active_memb !== "Desactiver" && (
              <Button
              className={" "+ (load && 'disabled')}  
              variant="secondary"
                onClick={() => changerStatus(props.row, "Desactiver")}
              >
                <FontAwesomeIcon icon={faUnlink} className="me-2" /> Désactivé
              </Button>
            )}{" "}
            {props.row.active_memb !== "Bloquer" && (
              <Button
              className={" "+ (load && 'disabled')}  
              variant="danger"
                onClick={() => changerStatus(props.row, "Bloquer")}
              >
                <FontAwesomeIcon icon={faHouseLock} className="me-2" /> Bloqué
              </Button>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setStatusModif(!statusModif)}
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
