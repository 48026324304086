import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound() {
    return (
        <div className='d-flex justify-content-center align-items-center h-100' >
            <div className=''>
                <div className=' text-center'>
                <div className='display-3'>Page non trouvé</div>
                    <p className='display-1 my-2'>404</p>
                  
                    <Link to={"/"}>Retour à la page d'accueil</Link>
                </div>
            </div>
        </div>
    )
}
