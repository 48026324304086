import {
  faBookOpenReader,
  faDotCircle,
  faEarth,
  faLanguage,
  faRightToBracket,
  fas,
  faSearch,
  faUserGear,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useMatches,
  useNavigate,
  useParams,
} from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "../header/style.css";
import md from "../../assets/svg/madagascar.svg";
import fr from "../../assets/svg/France.svg";
import avatar from "../../assets/image/avatar.jpg";
import HeaderAdmin from "../Admin/HeaderAdmin";
import isconnected from "../../service/isconnected";
import axios from "axios";
import Authenticated from "../../service/isconnected";
import {
  CATEGORIE_API,
  file_url,
  LOGOUT,
  SearchModuleLesson_API,
  TexteLois_API,
  TypeLois_API,
} from "../../service/config";
import { toast } from "react-toastify";
import { Alert } from "react-bootstrap";
import image from "../../assets/image/imgtest/image";
import { TailSpin } from "react-loader-spinner";
import LessonContext from "../../service/context/LessonContext";
import { useSelector } from "react-redux";

export default function Header(props) {
  const dataRedux = useSelector(state=>state.compte)
  const [infolesson, setinfolesson] = React.useState({
    temps_pour: 0,
    id_les: "",
  });
  let typeOrg = [
    "Auto-écoles",
    "CIM",
    "Examinateurs",
    "ATT",
    "MTM",
    "PTF",
    "Autre",
  ];

  
  const [chiffre, setChiffre] = useState([
    { id: "1" },
    { id: "1" },
    { id: "1" },
    { id: "1" },
  ]);
  let { pathname } = useLocation();
  function truncate(str) {
    return str.length > 100 ? str.substring(0, 150) + "..." : str;
  }
  const [categorie, SetCategorie] = useState([]);
  const [loi, setloi] = useState([]);
  const [load, setLoad] = useState(true);
  const [loadtextloi, setloadtextloi] = useState(true);
  const [Search, setSearch] = useState({
    input: "",
  });
  const [activeSearch, setActiveSearch] = useState(false);
  const [info, setInfo] = useState([]);
  const [langue, setLangue] = useState(null);
  const [content, setcontent] = useState({});
  const navigate = useNavigate();

  const handleSearch = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setSearch({ ...Search, [name]: value });
  };

  const PlatLangue = (lg) => {
    localStorage.setItem("langue", lg);
    setLangue(lg);
  };

  const getLG = () => {
    const lg = localStorage.getItem("langue");
    if (lg === "Français" || lg === "Malagasy") {
      setLangue(lg);
    } else {
      setLangue("Français");
      localStorage.setItem("langue", "Français");
    }
  };
  const getCategorie = async () => {
    setLoad(true);
    try {
      await axios
        .get(CATEGORIE_API)
        .then((reponse) => {
          SetCategorie(reponse.data.data);
          console.log(reponse.data);
          setLoad(false);
        })
        .catch((error) => {
          console.log(error);
          toast.error("verifier votre connexion");
        });
    } catch (error) {
      console.log(error);
      toast.error("erreur interne!");
    }
  };
  const getTextloi = async () => {
    setloadtextloi(true);
    try {
      await axios
        .get(TypeLois_API)
        .then((reponse) => {
          console.log(reponse);
          setloadtextloi(false);
          setloi(reponse.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    console.log(content);
  }, [content]);

  useEffect(() => {
    getCategorie();
    getTextloi();
  }, []);
  useEffect(() => {
    getLG();
  }, [langue]);
  var io = document.getElementById("cacher");
  const logout = async () => {
    const dec = toast.loading("Déconnexion !");
    try {
      await axios
        .post(LOGOUT)
        .then((response) => {
          delete axios.defaults.headers["Authorization"];
          navigate("/login");
          window.localStorage.removeItem("authToken");
          toast.update(dec, {
            render: "Session déconnecter!",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });
          props.setactif(false);
        })
        .catch((error) => {
          console.log(error);
          toast.update(dec, {
            render: "Echec de déconnexion !",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
        });
    } catch (error) {
      toast.update(dec, {
        render: "Echec de déconnexion !",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
  const initialize = () => {
    setInfo(isconnected.user());
  };

  const chercher = async (e) => {
    e.preventDefault();
    navigate("rechercher/" + Search.input);
    setTimeout(() => {
      setSearch({ input: "" });
    }, 200);
    props.setrech(Search.input);
  };
  useEffect(() => {
    initialize();

    isconnected.Authenticated();
  }, []);
  useEffect(() => {
    console.log(infolesson);
  }, [infolesson]);

  return (
    <>
      <div className="m-0 p-0 fixed-top " id="navbar">
        <nav
          id="nav-home"
          className="navbar navbar-expand-lg   border border-bottom  "
          style={{ backgroundColor: "#22577A" }}
        >
          <div className="container-lg container-fluid">
            <HashLink to="/" className="navbar-brand me-3" href="/#">
              <img
                src={image.mtm}
                alt=""
                width="35"
                height="auto"
                className="d-inline-block align-text-top"
              />{" "}
            </HashLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              id="cacher"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={"collapse navbar-collapse clearfix"}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item active ">
                  <HashLink to="/#home" className="nav-link text-light">
                    <span
                      className="w-100 h-100"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      Accueil
                    </span>
                  </HashLink>
                </li>
                <li className="nav-item active ">
                  <HashLink to="/#actu" className="nav-link text-light">
                    <span
                      className="w-100 h-100"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      {" "}
                      Actualités
                    </span>
                  </HashLink>
                </li>
                <li className="nav-item active ">
                  <HashLink to="/#sens" className="nav-link text-light">
                    <span
                      className="w-100 h-100"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      {" "}
                      Sensibilisations
                    </span>
                  </HashLink>
                </li>
              </ul>
              <div className="navbar-nav ">
                {/* <div className="nav-item ">
                <div className="nav-link d-flex flex-row justify-content-between text-primary"><p className="">Rechercher</p>  <FontAwesomeIcon icon={faSearch} onClick={() => setActiveSearch(!activeSearch)} color="blue" className="" /></div>




              </div> */}

                <>
                  {/* <div className="nav-item">
                    <a
                      className="nav-link"
                      href="/#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FontAwesomeIcon
                        size="lg"
                        className="d-lg-block d-none text-light"
                        icon={faSearch}
                      />
                      <span className="text-light d-lg-none d-block">
                        Recherche
                      </span>
                    </a>
                    <div className="dropdown-menu bg-light p-3 animate slideIn w-100">
                      <div className="container p-0">
                        <form onSubmit={chercher} className="d-inline">
                          <div className="d-flex gap-2 justify-content-center align-items-center">
                            <div className="input-icon w-75">
                              <input
                                type="text"
                                value={Search.input}
                                name="input"
                                onChange={handleSearch}
                                className="form-control rounded-0"
                                placeholder="Rechercher..."
                                aria-label="Search in website"
                              />
                            </div>

                            {Search.input === "" ? (
                              <button className="btn btn-info disabled rounded-0 ">
                                Aller
                              </button>
                            ) : (
                              <button className="btn btn-info rounded-0 ">
                                {" "}
                                Aller
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div> */}
                 {typeOrg.includes(isconnected.user().type_util) ? <div className="dropdown p-2">
                    <a
                      href="/#"
                      className="nav-link d-flex lh-1 text-reset p-0"
                      data-bs-toggle="dropdown"
                      aria-label="Open user menu"
                      aria-expanded="false"
                    >
                      <span
                        className="avatar avatar-sm"
                        style={{ backgroundImage: "url(" + avatar + ")" }}
                      ></span>
                      <div className="ps-2">
                        <div className="text-light fs-5"> {info.nom_util}</div>
                        <div className="mt-1 small text-light fs-6 fw-bold">
                          {info.type_util === "Grand public" ? (
                            <>Apprenant</>
                          ) : (
                            info.type_util == 'Examinateurs' && info.colonne_util == 'Admin' ? "Centre d'examen" : info.type_util
                            
                          )}
                        </div>
                      </div>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow animate bg-light slideIn">
                      <Link className="dropdown-item mt-2" to="/profile">
                        <FontAwesomeIcon
                          icon={faUserGear}
                          color="#9DA9B9"
                          className="pe-2"
                        />
                        <span
                          className="w-100 h-100"
                          data-bs-toggle="collapse"
                          data-bs-target=".navbar-collapse.show"
                        >
                          Mon compte
                        </span>
                      </Link>
                      {info.colonne_util && info.status_util == "Activer"  ? (
                        <Link className="dropdown-item " to="/">
                          <FontAwesomeIcon
                            icon={faUserShield}
                            color="#9DA9B9"
                            className="pe-2"
                          />
                          <span
                            className="w-100 h-100"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            Mode {info.type_util == "Examinateurs" ? "Centre d'examen" :info.type_util }
                          </span>
                        </Link>
                      ):null}
                      <div
                        to="/login"
                        onClick={() => logout()}
                        className="dropdown-item"
                        type="submit"
                      >
                        <FontAwesomeIcon
                          icon={faRightToBracket}
                          color="red"
                          className="pe-2"
                        />
                        <span
                          className="w-100 h-100"
                          data-bs-toggle="collapse"
                          data-bs-target=".navbar-collapse.show"
                        >
                          Deconnecter
                        </span>
                      </div>
                    </div>
                  </div> : <div className="flex my-auto"><button className="btn btn-cyan me-2" type="button" onClick={()=>navigate('/login')}>Connexion</button><button className="btn btn-primary" type="button" onClick={()=>navigate('/inscription')}>Inscription</button></div>}
                </>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div
        className="m-0 cacher"
        data-bs-toggle="collapse"
        data-bs-target=".collapse.show"
        style={{ paddingTop: "3.75rem" }}
      >
        <LessonContext.Provider value={[infolesson, setinfolesson]}>
          <Outlet />
        </LessonContext.Provider>
      </div>
    </>
  );
}
