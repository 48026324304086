import {
  faAdd,
  faCheckCircle,
  faCheckSquare,
  faClock,
  faEdit,
  faEraser,
  faFilter,
  faHouse,
  faPhone,
  faRotate,
  faTrash,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Modal,
  FormControl,
  Form,
  Overlay,
  OverlayTrigger,
  Tooltip,
  Popover,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

import { useSelector } from "react-redux";
import {
  affectpratique_API,
  candidat_API,
  file_url,
  getValidCandidatPratique,
  organisme_API,
  validationpratiqueHateaos,
} from "../../../../../service/config";
import ListeCandidatPratique from "../../../../../service/Print/ListeCandidatPratique";
import CardHover from "../../../../../outils/HoverCard/CardHover";
import { columnExamen } from "../ServiceExam";
import moment from "moment";
import "moment/locale/fr"
import { Image } from "primereact/image";

export default function CandValid() {
  moment.locale('fr')
  const abortController = new AbortController();

  const confCand = [
    {
      name: "Photo",
      width:'80px',
      selector: (row) => (
        <Image src={file_url + row.photo_cand} alt="Image" width="50" preview />
      ),
      sortable: true,
    },
    {
      name: "Nom et Prénom",
      wrap: true,
      selector: (row) => row.nom_cand + " " + row.prenom_cand,
      sortable: true,
    },
    {
      name: "Permis",
      wrap:true,
      selector: (row) => {
        let cat = row.categ_permis?.split(",");
        return (
          <div className="d-flex flex-wrap gap-1 p-1">
            {cat?.map(
              (i) => i.length > 0 && <span className="badge bg-cyan">{i}</span>
            )}
          </div>
        );
      },
      sortable: true,
    },
      {
        name: "Email",
        selector: (row) => row.email_cand,
        sortable: true,
  width:'22%',
        wrap:true
      },
    {
      name: "téléphone",
      width: "15%",
      selector: (row) => (
        <>
          {" "}
          <FontAwesomeIcon icon={faPhone} className="pe-2" />
          {row.tel_cand}
        </>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Date d'inscription",
      wrap:true,
      selector: (row) => moment(row.date_insc_cand).format('LL'),
      sortable: true,
    }
  ]

  const [data, setdata] = useState([]);
  const [show, setShow] = useState(false);
  const [textfiltre, settextfiltre] = useState("");
  const [toggledClearRows, settoggledClearRows] = useState(false);
  const [showvalid, setshowvalid] = useState(false);
  const [candidatSelect, setcandidatSelect] = useState([]);
  const [load, setLoad] = useState(false);
  const handleClose = () => setShow(!show);
  const handleValidation = () => setshowvalid(!showvalid);

  const getCandidatsValider = async () => {
    setLoad(true);
    await axios
      .get(validationpratiqueHateaos, {
        signal: abortController.signal,
      })
      .then((rep) => {
        console.log("first");
        console.log(rep.data);
        setdata(rep.data.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const datafiltrer = candidatSelect?.listecandidatvalider?.filter((item) => {
    return (
      (item.id_cand && item.id_cand === textfiltre) ||
      (item.nom_cand &&
        item.nom_cand.toLowerCase().includes(textfiltre.toLowerCase()))
    );
  });

  useEffect(() => {
    getCandidatsValider();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="xl"
        scrollable
        animation
      >
        <Modal.Header className="row">
          <div className="col-12 d-flex justify-content-between">
            <b>
              Examen du :{" "}
              {moment(candidatSelect?.date_examp).format("LL")}
            </b>

            <p>
              Heure :
              <FontAwesomeIcon icon={faClock} className="ms-2 me-1" />
              {candidatSelect.heurs_examp}
            </p>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="">
            <DataTable
              className="custom-table"
              title={
                <div className="d-flex gap-3 justify-content-between align-items-center">
                  <p className="h3">Les candidats</p>
                </div>
              }
              columns={confCand}
              data={datafiltrer}
              selectableRows={false}
              clearSelectedRows={toggledClearRows}
              progressPending={load}
              progressComponent={
                <div className="w-100 d-flex justify-content-center py-5">
                  <TailSpin
                    height="80"
                    width="80"
                    color="#22577A"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              }
              contextMessage={{
                singular: "élement",
                plural: "élements",
                message: "sélectionnées",
              }}
              pagination
              paginationPerPage={5}
              paginationComponentOptions={{
                rowsPerPageText: "Lignes par page",
              }}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {" "}
          <ListeCandidatPratique candidats={datafiltrer} candidatSelect={candidatSelect} />
          <Button variant="secondary" onClick={() => handleClose()}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="row g-0">
        <div className="col-12 d-flex flex-column">
          <div className="card-body">
            <div className="">
              <DataTable
                className="custom-table"
                title={
                  <div className="d-flex gap-3 justify-content-between align-items-center">
                    <p className="h3">Les examens validée par l’ATT</p>
                  </div>
                }
                columns={columnExamen(
                  "date_examp",
                  "heurs_examp",
                  "nom_org",
                  "adresse_org",
                  setcandidatSelect,
                  handleClose
                )}
                data={data}
                selectableRows={false}
                clearSelectedRows={toggledClearRows}
                progressPending={load}
             
                progressComponent={
                  <div className="w-100 d-flex justify-content-center py-5">
                    <TailSpin
                      height="80"
                      width="80"
                      color="#22577A"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                }
                contextMessage={{
                  singular: "élement",
                  plural: "élements",
                  message: "sélectionnées",
                }}
                pagination
                paginationPerPage={5}
                paginationComponentOptions={{
                  rowsPerPageText: "Lignes par page",
                }}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
