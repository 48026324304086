import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { accepterPratique, AjoutVisacim, candidat_API, refuserPratique } from "../../../../service/config";
import { toast } from "react-toastify";
import { generateRandomString } from "../../../../service/RandomString";
import { useLocation } from "react-router-dom";

export default function ControleCandidature({ candidatSelect, seteventy,settoggledClearRows,toggledClearRows}) {
  const [show, setshow] = useState(false);
  const [showw, setshoww] = useState(false);
  const { pathname } = useLocation();
  const [cand, setcand] = useState([]);
  const [load, setload] = useState(false);
  const [formValues, setFormValues] = useState({
    motif: "",
  });
  const handleShow = () => setshow(!show);
  const handleShoww = () => setshoww(!showw);
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const RefuserP = async () => {
    setload(true);
    if (cand.length > 0) {
      if (formValues.motif) {
        for (let i = 0; i < cand.length; i++) {
          const element = cand[i];
          try {
            await axios
              .post(refuserPratique, {
                id_cand: element.id_cand,
                motif: formValues.motif,
              })
              .then((response) => {
                console.log(response);
                setload(false);
                toast.success("Candidature refusée !");
                settoggledClearRows(!toggledClearRows);
                seteventy(generateRandomString(5));
                setshow(!show);
              });
          } catch (error) {
            setload(false);
            break;
          }
        }
        setFormValues({ ...formValues, motif: "" });
      } else {
        toast.info("Veuillez fournir un motif de refus !");
      }
    }
  };

  const AjoutVisa =async  () => {
   await axios
      .post(AjoutVisacim + `${cand[0].id_cand}?_method=PUT`, {visa_cim:formValues.visa_cim}, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response)=> {
        console.log(response)
        setshoww(!showw);
        toast.success("Visa a jour !");
        settoggledClearRows(!toggledClearRows);
        seteventy(generateRandomString(5));
      }).catch((error)=>{
        console.log(error)
        toast.error("Un erreur est survenu!");
        setshoww(!showw);
      })
  }
  const ValiderP = async () => {
    setload(true);
    if (cand.length > 0) {
      for (let i = 0; i < cand.length; i++) {
        const element = cand[i];
        try {
          await axios
            .post(accepterPratique, {
              id_cand: element.id_cand,
            })
            .then((response) => {
              console.log(response);
              setload(false);
              toast.success("Candidature validé !");
              settoggledClearRows(!toggledClearRows);
              seteventy(generateRandomString(5));
              setshow(false);
            });
        } catch (error) {
          setload(false);
          console.log(error)
          break;
        }
      }
    }
  };
  useEffect(() => {
    console.log(candidatSelect);
    setcand(candidatSelect);
  }, [candidatSelect]);

  useEffect(() => {
    console.log(pathname);
  }, [pathname]);

  return (
    <div>
      <div className="d-flex align-items-center gap-3">
     {cand.length < 2 && <Button variant="primary" onClick={() => handleShoww()}>
         Ajout Visa CIM
        </Button>}
       {pathname == "/cimVerifycandidatsvalidated" ? null :  <Button variant="success" disabled={load} onClick={()=>ValiderP()}>Valider candidature</Button>}
      {pathname == "/cimVerifycandidatsrefused" ? null :  <Button variant="danger" onClick={() => handleShow()}>
          Rejeter candidature
        </Button>}
        

      </div>
      <Modal show={show} onHide={handleShow}>
        <Modal.Header>Raison du refus</Modal.Header>
        <Modal.Body>
          <textarea
            rows={3}
            onChange={handleChange}
            name="motif"
            className="form-control"
            placeholder="decrire ici la raison du refus..."
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setshow(false)} variant="secondary">
            Fermer
          </Button>
          <Button disabled={load} variant="danger" onClick={() => RefuserP()}>
            Rejeter candidature
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showw} onHide={handleShoww}>
        <Modal.Header>Visa CIM</Modal.Header>
        <Modal.Body>
          <input
            type="text"
            onChange={handleChange}
            name="visa_cim"
            className="form-control"
            placeholder="Ajout ici la Visa CIM..."
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setshoww(false)} variant="secondary">
            Fermer
          </Button>
          <Button disabled={load} variant="success" onClick={() => AjoutVisa()}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
