import {
    faAdd,
    faCancel,
    faEdit,
    faEye,
    faFile,
    faSchool,
    faTrash
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React, { useEffect, useRef, useState } from "react";
  import { Link, useParams } from "react-router-dom";
  import Pagination from "../../../outils/Pagination";
  import LoadingBar from "react-top-loading-bar";
  import Select from "react-select";
  import makeAnimated from "react-select/animated";
  import {
    FichierLesson_API,
    file_url,
    LESSON_API,
    moduleByLesson,
    MODULE_API,
    Questionnaire_API,
    Reponse_API,
    Fichierq_API,
    ListFichierq_API
  } from "../../../service/config";
  import axios from "axios";
  import { toast } from "react-toastify";
  
  const animatedComponents = makeAnimated();
  
  export default function ListeReponseFich() {
    const ref = useRef(null);
    const {idques} = useParams()
    const [ID, setID] = useState(null);
    const [loadModule, setLoadModule] = useState(false);
    let dataLes = [];
    const [idLF, setidLF] = useState(null);
    const [load, setLoad] = useState(false);
    const [barcolor, setBarcolor] = useState("blue");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const [error, setError] = useState([]);
    const [options, setOptions] = useState([]);
    const [questionnaire, setquestionnaire] = useState([]);
    const [affichageRep, setaffichageRep] = useState(null);
    const [lesson, setLesson] = useState({
      qcm_ques: "",
      dure_ques: null
    });
    const vide = {
      qcm_ques: "",
      dure_ques: null,
      id_mod: ""
    };
    const [formValues, setFormValues] = useState([]);
    const handleChange = ({ currentTarget }) => {
      const { name, value } = currentTarget;
      setLesson({ ...lesson, [name]: value });
    };
  
    const [image, setImage] = useState(null);
    const onImageChange = e => {
      if (e.target.files && e.target.files[0]) {
        setImage(URL.createObjectURL(e.target.files[0]));
        setLesson({ ...lesson, img_les: e.target.files[0] });
      }
    };
    const onChangefichier = (i, e) => {
      let formfichier = [...formValues];
    
        if (e.target.files && e.target.files[0]) {
          formfichier[i]["fichierq"] = e.target.files[0];
        }
        setFormValues(formfichier);
      
  
      if (e.target.name === "fichierq") {
        for (let index = 0; index < formValues.length; index++) {
          const element = formValues[index];
          if (index === i) {
            document.getElementById(`fichierq${index}`).innerHTML = "";
            if (
              element.fichierq === "" ||
              element.fichierq === null ||
              element.fichierq === {}
            ) {
              document.getElementById(`fichierq${index}`).innerHTML =
                "<p className='text-danger fs-5'>fichier reponse requis</p>";
            } else {
              document.getElementById(`fichierq${index}`).innerHTML = "";
            }
          }
        }
      }
    };
    let handleChangeFichier = (i, e) => {
      let formfichier = [...formValues];
  
      formfichier[i][e.target.name] = e.target.value;
      formfichier[i]["index"] = i;
  
      setFormValues(formfichier);
      
        if (e.target.name === "type_repq") {
          for (let index = 0; index < formValues.length; index++) {
            const element = formValues[index];
            if (index === i) {
              document.getElementById(`type_repq${index}`).innerHTML = "";
              if (
                element.type_repq === "" ||
                element.type_repq === "choisir ici..."
              ) {
                document.getElementById(`type_repq${index}`).innerHTML =
                  "<p className='text-danger fs-5'>type reponse requis</p>";
              } else {
                document.getElementById(`type_repq${index}`).innerHTML = "";
              }
            }
          }
        }
  
        if (e.target.name === "desc_repq") {
          for (let index = 0; index < formValues.length; index++) {
            const element = formValues[index];
            if (index === i) {
              document.getElementById(`desc_repq${index}`).innerHTML = "";
              if (element.desc_repq === "") {
                document.getElementById(`desc_repq${index}`).innerHTML =
                  "<p className='text-danger fs-5'>Description requis</p>";
              } else {
                document.getElementById(`desc_repq${index}`).innerHTML = "";
              }
            }
          }
        }
        if (e.target.name === "type_fichq") {
          for (let index = 0; index < formValues.length; index++) {
            const element = formValues[index];
            if (index === i) {
              document.getElementById(`type_fichq${index}`).innerHTML = "";
              if (element.type_fichq === "") {
                document.getElementById(`type_fichq${index}`).innerHTML =
                  "<p className='text-danger fs-5'>Type fichier requis</p>";
              } else {
                document.getElementById(`type_fichq${index}`).innerHTML = "";
              }
            }
          }
        
      }
    };
  
    const addInput = e => {
      e.preventDefault();
      const nvInput = [...formValues, { id_ques: idques,type_fichq:"Photo"}];
      setFormValues(nvInput);
    };
  
    let removeFormFields = i => {
      let newFormValues = [...formValues];
      newFormValues.splice(i, 1);
      setFormValues(newFormValues);
    };
  
    const [ito, setIto] = useState([]);
    const change = choice => {
      setIto(choice);
      setLesson({ ...lesson, id_mod: choice.value });
    };
  
    const handlePageChange = page => {
      setCurrentPage(page);
    };
    const ajoutLessonFichier = e => {
      e.preventDefault();
      var process = 0;
      setLoad(true);
  

        for (let index = 0; index < formValues.length; index++) {
          const element = formValues[index];
          if (
            element.type_repq === undefined ||
            element.type_repq === "" ||
            element.type_repq === "choisir ici..."
          ) {
            document.getElementById(`type_repq${index}`).innerHTML =
              "<p className='text-danger fs-5'>type reponse requis</p>";
            setLoad(false);
            break;
          }
  
          if (
            element.fichierq === undefined ||
            element.fichierq === "" ||
            element.fichierq === null ||
            element.fichierq === {}
          ) {
            document.getElementById(`fichierq${index}`).innerHTML =
              "<p className='text-danger fs-5'>fichier reponse requis</p>";
            setLoad(false);
            break;
          }
          if (
            element.desc_repq === undefined ||
            element.desc_repq === "" ||
            element.desc_repq === "choisir ici..."
          ) {
            document.getElementById(`desc_repq${index}`).innerHTML =
              "<p className='text-danger fs-5'>Description reponse requis</p>";
            setLoad(false);
            break;
          }
          if (
            element.type_fichq === undefined ||
            element.type_fichq === "" ||
            element.type_fichq === "choisir ici..."
          ) {
            document.getElementById(`type_fichq${index}`).innerHTML =
              "<p className='text-danger fs-5'>Type fichier reponse requis</p>";
            setLoad(false);
            break;
          }
  
          if (index === formValues.length - 1) {
            formValues.forEach(element => {
              process++;
              try {
                axios
                  .post(Fichierq_API + "?_method=PUT", element, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      "Access-Control-Allow-Origin" : "*"
                    }
                  })
                  .then(response => {
                    console.log(response);
                    if (process === formValues.length) {
                      setLoad(false);
                      toast.success("reponse ajouter");
                      document.getElementById("fermerModal").click();
                      getAllLesson()
                    }
                  })
                  .catch(error => {
                    console.log(error);
                    const msg = error.response.data;
                    console.log(msg);
                    if (process === formValues.length) {
                      setLoad(false);
                      toast.error(JSON.stringify(msg));
                    }
                  });
                setError([]);
              } catch (error) {
                console.log(error);
              }
            });
         
        }
      }
    };
  
    const paginatedInvoices = Pagination.getData(
      questionnaire,
      currentPage,
      itemsPerPage
    );
   
  
  
    const getAllLesson = async () => {
      ref.current.continuousStart();
      setBarcolor("blue");
  
      try {
        await axios
          .get(ListFichierq_API+"/"+idques)
          .then(response => {
            console.log(response);
            const data = response.data.fichierq;
            setquestionnaire(data);
            ref.current.complete();
          })
          .catch(error => {
            console.log(error);
            setBarcolor("red");
          });
      } catch (error) {
        console.log(error);
      }
    };
    const getAllModule = async () => {
      setBarcolor("blue");
      const getModules = [];
      try {
        await axios
          .get(MODULE_API)
          .then(response => {
            console.log(response);
            const data = response.data.data;
  
            data.forEach(element => {
              getModules.push({
                value: element.id_mod,
                label: element.titre_mod
              });
            });
          })
          .catch(error => {
            console.log(error);
            setBarcolor("red");
          });
        setOptions(getModules);
      } catch (error) {
        console.log(error);
      }
    };
  
    const handleUpdate = async e => {
      e.preventDefault();
      setLoad(true);
      const apiErrors = {};
      try {
        await axios
          .post(Questionnaire_API+"/" + ID + "?_method=PUT", lesson, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin" : "*"
            }
          })
          .then(response => {
            document.getElementById("fermermodif").click();
            toast.success("modification reussi");
            getAllLesson();
            setLoad(false);
            console.log(response);
          })
          .catch(error => {
            toast.warn("un probleme est sruvenu");
            console.log(error);
            const msg = error.response.data;
            setLoad(false);
            if (msg) {
              apiErrors.qcm_ques = msg.qcm_ques;
              apiErrors.dure_ques = msg.dure_ques;
              apiErrors.id_mod = msg.id_mod;
            }
  
            console.log(error);
          });
        setError(apiErrors);
        setLoad(false);
      } catch (error) {
        toast.error("probleme de connexion au serveur !");
        console.log(error);
        setLoad(false);
      }
    };
  
    const supprimer = async () => {
      setLoad(true);
      try {
        await axios
          .delete(Fichierq_API +"/"+ ID)
          .then(response => {
            toast.success("fichier numero " + ID + " supprimer");
            setLoad(false);
            document.getElementById("fermerdel").click();
            getAllLesson();
          })
          .catch(error => {
            console.log(error);
            toast.error("module pas supprimer!");
            setLoad(false);
          });
      } catch (error) {
        toast.error("probleme interne");
        setLoad(false);
      }
    };
  
    const fetchUpdate = (lesson, module) => {
      setID(lesson.id_les);
      setLesson(vide);
      setIto([]);
     
      const updt = {};
      setImage(file_url + lesson.img_les);
      updt.titre_les = lesson.titre_les;
      updt.desc_les = lesson.desc_les;
      setLesson(updt);
    };
    const initialiser = () => {
      document.getElementById("reset_form").click();
      setaffichageRep(null);
      setIto([]);
      setidLF(null);
      setFormValues([]);
      setLesson(vide);
      setImage(null);
      setError(vide);
      setidLF(null);
      console.log("click");
    };
  
    // calls whenever state will be change , another approach
    useEffect(() => {
      getAllLesson();
    }, []);
  
    return (
      <>
        <LoadingBar ref={ref} color={barcolor} />
        <div className="container-lg container-fluid py-4">
          <div
            className="modal modal-blur"
            id="modal-danger"
            tabIndex="-1"
            aria-modal="true"
            role="dialog"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="modal-status bg-danger"></div>
                <div className="modal-body text-center py-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon mb-2 text-danger icon-lg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 9v2m0 4v.01"></path>
                    <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
                  </svg>
                  <h3>vous etes sur ?</h3>
                  <div className="text-muted">
                    vous-voulez vraiment supprimer cette reponse? c'est
                    irreversible.
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="w-100">
                    <div className="row">
                      <div className="col">
                        <button
                          type="button"
                          id="fermerdel"
                          className="btn w-100"
                          data-bs-dismiss="modal"
                        >
                          Annuler
                        </button>
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          onClick={supprimer}
                          className={
                            "btn btn-danger w-100 " + (load && "disabled")
                          }
                        >
                          Supprimer reponse
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal modal-blur" tabIndex="-1" id="exampleModal">
            <div className="modal-dialog modal-lg" role="document">
              <form
                className="my-3"
                onSubmit={ajoutLessonFichier}
              >
                <div className="modal-content">
                  <div className="d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black">
                    <h2 className="p-2">Formulaire exercice</h2>
                    <button
                      className="btn-close p-0"
                      type="button"
                      data-bs-dismiss="modal"
                      id="fermerModal"
                      aria-label="Close"
                    ></button>
                  </div>
  
                 (
                    <div
                      className="modal-body py-0"
                      style={{ height: "550px", overflowY: "auto" }}
                    >
                      <p className="lead my-2">
                        Ajouter ici les reponses liées au exercice , sinon
                        cliquer sur le bouton{" "}
                        <span className="fw-bold text-primary">Annuler</span>
                      </p>
                    
                        <div className="mt-1">
                          <button
                            type="reset"
                            className="p-0 m-0"
                            style={{ visibility: "hidden" }}
                            id="reset_form"
                          ></button>
                          <div className="row my-3">
                            <div className="col d-flex align-items-center gap-3">
                              <label className="form-label">
                                Fichier rattaché
                              </label>{" "}
                              <button
                                className="btn btn-primary"
                                onClick={addInput}
                              >
                                <FontAwesomeIcon icon={faAdd} className="pe-2" />
                                Ajouter un champs
                              </button>
                            </div>
                          </div>
                          <div className="row">
                            {formValues.map((element, index) => (
                              <div
                                className="col-6 d-flex gap-2 my-2"
                                key={index}
                              >
                                <div className="card p-3 w-100">
                                  <div className="mt-1">
                                    <label className="form-label">
                                      C'est la vrai réponse ?
                                    </label>
                                    <select
                                      className="form-control"
                                      name="type_repq"
                                      placeholder="choisir..."
                                      onChange={e =>
                                        handleChangeFichier(index, e)
                                      }
                                    >
                                      <option>choisir ici...</option>
                                      <option value={1}>oui</option>
                                      <option value={0}>non</option>
                                    </select>
                                    <div
                                      style={{ color: "red" }}
                                      id={`type_repq${index}`}
                                    ></div>
                                  </div>
                                  <div className="mt-3">
                                    <label className="form-label">
                                      Fichier du reponse
                                    </label>
                                    <input
                                      type="file"
                                      name="fichierq"
                                      className="form-control"
                                      id={`fichierqq${index}`}
                                      onChange={e => onChangefichier(index, e)}
                                    />
                                    <div
                                      style={{ color: "red" }}
                                      id={`fichierq${index}`}
                                    ></div>
                                  </div>
                                  <div className="mt-3">
                                    <label className="form-label">
                                      Description du reponse
                                    </label>
                                    <textarea
                                      value={element.desc_repq}
                                      className="form-control"
                                      name="desc_repq"
                                      onChange={e =>
                                        handleChangeFichier(index, e)
                                      }
                                    ></textarea>
                                    <div
                                      style={{ color: "red" }}
                                      id={`desc_repq${index}`}
                                    ></div>
                                  </div>
  
                                  {/* <div className="my-3">
                                    <label className="form-label">
                                      Type du fichier
                                    </label>
                                    <select
                                      className="form-control"
                                      value={element.type_fichq}
                                      name="type_fichq"
                                      id="type_fichq"
                                      onChange={e =>
                                        handleChangeFichier(index, e)
                                      }
                                      required
                                    >
                                      <option>choisir ici...</option>
                                      <option value="Photo">Photo</option>
                                      <option value="Video">Video</option>
                                      <option value="Audio">Audio</option>
                                      <option value="document">Document</option>
                                      <option value="Autre">Autre</option>
                                    </select>
                                    <div
                                      style={{ color: "red" }}
                                      id={`type_fichq${index}`}
                                    ></div>
                                  </div> */}
                                  {index ? (
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() => removeFormFields(index)}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                     
                    </div>
               
                  <div className="modal-footer">
                    <div className="w-100">
                      <div className="row justify-content-between">
                        <div className={"col d-flex justify-content-start"}>
                         
                            <button
                              type="button"
                              className="btn"
                              data-bs-dismiss="modal"
                            >
                              <FontAwesomeIcon icon={faCancel} className="me-2" />{" "}
                              Annuler
                            </button>
                         
                        </div>
                        <div className="col d-flex justify-content-end">
                          <button
                            type="submit"
                            className={"btn btn-primary " + (load && "disabled")}
                          >
                            <FontAwesomeIcon icon={faAdd} className="me-2" />{" "}
                            { <>Ajouter</>}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal modal-blur" tabIndex="-1" id="modif">
            <div className="modal-dialog modal-lg" role="document">
              <form className="my-3" onSubmit={handleUpdate}>
                <div className="modal-content">
                  <div className="d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black">
                    <h2 className="p-2">Formulaire exercice</h2>
                    <button
                      className="btn-close p-0"
                      type="button"
                      data-bs-dismiss="modal"
                      id="fermermodif"
                      aria-label="Close"
                    ></button>
                  </div>
  
                  <div
                    className="modal-body py-0"
                    style={{ height: "550px", overflowY: "auto" }}
                  >
                    <div className="my-3">
                      <label className="form-label">Titre</label>
                      <input
                        className={
                          "form-control " + (error.titre_les && "is-invalid")
                        }
                        type="text"
                        name="titre_les"
                        value={lesson.titre_les}
                        onChange={handleChange}
                        placeholder="Saisir titre du module"
                      />
                      {error.titre_les && (
                        <p className="invalid-feedback">{error.titre_les}</p>
                      )}
                    </div>
                    <div className="my-3 row">
                      <div className="col">
                        <label className="form-label">Modules</label>
                        <Select
                          closeMenuOnSelect
                          components={animatedComponents}
                          defaultValue={[options[4], options[5]]}
                          isMulti
                          name="id_mod"
                          placeholder="choix module"
                          options={options}
                          value={ito}
                          onMenuOpen={() => getAllModule()}
                          onChange={choice => change(choice)}
                          noOptionsMessage={() => "plus d'options"}
                          isDisabled={loadModule}
                          isLoading={loadModule}
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                      <label className="form-label">
                        Image de representation du leçons
                      </label>
                      <div className="row">
                        <div className="col-lg-6 col-12 mb-2">
                          <input
                            type="file"
                            className={
                              "form-control " + (error.img_les && "is-invalid")
                            }
                            id="img_les"
                            onChange={onImageChange}
                            placeholder="choisir un image"
                            accept="image/png, image/gif, image/jpeg"
                            multiple
                          />
                          {error.img_les && (
                            <p className="invalid-feedback">{error.img_les}</p>
                          )}
                        </div>
  
                        {image && (
                          <span
                            className="avatar avatar-sm"
                            style={{ backgroundImage: `url(${image})` }}
                          ></span>
                        )}
                      </div>
                    </div>
                    <div className="my-3">
                      <label className="form-label">Description du leçons</label>
                      <textarea
                        rows={5}
                        className={
                          "form-control " + (error.desc_les && "is-invalid")
                        }
                        name="desc_les"
                        value={lesson.desc_les}
                        onChange={handleChange}
                        placeholder="saisir description"
                      ></textarea>
                      {error.desc_les && (
                        <p className="invalid-feedback">{error.desc_les}</p>
                      )}
                    </div>
                  </div>
  
                  <div className="modal-footer">
                    <div className="w-100">
                      <div className="row justify-content-between">
                        <div className={"col d-flex justify-content-start"}>
                          <button
                            type="button"
                            className="btn"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <FontAwesomeIcon icon={faCancel} className="me-2" />{" "}
                            Annuler
                          </button>
                        </div>
                        <div className="col d-flex justify-content-end">
                          <button
                            type="submit"
                            className={"btn btn-primary " + (load && "disabled")}
                          >
                            <FontAwesomeIcon icon={faAdd} className="me-2" />
                            Modifier
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div>
              <h2>Reponses</h2>
              <p className="">Liste des reponses avec fichier</p>
            </div>
            <div>
              <button
                type="button"
                onClick={() => initialiser()}
                className="btn btn-primary px-4 py-2"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <FontAwesomeIcon icon={faAdd} className="me-2" /> Nouvel Reponse avec fichier
              </button>
            </div>
          </div>
  
          <div className="overflow-auto">
            <table className="table table-hover table-bordered table-striped table-light ">
              <thead>
                <tr className="">
                  <th>ID</th>
                  <th>Question</th>
                  <th className="col-1">reponse</th>
                  <th className="col-1">est ce la vrai reponse ?</th>
                  <th className="col-1">Type fichier</th>
                  <th className="col-1">Action</th>
                </tr>
              </thead>
              <tbody>
                {paginatedInvoices.map((element, index) => (
                  <tr key={index}>
                    <td>{element.id_fichq}</td>
                    <td>{element.id_ques}</td>
                    <td> <span className="avatar avatar-sm" style={{ backgroundImage: `url(${file_url}/${element.fichierq})` }}></span></td>
                    <td>{element.type_repq ? <span>oui</span> : <span>non</span>}</td>
                    <td>{element.type_fichq}</td>
                 
                    <td className="d-flex gap-2 justify-content-center">
                      {/* <button
                        data-bs-toggle="modal"
                        data-bs-target="#modif"
                        onClick={() =>
                          fetchUpdate(element.lesson, element.module)
                        }
                        className="btn btn-primary"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button> */}
                      <button
                        type="button"
                        onClick={() => setID(element.id_fichq)}
                        data-bs-toggle="modal"
                        data-bs-target="#modal-danger"
                        className="btn btn-danger"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-end">
              <Pagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                onPageChanged={handlePageChange}
                length={questionnaire.length}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
  