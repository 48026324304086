import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { Historique_api } from "../../../service/config";
import moment from "moment";
import { toast } from "react-toastify";

export default function Historique() {
  const columns = [
    // {
    //   name: "ID",
    //   width: "170px",
    //   wrap: true,
    //   selector: (row) => row.id_histo,
    //   sortable: true,
    // },
    {
      name: "Date",
      width: "170px",
      wrap: true,
      selector: (row) => moment(row.created_at).format("DD/MM/yyyy"),
      sortable: true,
    },
    {
      name: "Déscription",
      wrap: true,
      selector: (row) => <div className="text-justify">{row.desc_histo}</div>,
      sortable: true,
    },
  ];

  const [data, setdata] = useState([]);
  const [load, setload] = useState(true);
  const getHistory = () => {
    setload(true)
    axios
      .get(Historique_api + "?order=desc&order_by=id_histo@limit=2")
      .then((hist) => {
        console.log(hist);
        setdata(hist.data.data);
        setload(false)
      })
      .catch((err) => {
        console.log(err);
        toast.error("un erreur est survenu !")
      });
  };

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <div className="h-100">
      <div className="jumbotron container-lg container-fluid h-100 w-100 py-4">
        <div className="row row-cards">
          <div className="">
            <DataTable
              highlightOnHover
              className="custom-table"
              title={
                <h1 className="text-start">
                  <FontAwesomeIcon icon={faHistory} /> Les historiques
                </h1>
              }
              columns={columns}
              data={data}
              selectableRows={false}
              progressPending={load}
              progressComponent={
                <div className="w-100 d-flex justify-content-center py-5">
                  <TailSpin
                    height="80"
                    width="80"
                    color="#22577A"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              }
              contextMessage={{
                singular: "élement",
                plural: "élements",
                message: "sélectionnées",
              }}
              pagination
              paginationPerPage={15}
              paginationComponentOptions={{
                rowsPerPageText: "Lignes par page",
              }}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
