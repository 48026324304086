import axios from "axios";
import { motsministres } from "../config";
import {store} from "../Redux/store";

export const getMots = () => {
    axios
      .get(motsministres)
      .then((rep) => {
        console.log(rep.data);
        if (rep.data.data.length == 0) {
            store.dispatch({type:'clearmots'})
        }else{
            store.dispatch({type:'addmots',payload:rep.data.data[0]})
        }
        
      })
      .catch((err) => {
        console.log(err);
      });
  };