import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

export default function BarChart() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
  
   //(width/height)
   
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        // text: "Chart.js Bar Chart",
      },
    }
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];
  const donnee = [789, 698, 456, 562, 200, 500, 600];
  const donnee2 = [749, 528, 666, 302, 742, 620, 804];
  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: donnee.map((el) => el),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Dataset 2",
        data: donnee2.map((el) => el),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return (
    <>
      <Bar width={100} height={50} options={options} data={data} />;
    </>
  );
}
