import { combineReducers } from "redux";
import {Auto, TypeExamen, actualite, categ, compte, getMots, sensibilisation } from "./arrayReducers";

const rootReducer = combineReducers({
    actu: actualite,
    sensi:sensibilisation,
    categ: categ,
    compte:compte,
    listtypeexam:TypeExamen,
    motmin:getMots,
    auto:Auto
});

export default rootReducer;