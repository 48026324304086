import * as XLSX from 'xlsx';

export const XLSexport = (data,filename,feuille) => {
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Définir la largeur des colonnes
    const colWidths = [
      { wch: 20 }, // largeur de la première colonne
      { wch: 30 }, // largeur de la deuxième colonne
      // Ajoutez plus d'objets avec des largeurs pour chaque colonne supplémentaire
    ];

    // Appliquer les largeurs de colonnes à la feuille de calcul
    worksheet['!cols'] = colWidths;

    // Ajouter des bordures à toutes les cellules
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { c: C, r: R };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        if (!worksheet[cellRef]) continue;
        worksheet[cellRef].s = {
          border: {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' }
          }
        };
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, feuille);
    XLSX.writeFile(workbook, filename+".xlsx");
  };