import { faCheckCircle, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tag } from "primereact/tag";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";

import moment from "moment";
import { Button } from "react-bootstrap";
import UseEndpoint from "../../../../service/getApi/UseEndpoint";
import {
  ResPassPratique,
  ResPassPratiqueV1,
  ResRedoublePratique,
} from "../../../../service/config";
import Cherche from "../../../../outils/Cherche";
import PrintResPratique from "../../../../service/Print/PrintResPratique";
import { useSelector } from "react-redux";
import ExamenEffectuer from "./ExamenEffectuer";

export default function ListPratiqueMap({
  recharger,
  data,
  listcentre,
  categ,
  dataecole,
  auto,
  filtre,
  setselectedCand,
  selectedCand
}) {
  const dataRedux = useSelector((state) => state.listtypeexam);
  const [nom, setnom] = useState("");
  const { data: list, load } =
    filtre?.statut == 0
      ? UseEndpoint(
          ResPassPratiqueV1({ id_org: auto?.value, id_exam: data[0]?.id_examp }),
          "data",
          recharger
        )
      : filtre?.statut == 1
      ? UseEndpoint(
          ResRedoublePratique({
            id_org: auto?.value,
            id_exam: data[0]?.id_examp,
          }),
          "data",
          recharger
        )
      : UseEndpoint(
        ResPassPratiqueV1({ id_org: auto?.value, id_exam: data[0]?.id_examp }),
        "data",
        recharger
      )
      console.log(list)
  const Type = (id) => {
    console.log(dataRedux);
    const element = dataRedux.find((element) => element.value == id);

    if (element !== undefined) {
      return `${element.label}`;
    } else {
      return `${id}`;
    }
  };
  const columns = [
    {
      name: "ID Candidat",
      selector: (row) => row.notepratique["unique_id_cand"],
      sortable: true,
    },
    {
      name: "Nom & prénom",
      wrap: true,
      selector: (row) => row["nom_cand"] + " " + row["prenom_cand"],
      sortable: true,
    },
    {
      name: "Auto-école",
      wrap: true,
      selector: (row) => row["organisme"]?.nom_org,
      sortable: true,
    },
    {
      name: "Permis",
      wrap: true,
      selector: (row) => row["categ_permis"],
      sortable: true,
    },
    {
      name: "Type examen",
      wrap: true,
      selector: (row) => Type(row["notepratique"].id_typeexam),
      sortable: true,
    },
    // {
    //   name: "Tempsp",
    //   selector: (row) => (row.tempsp_cand ? row.tempsp_cand : "Aucun info"),
    //   sortable: true,
    // },
    // {
    //   name: "Note",
    //   selector: (row) => row.notep_cand + "/" + row.notesurp_cand,
    //   sortable: true,
    // },
    // {
    //   name: "statut",
    //   minWidth: "200px",
    //   selector: (row) => (
    //     <Tag severity={"success"}>{row.candidat["status_examen_cand"]}</Tag>
    //   ),
    // },
    {
      name: "Info du candidat",
      cell: (row) =>  <ExamenEffectuer list={row?.all_examens} selectedCand={row}/>,
      sortable: true,
    },
  ];

  useEffect(() => {
    let centre = Cherche(listcentre, data[0], "id_org", "nom_org");
    setnom(centre);
  }, [data]);

  useEffect(() => {
    if (load === true) {
      setselectedCand([]);
    }
  }, [load]);

  return (
    <div>
      <div className="">
        <DataTable
          noContextMenu
          className="custom-table"
          columns={columns}
          data={list}
          selectableRows
          selectableRowsSingle
          onSelectedRowsChange={(row) => setselectedCand(row.selectedRows)}
          highlightOnHover
          progressPending={load}
          progressComponent={
            <div className="w-100 d-flex justify-content-center py-5">
              <TailSpin
                height="80"
                width="80"
                color="#22577A"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          }
          contextMessage={undefined}
          contextActions={null}
          pagination
          paginationPerPage={5}
          paginationComponentOptions={{
            rowsPerPageText: "Lignes par page",
          }}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      </div>
      {list.length > 0 && (
        <div className=" my-2 d-flex justify-content-end gap-2">
          {/* <button className="btn btn-secondary">
            <FontAwesomeIcon icon={faFilePdf} className="me-2" />
            Exporter en PDF
          </button> */}
          {/* <button className="btn btn-success">
                  <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                  Valider
                </button> */}
        </div>
      )}
    </div>
  );
}
