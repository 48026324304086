import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Breadcumb from "../../../../../outils/BreadCumb/Breadcumb";

const PratiquesRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="container-lg container-fluid pt-3 ">
    <div className="card p-3 rounded-3">
    <div className="mb-1 d-flex flex-row align-items-center justify-content-between">
        <div className="">
          <h1>Candidats / Examen pratique </h1>
        </div>
        <div className="d-flex flex-wrap justify-content-between">
        <div className="d-flex gap-2 flex-wrap">
          <div
            onClick={() => navigate("/pratiques")}
            className={`btn btn-secondary ${
              location.pathname === "/pratiques" ? "btn-primary" : ""
            }`}
          >
            aptes à candidater
          </div>
          <div
            onClick={() => navigate("/pratiques/affecter")}
            className={`btn btn-secondary ${
              location.pathname === "/pratiques/affecter" ? "btn-primary" : ""
            }`}
          >
            envoyés à l’examen pratique
          </div>{" "}
          <div
            onClick={() => navigate("/pratiques/valider")}
            className={`btn btn-secondary ${
              location.pathname === "/pratiques/valider" ? "btn-primary" : ""
            }`}
          >
            Validés par l’ATT
          </div>
        </div>
      </div>
      </div>
     
    </div>
      <div className="mt-2">
        <Outlet />
      </div>
    </div>
  );
};

export default PratiquesRoutes;
