import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";

export default function ExamenEffectuer({ list,selectedCand }) {
  // "id_validationp": 20,
  //         "unique_id_cand": "ainfit771",
  //         "notep_cand": null,
  //         "notesurp_cand": null,
  //         "tempsp_cand": null,
  //         "validep_cand": false,
  //         "id_cand": 12,
  //         "id_examp": 36,
  //         "created_at": "2023-08-25T09:25:40.000000Z",
  //         "updated_at": "2023-08-25T09:25:40.000000Z",
  //         "examenpratiques": {
  //             "id_examp": 36,
  //             "heurs_examp": "08:48:00",
  //             "date_examp": "2023-08-29",
  //             "temps_examp": null,
  //             "id_org": 21,
  //             "id_memb": 19,
  //             "id_categ": 3,
  //             "created_at": "2023-08-29T02:43:56.000000Z",
  //             "updated_at": "2023-08-29T02:43:56.000000Z",
  //             "unique_id_meb": "bolexa438",
  //             "organisme": {
  const columns = [
    {
      name: "ID Candidat",
      selector: (row) => row["unique_id_cand"],
      sortable: true,
    },
    {
      name: "Date et Heure",
      selector: (row) =>
        row["examenpratiques"]?.date_examp +
        " à " +
        row["examenpratiques"]?.heurs_examp,
      sortable: true,
    },
    {
      name: "Auto-école",
      wrap: true,
      selector: (row) => row["examenpratiques"]?.organisme.nom_org,
      sortable: true,
    },
    {
      name: "Note",
      wrap: true,
      selector: (row) => row["notep_cand"] + "/" + row["notesurp_cand"],
      sortable: true,
    },
  ];
  const [examodal, setexamodal] = useState(false)
  const dataRedux = useSelector((state) => state.listtypeexam);
  const Type = (id) => {
    console.log(dataRedux);
    const element = dataRedux.find((element) => element.value == id);

    if (element !== undefined) {
      return `${element.label}`;
    } else {
      return `${id}`;
    }
  };
  return (
    <div>
      <div className="">
        <Button variant="info" onClick={()=>setexamodal(!examodal)}>Détails examen</Button>
      </div>
      <Modal autoFocus={true} backdrop="static" size="lg" show={examodal} onHide={()=>setexamodal(!examodal)}>
        <Modal.Header closeButton>Détails examen</Modal.Header>
        <Modal.Body>
        <table className="border-none">
            <tr>
              <th className="pb-1">
                <span className=" me-2">Nom et prenom :</span>
              </th>
              <th className="pb-1">
                <span className="fw-bold">
                  {selectedCand?.nom_cand} {selectedCand?.prenom_cand}
                </span>
              </th>
            </tr>
            <tr>
              <th className="pb-1">
                <span className=" me-2">Auto éole :</span>
              </th>
              <th className="pb-1">
                <span className="fw-bold">
                  {selectedCand?.organisme?.nom_org}
                </span>
              </th>
            </tr>
            <tr>
              <th className="pb-1">
                <span className=" me-2">Adresse :</span>
              </th>
              <th className="pb-1">
                <span className="fw-bold">{selectedCand?.adresse_cand}</span>
              </th>
            </tr>
            <tr>
              <th className="pb-1">
                <span className=" me-2">Region :</span>
              </th>
              <th className="pb-1">
                <span className="fw-bold">{selectedCand?.province_cand}</span>
              </th>
            </tr>
            <tr>
              <th className="pb-1">
                <span className=" me-2">Email :</span>
              </th>
              <th className="pb-1">
                <span className="fw-bold">{selectedCand?.email_cand}</span>
              </th>
            </tr>
            <tr>
              <th className="pb-1">
                <span className=" me-2">Type d'examen :</span>
              </th>
              <th className="pb-1">
                <span className="fw-bold">
                  {Type(selectedCand?.notepratique.id_typeexam)}
                </span>
              </th>
            </tr>
            <tr>
              <th className="pb-1">
                <span className=" me-2">Note :</span>
              </th>
              <th className="pb-1">
                <span className="fw-bold">
                  {selectedCand?.notepratique.notep} /{" "}
                  {selectedCand?.notepratique.notesurp}
                </span>
              </th>
            </tr>
          </table>
          <DataTable
            noContextMenu
            title={
              <div className="mb-4 mt-3">
                <div className="row justify-content-end g-3">
                  <div className="col-12 d-flex justify-content-between ">
                    <div className="text-wrap fs-4">
                      <span className="fs-2 h1">Les examens effectués</span>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            }
            className="custom-table"
            columns={columns}
            data={list}
            highlightOnHover
            progressComponent={
              <div className="w-100 d-flex justify-content-center py-5">
                <TailSpin
                  height="80"
                  width="80"
                  color="#22577A"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            }
            contextMessage={undefined}
            contextActions={null}
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page",
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
