import React from "react";
import SelectOne from "../../../../outils/Select/selectCustom";
import { regions } from "../../../../service/auth/UtilApi";
import InputMask from "react-input-mask";
import isconnected from "../../../../service/isconnected";
import { Image } from "primereact/image";
import imageFound from "../../../../assets/image/avatar.jpg";
import ScanNotFound from "../../../../assets/image/imagenotfound.png";
import Inpt from "../../../../outils/Input/Inpt";

export default function Details({
  setImage,
  setImagescan,
  error,
  setError,
  formValues,
  setFormValues,
  handleChange,
  onImageChange,
  image,
  imagescan,
  imagenotfound,
}) {
  const data = {
    nom_memb: "MOSA",
    prenom_memb: "Jules",
    naiss_memb: "1999-12-12",
    sexe_memb: true,
    nationalite_memb: "Malagasy",
    adresse_memb: "Itaosy",
    ville_memb: "Ambohidratrimo",
    province_memb: "Analamanga",
    pays_memb: "Madagascar",
    cp_memb: "102",
    tel_memb: "+261 34 75 041 29",
    email_memb: "examinateur4@yopmail.com",
    role_memb: "Examinateurs",
    fonction_memb: "Examinateur",
    active_memb: "Desactiver",
    photo_memb: "img/membre/IOQjWFv3L2ZxRV5PmTC08fRE2pNGsCZHlBqejFJO.gif",
    cin_memb: "1513145456",
    date_capp: null,
    num_capp: null,
    scan_capp: null,
  };

  return (
    <div className="row">
      <div className="col-lg-6 col-12 d-flex align-item-stretch">
        <div className="card w-100">
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-12 d-flex justify-content-center">
                {image && (
                  <div className="rounded-3" style={{ overflow: "hidden" }}>
                    <Image
                      src={image}
                      height="130"
                      className="rounded-3"
                      style={{ borderRadius: "25px" }}
                      onError={() => setImage(imageFound)}
                      preview
                    />
                  </div>
                )}
              </div>
              <div className="col-12">
                <div className="">
                  <label className="form-label">Photo </label>
                  <input
                    className={
                      "form-control " + (error.photo_memb && "is-invalid")
                    }
                    name="photo_memb"
                    id="photo_memb"
                    type="file"
                    onChange={onImageChange}
                    accept="image/*"
                  />
                  {error.photo_memb && (
                    <div className="invalid-feedback">{error.photo_memb}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="my-3">
              <label className="form-label">Nom</label>
              <input
                className={"form-control " + (error.nom_memb && "is-invalid")}
                name="nom_memb"
                value={formValues.nom_memb}
                onChange={handleChange}
                type="text"
                placeholder="Saisir ici votre nom "
              />
              {error.nom_memb ? (
                <div className="invalid-feedback">{error.nom_memb}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3">
              <label className="form-label">Prenom</label>
              <input
                className={
                  "form-control " + (error.prenom_memb && "is-invalid")
                }
                name="prenom_memb"
                value={formValues.prenom_memb}
                onChange={handleChange}
                type="text"
                placeholder="Saisir ici votre prenom"
              />
              {error.prenom_memb ? (
                <div className="invalid-feedback">{error.prenom_memb}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3">
              <label className="form-label">Sexe</label>
              <select
                className={"form-control " + (error.sexe_memb && "is-invalid")}
                name="sexe_memb"
                value={
                  formValues.sexe_memb === true
                    ? "1"
                    : formValues.sexe_memb === false
                    ? "0"
                    : formValues.sexe_memb === ""
                    ? formValues.sexe_memb
                    : formValues.sexe_memb
                }
                onChange={handleChange}
              >
                <option>choisir sexe ...</option>
                <option value={0}>Femme</option>
                <option value={1}>Homme</option>
              </select>
              {error.sexe_memb ? (
                <div className="invalid-feedback">{error.sexe_memb}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3">
              <label className="form-label">Date de naissance</label>
              <input
                className={"form-control " + (error.naiss_memb && "is-invalid")}
                name="naiss_memb"
                value={formValues.naiss_memb}
                onChange={handleChange}
                type="date"
                placeholder="Chosir date"
              />
              {error.naiss_memb ? (
                <div className="invalid-feedback">{error.naiss_memb}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3">
                <label className="form-label">Nationalité</label>
                <input
                  className={
                    "form-control " + (error.nationalite_memb && "is-invalid")
                  }
                  name="nationalite_memb"
                  value={formValues.nationalite_memb}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir ici votre nationalité"
                />
                {error.nationalite_memb ? (
                  <div className="invalid-feedback">
                    {error.nationalite_memb}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            <div className="my-3">
              <label className="form-label">
                Carte d'Identité Nationale (CIN){" "}
              </label>
              <InputMask
                onKeyUp={(e) => {
                  console.log(e.target.value);
                  if (e.target.value === "") {
                    setError({
                      ...error,
                      cin_memb: ["le champs telephone est requis"],
                    });
                  } else if (e.target.value !== "") {
                    setError({ ...error, cin_memb: [] });
                  }
                }}
                onChange={handleChange}
                mask="999 999 999 999"
                type="text"
                className={
                  "form-control " +
                  (error.cin_memb?.length > 0 && " is-invalid")
                }
                value={formValues.cin_memb}
                name="cin_memb"
                placeholder="Telephone"
              />

              {error.cin_memb &&
                error.cin_memb.map((element) => (
                  <p key={element} className="invalid-feedback p-0 m-1">
                    {element}
                  </p>
                ))}
            </div>
            <div className="my-3">
              <label className="form-label">Email</label>
              <input
                className={"form-control " + (error.email_memb && "is-invalid")}
                name="email_memb"
                value={formValues.email_memb}
                onChange={handleChange}
                type="email"
                placeholder="Saisir ici votre email"
              />
              {error.email_memb ? (
                <div className="invalid-feedback">{error.email_memb}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3">
                <label className="form-label">Adresse</label>
                <input
                  className={
                    "form-control " + (error.adresse_memb && "is-invalid")
                  }
                  name="adresse_memb"
                  value={formValues.adresse_memb}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir ici votre adresse du membre"
                />
                {error.adresse_memb ? (
                  <div className="invalid-feedback">{error.adresse_memb}</div>
                ) : (
                  <></>
                )}
              </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-12 d-flex align-item-stretch">
        <div className="card w-100">
          <div className="card-body">
          <div className="my-3">
                <label className="form-label">Ville</label>
                <input
                  className={
                    "form-control " + (error.ville_memb && "is-invalid")
                  }
                  name="ville_memb"
                  value={formValues.ville_memb}
                  onChange={handleChange}
                  type="text"
                  placeholder="Saisir ici votre ville"
                />
                {error.ville_memb ? (
                  <div className="invalid-feedback">{error.ville_memb}</div>
                ) : (
                  <></>
                )}
              </div>
            <div className="my-3">
              <label className="form-label">Région (*)</label>
              <SelectOne
                options={regions}
                load={false}
                choix={formValues.province_memb}
                placeholder="choix région"
                setFormValues={setFormValues}
                formValues={formValues}
                setError={setError}
                error={error}
                name={"province_memb"}
              />
              {error.province_memb ? (
                <div className="invalid-feedback">{error.province_memb}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="row">
            <div className="col-12 col-lg-4">
                <label className="form-label">Code postal</label>
                <input
                  className={"form-control " + (error.cp_memb && "is-invalid")}
                  name="cp_memb"
                  value={formValues.cp_memb}
                  onChange={handleChange}
                  type="number"
                  placeholder="votre code postal"
                />
                {error.cp_memb ? (
                  <div className="invalid-feedback">{error.cp_memb}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-12 col-lg-8">
              <label className="form-label">Pays</label>
              <input
                className={"form-control " + (error.pays_memb && "is-invalid")}
                name="pays_memb"
                value={formValues.pays_memb}
                onChange={handleChange}
                type="text"
                placeholder="Saisir ici votre pays du membre"
              />
              {error.pays_memb ? (
                <div className="invalid-feedback">{error.pays_memb}</div>
              ) : (
                <></>
              )}
            </div>
            </div>
            <div className="my-3">
              <label className="form-label">Telephone </label>
              <InputMask
                onKeyUp={(e) => {
                  console.log(e.target.value);
                  if (e.target.value === "") {
                    setError({
                      ...error,
                      tel_memb: ["le champs telephone est requis"],
                    });
                  } else if (e.target.value !== "") {
                    setError({ ...error, tel_memb: [] });
                  }
                }}
                onChange={handleChange}
                mask="+261 39 99 999 99"
                type="text"
                className={
                  "form-control " +
                  (error.tel_memb?.length > 0 && " is-invalid")
                }
                value={formValues.tel_memb}
                name="tel_memb"
                placeholder="Telephone"
              />

              {error.tel_memb &&
                error.tel_memb.map((element) => (
                  <p key={element} className="invalid-feedback p-0 m-1">
                    {element}
                  </p>
                ))}
            </div>
           
            <div className="my-3">
              <label className="form-label">Fonction</label>
              <select
                className={
                  "form-control " + (error.fonction_memb && "is-invalid")
                }
                name="fonction_memb"
                value={formValues.fonction_memb}
                onChange={handleChange}
              >
                <option>choisir fonction ...</option>
                <option value="Moniteur">moniteur</option>
                <option value="validateur">validateur</option>
                <option value="Directeur administratif et financier">Directeur administratif et financier</option>
                <option value="directeur">directeur</option>
                <option value="secrétaire">secrétaire</option>
                <option value="Autre">Autre</option>
              </select>
              {error.fonction_memb ? (
                <div className="invalid-feedback">{error.fonction_memb}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="my-3">
              <Inpt
                error={error}
                formValues={formValues}
                name={"num_capp"}
                handleChange={handleChange}
                type={"text"}
                label={
                  "N°CAPP (Certificat d’Aptitude Professionnelle et Pédagogique)"
                }
                placeholder={"Saisir numéro CAPP"}
              />
            </div>
            <div className="my-3">
              <Inpt
                error={error}
                formValues={formValues}
                name={"date_capp"}
                handleChange={handleChange}
                type={"date"}
                label={"Date de délivrance du CAPP"}
                placeholder={"Date de délivrance du CAPP"}
              />
            </div>
            <div className="my-3">
              <label className="form-label">SCAN du CAPP </label>
              <input
                className={"form-control " + (error.scan_capp && "is-invalid")}
                name="scan_capp"
                id="scan_capp"
                type="file"
                onChange={onImageChange}
                accept="image/*"
              />
              {error.scan_capp && (
                <div className="invalid-feedback">{error.scan_capp}</div>
              )}
              {imagescan ? (
                <div className="rounded-3">
                  <Image
                    src={imagescan}
                    height="130"
                    className="rounded-3 mt-3"
                    style={{ borderRadius: "25px" }}
                    preview
                  />
                </div>
              ) : (
                <div className="rounded-3">
                  <Image
                    src={ScanNotFound}
                    height="130"
                    className="rounded-3"
                    style={{ borderRadius: "25px" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
