import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { sortByProperty } from "../SortByProperty";

export default function SelectOneId(props) {
  const animatedComponents = makeAnimated();
  let text = "";
  const [ito, setIto] = useState([]);
  const SortedValue = sortByProperty(props.options)
  const change = (choice) => {
    setIto(choice);
    text = "";
    text = choice.value;
    props.setFormValues({ ...props.formValues, [props.name]: text });
    props.setError({ ...props.error, [props.name]: null });
  };

  useEffect(() => {
    if (props.choix == null || props.choix == "") {
      setIto(null);
    } else {
      let categ = props.choix;
      let valueCat = [];
      valueCat.push({ value: categ, label: categ });
      setIto(valueCat);
    }
  }, [props.choix]);
  return (
    <Select
    closeMenuOnSelect
    components={animatedComponents}
    placeholder={props.placeholder}
    options={SortedValue}
    name="id_categ"
    value={ito}
    className=""
    onChange={(choice) => change(choice)}
    noOptionsMessage={() => "plus d'options"}
    isDisabled={props.load}
    isLoading={props.load}
  />
  );
}
