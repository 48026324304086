import {
  faArrowAltCircleRight,
  faFile,
  faFilePdf,
  faFileWord,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ACTU_API, file_url } from "../../../service/config";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";


export default function Details_actu() {
  let { id } = useParams();
  const [load, setload] = useState(true);
  const [textlois, setTextlois] = useState({});
  function Separer(strin) {
    if (strin === undefined || strin === null) {
      return null;
    } else {
      var tab = strin.split(";");

      return (
        <>
          {tab.map(
            (element, index) =>
              element !== "" && (
                <span
                  key={index}
                  className="badge bg-transparent text-primary border border-indigo"
                >
                  {element}
                </span>
              )
          )}
        </>
      );
    }
  }
  function verifier(url) {
    var parts = url.split(".");
    var extension = parts.pop();
    return extension;
  }
  const download = async (ur) => {
    const url = file_url + ur;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute("download", "file.pdf"); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  const getTextloi = async () => {
    setload(true);
    try {
      await axios
        .get(ACTU_API + "/" + id)
        .then((reponse) => {
          console.log(reponse);
          setload(false);
          setTextlois(reponse.data.actualite);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTextloi();
  }, []);

  return (
    <div className="container-lg container-fluid py-3">
      <div className="card w-100">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h1 className="fst-italic">
            <FontAwesomeIcon icon={faNewspaper} className="me-2" />
            Actualité
          </h1>
          <Link to="/tous les actualités">
            <p>
              Toutes les actualités{" "}
              <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </p>
          </Link>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row  flex-lg-row-reverse justify-content-between align-items-center g-5 py-4">
            <div className="col-12 col-sm-8 col-lg-4">
              {load === false && (
                <img
                  src={file_url + textlois.img_actu}
                  className="d-block mx-lg-auto img-fluid text-center"
                  style={{ width: "60%" }}
                  alt="permis categorie"
                  loading="lazy"
                />
              )}
            </div>
            <div className="col-lg-8">
              <h1 className="display-7 fw-bold lh-1 mb-3 text-primary">
                {textlois.titre_actu}
              </h1>
              <div className="my-2 d-flex gap-1">
                {Separer(textlois.tag_actu)}
              </div>
              <p className="lead">{textlois.resum_actu}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 rounded-3">
          <div className="card">
            <div className="card-body">
              <div className="non-edit">
              <CKEditor
                editor={Editor}
                data={textlois.desc_actu}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                  editor.enableReadOnlyMode("editor");
                  const toolbar = editor.ui.view.toolbar.element;
                  toolbar.style.display = "none";
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                }}
                // onBlur={ ( event, editor ) => {
                //     console.log( 'Blur.', editor );
                // } }
                // onFocus={ ( event, editor ) => {
                //     console.log( 'Focus.', editor );
                // } }
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
