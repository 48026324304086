import axios from "axios"
import {store} from "../Redux/store"
import { typeExam } from "../config"

export const gettype = ()=>{
    let type = []
      try {
          axios.get(typeExam).then((reponse)=>{
              console.log(reponse)
              reponse.data.data.forEach(element => {
                type.push({label:element.type_exam,value:element.id_typeexam})
              });
              store.dispatch({type:'listtypeexam',payload:type})
          }).catch((err)=>{
              console.log(err)
          })
      } catch (error) {
          console.log(error)
      }
  }