import React, { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import SelectOneId from "../../../../outils/Select/selectCustomId";
import Inpt from "../../../../outils/Input/Inpt";
import { Image } from "primereact/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { toast } from "react-toastify";
import {
  GetMembreByMail,
  affecterMoniteur,
  file_url,
  inscriptionMoniteur,
} from "../../../../service/config";
import {
  faEye,
  faEyeSlash,
  faPlus,
  faUsersRectangle,
} from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
import SelectOne from "../../../../outils/Select/selectCustom";
import { regions } from "../../../../service/auth/UtilApi";
import { useSelector } from "react-redux";

export default function AddMoniteur(props) {
  const dataRedux = useSelector((state) => state.auto);
  const MonCompte = useSelector((state) => state.compte);

  const [formValues, setFormValues] = useState({
    nom_memb: "",
    prenom_memb: "",
    sexe_memb: "",
    naiss_memb: "",
    cin_memb: "",
    nationalite_memb: "",
    email_memb: "",
    tel_memb: "",
    adresse_memb: "",
    cp_memb: "",
    ville_memb: "",
    province_memb: "",
    pays_memb: "",
    photo_memb: "",
    fonction_memb: "",
    role_memb: "Moniteur",
    num_capp: "",
    date_capp: "",
    scan_capp: "",
    password: "",
    password_confirmation: "",
  });
  const [vide, setvide] = useState({
    nom_memb: "",
    prenom_memb: "",
    sexe_memb: "",
    naiss_memb: "",
    cin_memb: "",
    nationalite_memb: "",
    email_memb: "",
    tel_memb: "",
    adresse_memb: "",
    cp_memb: "",
    ville_memb: "",
    province_memb: "",
    pays_memb: "",
    photo_memb: "",
    fonction_memb: "",
    role_memb: "Moniteur",
    num_capp: "",
    date_capp: "",
    scan_capp: "",
    password: "",
    password_confirmation: "",
  });
  const [mode, setmode] = useState(true);
  const [show, setShow] = useState(false);
  const [load, setload] = useState({
    landing: true,
    getdata: false,
    find: false,
  });
  const [showdp, setshowdp] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
   if (load?.find) {
    
   }else {
    const { name, value } = currentTarget;
    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
   }
  };

  const [image, setImage] = useState(null);
  const [capp, setscan_capp] = useState(null);

  const onImageChange = (e) => {
   if (load?.find) {
    
   }else{
    console.log(e.target.files[0].size);

    if (e.target.files && e.target.files[0]) {
      setFormValues({ ...formValues, [e.target.name]: e.target.files[0] });
      setError({ ...error, [e.target.name]: null });
    }
    if (e.target.name === "scan_capp") {
      setscan_capp(URL.createObjectURL(e.target.files[0]));
    } else {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
   }
  };

  const findmoniteur = ({ notif }) => {
    setload({ ...load, getdata: true });
    if (formValues.email_memb) {
      axios
        .get(GetMembreByMail + "?email=" + formValues.email_memb + "")
        .then((reponse) => {
          console.log(reponse.data.membre[0]);
          let mon = reponse.data.membre[0];
          if (mon?.nom_memb) {
            setFormValues(mon);
            setImage(file_url + mon?.photo_memb);
            setscan_capp(file_url + mon?.scan_capp);
            setload({ landing: false, getdata: false, find: true });
          } else {
            toast.info("Aucune info trouver !");
            setFormValues({...formValues,id_org: MonCompte.id_org})
            setload({ landing: false, getdata: false, find: false });
          }

          console.log(formValues);
        })
        .catch((error) => {
          console.log(error);
          setload({ ...load, getdata: false });
        });
    } else {
      setload({ landing: false, getdata: false, find: false });
    }
  };

  const changer = async () => {
    try {
      setload({ ...load, getdata: true });
      await axios
        .put(
          affecterMoniteur + formValues.id_memb + "?id_org=" + MonCompte.id_org
        )
        .then((reponse) => {
          console.log(reponse);
          toast.success("Affectation reussi !");
          setload({ ...load, getdata: false });
          findmoniteur();
          props.setrecharger(Math.floor(Math.random() * 11));
        })
        .catch((error) => {
          console.log(error);
        //   toast.error("un erreur est survenu !");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    setload({ ...load, getdata: true });
    setFormValues({ ...formValues, id_org: MonCompte.id_org });
    const apiErrors = {};
    try {
      await axios
        .post(inscriptionMoniteur, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          setload({ ...load, getdata: false });
          toast.success("enregistrement reussi !");
          setImage(null);
          setFormValues(vide);
          handleClose()
          props.setrecharger(Math.floor(Math.random() * 11));
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setload({ ...load, getdata: false });
          if (msg) {
            toast.error("Verifier votre saisi !");
            let champs = Object.keys(msg);
            for (let i = 0; i < champs.length; i++) {
              const element = champs[i];
              if (msg[element]) {
                apiErrors[element] = msg[element];
              }
            }
          }
          if (error.response.status == 500) {
            toast.error(error.response.data.message);
          }
          if (error.response.status == 401) {
            toast.info(error.response.data.message);
          }
        });
    } catch (error) {
      console.log(error);
      setload({ ...load, getdata: false });
    }
    setError(apiErrors);
  };
  return (
    <div>
      <Button
        variant="primary"
        onClick={() => {
          setmode(false);
          setload({ landing: true, getdata: false });
          setFormValues(vide);
          setImage(null);
          setscan_capp(null);
          handleShow();
        }}
      >
        <FontAwesomeIcon icon={faPlus} className="me-2" />
        Nouveau Moniteur
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={load.landing ? "md" : "xl"}
        fullscreen={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>
              <FontAwesomeIcon icon={faUsersRectangle} className="me-2" />
              Information du moniteur
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {load.landing ? (
              <div className="col-12 d-flex justify-content-center align-items-center gap-3">
                <div>
                  <h3 className="mb-3">
                    Entrer l'adresse email du nouveau moniteur :{" "}
                  </h3>
                  <input
                    type="email"
                    name="email_memb"
                    value={formValues.email_memb}
                    className="form-control"
                    placeholder="taper ici son adresse"
                    onChange={handleChange}
                  />
                  <Button
                    onClick={findmoniteur}
                    className={
                      "btn btn-primary rounded-5 my-3 w-100 text-center"
                    }
                    disabled={load.getdata}
                  >
                    Suivant
                  </Button>
                </div>
              </div>
            ) : (
              <div className="col-12 d-flex justify-content-center">
                <div className="row g-3 w-100 ">
                  <div className="col-lg-6 col-12 d-flex align-items-stretch">
                    <Card className="w-100">
                      <Card.Body>
                        <div className="row">
                          <div className="col-12">
                            <div className="info-perso">
                              <div className="row align-items-center">
                                <div className="col-12 col-lg-6 align-self-end">
                                  {image && (
                                    <Image
                                      src={image}
                                      height="130"
                                      width="130"
                                      className="rounded-3 mt-3"
                                      style={{ borderRadius: "25px" }}
                                      preview
                                    />
                                  )}
                                  {load?.find ? null :<div className="my-3"> <input
                                      className={
                                        "form-control my-2" +
                                        (error.photo_memb && "is-invalid")
                                      }
                                      name="photo_memb"
                                      id="photo_memb"
                                      type="file"
                                      onChange={onImageChange}
                                      accept="image/*"
                                    />
                                    {error.photo_memb && (
                                      <div className="invalid-feedback">
                                        {error.photo_memb}
                                      </div>
                                    )}</div>}
                                </div>
                                <div className="col-4">
                                  <div className="">
                                    <label className="form-label">
                                      PHOTO D'IDENTITE
                                    </label>
                              
                                  </div>
                                </div>
                              </div>
                              <div className="my-3">
                                <label className="form-label">Nom</label>
                                <input
                                  className={
                                    "form-control " +
                                    (error.nom_memb && "is-invalid")
                                  }
                                  name="nom_memb"
                                  value={formValues.nom_memb}
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="Saisir ici votre nom "
                                />
                                {error.nom_memb ? (
                                  <div className="invalid-feedback">
                                    {error.nom_memb}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="my-3">
                                <label className="form-label">Prenom</label>
                                <input
                                  className={
                                    "form-control " +
                                    (error.prenom_memb && "is-invalid")
                                  }
                                  name="prenom_memb"
                                  value={formValues.prenom_memb}
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="Saisir ici votre prenom"
                                />
                                {error.prenom_memb ? (
                                  <div className="invalid-feedback">
                                    {error.prenom_memb}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>

                              <div className="my-3">
                                <label className="form-label">Sexe</label>
                                <select
                                  className={
                                    "form-control " +
                                    (error.sexe_memb && "is-invalid")
                                  }
                                  name="sexe_memb"
                                  value={
                                    formValues.sexe_memb === true
                                      ? "1"
                                      : formValues.sexe_memb === false
                                      ? "0"
                                      : formValues.sexe_memb === ""
                                      ? formValues.sexe_memb
                                      : formValues.sexe_memb
                                  }
                                  onChange={handleChange}
                                >
                                  <option>choisir sexe ...</option>
                                  <option value={0}>Femme</option>
                                  <option value={1}>Homme</option>
                                </select>
                                {error.sexe_memb ? (
                                  <div className="invalid-feedback">
                                    {error.sexe_memb}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="my-3">
                                <label className="form-label">
                                  Date de naissance
                                </label>
                                <input
                                  className={
                                    "form-control " +
                                    (error.naiss_memb && "is-invalid")
                                  }
                                  name="naiss_memb"
                                  value={formValues.naiss_memb}
                                  onChange={handleChange}
                                  type="date"
                                  placeholder="Chosir date"
                                />
                                {error.naiss_memb ? (
                                  <div className="invalid-feedback">
                                    {error.naiss_memb}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="row my-3">
                                <div className="col-12 col-lg-6">
                                  <label className="form-label">
                                    Carte d'Identité Nationale (CIN){" "}
                                  </label>
                                  <InputMask
                                    onKeyUp={(e) => {
                                      console.log(e.target.value);
                                      if (e.target.value === "") {
                                        setError({
                                          ...error,
                                          cin_memb: [
                                            "le champs cin est requis",
                                          ],
                                        });
                                      } else if (e.target.value !== "") {
                                        setError({ ...error, cin_memb: [] });
                                      }
                                    }}
                                    onChange={handleChange}
                                    type="text"
                                    className={
                                      "form-control " +
                                      (error.cin_memb?.length > 0 &&
                                        " is-invalid")
                                    }
                                    value={formValues.cin_memb}
                                    name="cin_memb"
                                    placeholder="Telephone"
                                  />

                                  {error.cin_memb &&
                                    error.cin_memb.map((element) => (
                                      <p
                                        key={element}
                                        className="invalid-feedback p-0 m-1"
                                      >
                                        {element}
                                      </p>
                                    ))}
                                </div>
                                <div className="col-12 col-lg-6">
                                  <label className="form-label">
                                    Nationalité
                                  </label>
                                  {/* <CountrySelector error={error} formvalue={}/> */}
                                  <input
                                    className={
                                      "form-control " +
                                      (error.nationalite_memb && "is-invalid")
                                    }
                                    name="nationalite_memb"
                                    value={formValues.nationalite_memb}
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Saisir ici votre nationalité"
                                  />
                                  {error.nationalite_memb ? (
                                    <div className="invalid-feedback">
                                      {error.nationalite_memb}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="info-coordonne">
                                <div className="my-3">
                                  <label className="form-label">Email</label>
                                  <input
                                    className={
                                      "form-control " +
                                      (error.email_memb && "is-invalid")
                                    }
                                    name="email_memb"
                                    value={formValues.email_memb}
                                    onChange={handleChange}
                                    type="email"
                                    placeholder="Saisir ici votre email"
                                  />
                                  {error.email_memb ? (
                                    <div className="invalid-feedback">
                                      {error.email_memb}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="my-3">
                                  <label className="form-label">
                                    Telephone (*)
                                  </label>
                                  <InputMask
                                    onKeyUp={(e) => {
                                      console.log(e.target.value);
                                      if (e.target.value === "") {
                                        setError({
                                          ...error,
                                          tel_memb: [
                                            "le champs telephone est requis",
                                          ],
                                        });
                                      } else if (e.target.value !== "") {
                                        setError({ ...error, tel_memb: [] });
                                      }
                                    }}
                                    onChange={handleChange}
                                    mask="+261 39 99 999 99"
                                    type="text"
                                    className={
                                      "form-control " +
                                      (error.tel_memb?.length > 0 &&
                                        " is-invalid")
                                    }
                                    value={formValues.tel_memb}
                                    name="tel_memb"
                                    placeholder="Telephone"
                                  />

                                  {error.tel_memb && (
                                    <p className="invalid-feedback p-0 m-1">
                                      {error.tel_memb}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-lg-6 col-12 d-flex align-items-stretch">
                    <Card className="w-100">
                      <Card.Body>
                        <div className="info-fonction-role">
                          <div className="my-3 row">
                            <div className="col-12 col-lg-8">
                              <label className="form-label">Adresse</label>
                              <input
                                className={
                                  "form-control " +
                                  (error.adresse_memb && "is-invalid")
                                }
                                name="adresse_memb"
                                value={formValues.adresse_memb}
                                onChange={handleChange}
                                type="text"
                                placeholder="Saisir ici votre adresse du membre"
                              />
                              {error.adresse_memb ? (
                                <div className="invalid-feedback">
                                  {error.adresse_memb}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="col-12 col-lg-4">
                              <label className="form-label">Code postal</label>
                              <input
                                className={
                                  "form-control " +
                                  (error.cp_memb && "is-invalid")
                                }
                                name="cp_memb"
                                value={formValues.cp_memb}
                                onChange={handleChange}
                                type="number"
                                placeholder="votre code postal"
                              />
                              {error.cp_memb ? (
                                <div className="invalid-feedback">
                                  {error.cp_memb}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-lg-6">
                              <label className="form-label">Ville</label>
                              <input
                                className={
                                  "form-control " +
                                  (error.ville_memb && "is-invalid")
                                }
                                name="ville_memb"
                                value={formValues.ville_memb}
                                onChange={handleChange}
                                type="text"
                                placeholder="Saisir ici votre ville"
                              />
                              {error.ville_memb ? (
                                <div className="invalid-feedback">
                                  {error.ville_memb}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="col-12 col-lg-6">
                              <label className="form-label">Région</label>
                              <SelectOne
                                options={regions}
                                load={false}
                                choix={formValues.province_memb}
                                placeholder="choix région"
                                setFormValues={setFormValues}
                                formValues={formValues}
                                setError={setError}
                                error={error}
                                name={"province_memb"}
                              />
                              {error.province_memb ? (
                                <div className="invalid-feedback">
                                  {error.province_memb}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="my-3">
                            <label className="form-label">Pays</label>
                            <input
                              className={
                                "form-control " +
                                (error.pays_memb && "is-invalid")
                              }
                              name="pays_memb"
                              value={formValues.pays_memb}
                              onChange={handleChange}
                              type="text"
                              placeholder="Saisir ici votre pays du membre"
                            />
                            {error.pays_memb ? (
                              <div className="invalid-feedback">
                                {error.pays_memb}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="my-3">
                            <label className="form-label">auto-école</label>
                            {dataRedux?.map((el) =>
                              el.value == formValues?.id_org ? (
                                <input
                                  className={"form-control "}
                                  name="pays_memb"
                                  value={el.label}
                                  type="text"
                                />
                              ) : null
                            )}
                          </div>
                          {/* <div className="my-3">
                            <label className="form-label">
                               auto-école
                            </label>
                            <SelectOneId
                              options={dataRedux}
                              load={false}
                              placeholder="choix auto-école"
                              setFormValues={setFormValues}
                              formValues={formValues}
                              setError={setError}
                              error={error}
                              name={"id_org"}
                            />

                            {error.id_org ? (
                              <div className="invalid-feedback">
                                {error.id_org}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div> */}
                          <div className="my-3">
                            <label className="form-label">Fonction</label>
                            <select
                              className={
                                "form-control " +
                                (error.fonction_memb && "is-invalid")
                              }
                              name="fonction_memb"
                              value={formValues.fonction_memb}
                              onChange={handleChange}
                            >
                              <option>choisir fonction ...</option>
                              <option value="Moniteur">moniteur</option>
                              <option value="validateur">validateur</option>
                              <option value="Directeur administratif et financier">
                                Directeur administratif et financier
                              </option>
                              <option value="directeur">directeur</option>
                              <option value="secrétaire">secrétaire</option>
                              <option value="Autre">Autre</option>
                            </select>
                            {error.fonction_memb ? (
                              <div className="invalid-feedback">
                                {error.fonction_memb}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="my-3">
                            <Inpt
                              error={error}
                              formValues={formValues}
                              name={"num_capp"}
                              handleChange={handleChange}
                              type={"text"}
                              label={
                                "N°CAPP (Certificat d’Aptitude Professionnelle et Pédagogique)"
                              }
                              placeholder={"Saisir numéro CAPP"}
                            />
                          </div>
                          <div className="my-3">
                            <Inpt
                              error={error}
                              formValues={formValues}
                              name={"date_capp"}
                              handleChange={handleChange}
                              type={"date"}
                              label={"Date de délivrance du CAPP"}
                              placeholder={"Date de délivrance du CAPP"}
                            />
                          </div>
                          <div className="my-3">
                            <label className="form-label">SCAN du CAPP </label>
                            {load?.find ? null : (
                              <input
                                className={
                                  "form-control " +
                                  (error.scan_capp && "is-invalid")
                                }
                                name="scan_capp"
                                id="scan_capp"
                                type="file"
                                onChange={onImageChange}
                                accept="image/*"
                              />
                            )}
                            {error.scan_capp && (
                              <div className="invalid-feedback">
                                {error.scan_capp}
                              </div>
                            )}
                            {capp && (
                              <div className="rounded-3">
                                <Image
                                  src={capp}
                                  height="130"
                                  width="200"
                                  className="rounded-3 mt-3"
                                  style={{ borderRadius: "25px" }}
                                  preview
                                />
                              </div>
                            )}
                          </div>
                          {load?.find ? null : (
                            <>
                              {" "}
                              <div className="my-3">
                                <label className="form-label">
                                  Mot de passe (*)
                                </label>
                                <div className="input-group ">
                                  <input
                                    onKeyUp={(e) => {
                                      if (e.target.value === "") {
                                        setError({
                                          ...error,
                                          password: [
                                            "le champs mot de passe est requis",
                                          ],
                                        });
                                      } else if (e.target.value !== "") {
                                        setError({ ...error, password: [] });
                                      }
                                    }}
                                    onChange={handleChange}
                                    type={showdp ? "text" : "password"}
                                    name="password"
                                    value={formValues.password}
                                    className={
                                      "form-control " +
                                      (error.password?.length > 0 &&
                                        " is-invalid")
                                    }
                                    placeholder="Mot de passe"
                                  />
                                  <div
                                    className="input-group-append"
                                    onClick={() => setshowdp(!showdp)}
                                  >
                                    <span className="input-group-text h-100">
                                      <FontAwesomeIcon
                                        icon={showdp ? faEye : faEyeSlash}
                                      />
                                    </span>
                                  </div>
                                  {error.password &&
                                    error.password.map((element) => (
                                      <p
                                        key={element}
                                        className="invalid-feedback p-0 m-1"
                                      >
                                        * {element}
                                      </p>
                                    ))}
                                </div>
                              </div>
                              <div className="my-3">
                                <label className="form-label">
                                  Mot de passe de confirmation (*)
                                </label>
                                <input
                                  onKeyUp={(e) => {
                                    if (e.target.value === "") {
                                      setError({
                                        ...error,
                                        password_confirmation: [
                                          "ce champs est requis",
                                        ],
                                      });
                                    } else if (e.target.value !== "") {
                                      setError({
                                        ...error,
                                        password_confirmation: [],
                                      });
                                    }
                                  }}
                                  onChange={handleChange}
                                  type="password"
                                  name="password_confirmation"
                                  value={formValues.password_confirmation}
                                  className={
                                    "form-control " +
                                    (error.password_confirmation?.length > 0 &&
                                      " is-invalid")
                                  }
                                  placeholder="Confirmation du passe"
                                />
                                {error.password_confirmation && (
                                  <p className="invalid-feedback p-0 m-1">
                                    * {error.password_confirmation}
                                  </p>
                                )}
                              </div>{" "}
                            </>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        {load.landing ? null : (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            <Button
              variant={MonCompte?.id_org == formValues?.id_org ? "success":"danger"}
              disabled={load.getdata}
              onClick={() => (load?.find ? changer() : handleSubmit())}
            >
              {"Affecter moniteur"}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
}
