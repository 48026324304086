import React from 'react'
import Accueil from '../../Accueil/Accueil'

export default function AccueilORG() {
  return (
    <>
    <Accueil/>
    </>
  )
}
