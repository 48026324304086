import { useEffect, useState } from "react";
import axios from "axios";

function UseEndpoint(url, apidata, recharger, param) {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    getData();
  }, [recharger]);

  // Créer une instance de URLSearchParams
  const filteredParams = new URLSearchParams();

  // Ajouter uniquement les paramètres qui ont une valeur non nulle
  if (param) {
  for (const [key, value] of Object.entries(param)) {
    if (value !== null && value !== undefined) {
      filteredParams.append(key, value);
    }
  }
  }
  // Construire la chaîne de paramètres
  const queryString = filteredParams.toString();

  // Construire l'URL complète uniquement si la chaîne de paramètres n'est pas vide
  const fetchUrl = queryString ? `${url}?${queryString}` : url;
  const getData = async () => {
    setLoad(true);
    await fetch(fetchUrl, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
       
        let donnee = data[apidata];
        console.log(data);
        setData(donnee);
        setLoad(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des données:", error);
      });
  };

  return { data, load };
}

export default UseEndpoint;
