import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";

export default function DonutChart(props) {
  Chart.register(ArcElement, Tooltip, Legend);
  const data = {
    labels: [props.label[0], props.label[1]],
    datasets: [
      {
        data: [props?.donnee1, props?.donnee2],
        backgroundColor: ["blue", "pink"],
        hoverBackgroundColor: ["blue", "pink"],
      },
    ],
  };
  const options = {
    aspectRatio:props.taille ,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
  return <Doughnut width={100} height={50} data={data} options={options} />;
}
