import { useEffect, useState } from "react";

function SelectDistinct(data,id_prop,property) {
    const distinctData = data.reduce((acc, current) => {
        if (!acc.some(item => item[id_prop] === current[id_prop])) {
          acc.push(current);
        }
        return acc;
      }, []);

  return { distinctData };
}

export default SelectDistinct;






















