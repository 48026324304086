import { faBookOpenReader, faBoxArchive, faCarSide, faChartPie, faGraduationCap, faHouse, faRectangleXmark, faSquareCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import DonutChart from "./DonutChart";

export default function AccueilStat() {

  return (
    <div className="container-lg container-fluid py-4">
      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="">
          <h2>
            <FontAwesomeIcon icon={faHouse} className="me-1" /> Statistique de
            suivi{" "}
          </h2>
          <h4>Menu des statistiques</h4>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-lg-3 col-6 p-2 d-flex align-items-stretch">
          <Link to={'/statgen'} className="card w-100 card-link card-link-pop">
            <div className="body p-3 pt-4">
              <div className="w-100 d-flex flex-column justify-content-center">
                <FontAwesomeIcon icon={faChartPie} size="4x" />
                <h2 className="text-center my-3">Statistique générales</h2>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-lg-3 col-6 p-2 d-flex align-items-stretch">
          <Link to={'/statmodule'} className="card w-100 card-link card-link-pop">
            <div className="body p-3 pt-4">
              <div className="w-100 d-flex flex-column justify-content-center">
                <FontAwesomeIcon icon={faBookOpenReader} size="4x" />
                <h2 className="text-center my-3">Modules</h2>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-lg-3 col-6 p-2 d-flex align-items-stretch">
          <Link to={'/statautoecole'} className="card w-100 card-link card-link-pop">
            <div className="body p-3 pt-4">
              <div className="w-100 d-flex flex-column justify-content-center">
                <FontAwesomeIcon icon={faCarSide} size="4x" />
                <h2 className="text-center my-3">Auto-écoles</h2>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-lg-3 col-6 p-2 d-flex align-items-stretch">
          <Link to={'/statcandidat'} className="card w-100 card-link card-link-pop">
            <div className="body p-3 pt-4">
              <div className="w-100 d-flex flex-column justify-content-center">
                <FontAwesomeIcon icon={faGraduationCap} size="4x" />
                <h2 className="text-center my-3">Candidats</h2>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
