import {
  faAdd,
  faCheckCircle,
  faEdit,
  faEye,
  faFilterCircleXmark,
  faHouse,
  faPerson,
  faShower,
  faTrash,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal, FormControl, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { Image } from "primereact/image";
import { Tag } from "primereact/tag";
import { useSelector } from "react-redux";

import {
  addExaminateur,
  examinateurByStatus,
  file_url,
  getListExaminateur,
  organisme_API,
} from "../../../../service/config";
import isconnected from "../../../../service/isconnected";
import { regions } from "../../../../service/auth/UtilApi";
import SelectOne from "../../../../outils/Select/selectCustom";
import Details from "./Details";
import ChangerStatusExaminateur from "./ChangerStatusExaminateur";
import AffectationExaminateur from "./AffectationExaminateur";

export default function Examinateurs() {
  const abortController = new AbortController();
  const Compte = useSelector((state) => state.compte);
  const [centre, setCentre] = useState([]);
  const [loadcentre, setloadcentre] = useState(true);
  const [filtre, setfiltre] = useState({
    nom: "",
    region: "",
    ville: "",
    type: "",
  });
  const [videfiltre, setvidefiltre] = useState({
    nom: "",
    region: "",
    ville: "",
    type: "",
  });
  const handleFilter = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    setfiltre({ ...filtre, [name]: value });
  };

  const columns = [
    {
      name: "photo",
      width:'80px',
      selector: (row) => (
        <Image src={file_url + row.photo_memb} alt="Image" width="30" preview />
      ),
      sortable: true,
    },
    {
      name: "nom & prenom",
      selector: (row) => row.nom_memb + " " + row.prenom_memb,
      sortable: true,
      wrap:true,
      width: "23%",
    },
    {
      name: "email",
      selector: (row) => row.email_memb,
      sortable: true,
      wrap: true,
      width: "23%",
    },
    {
      name: "téléphone",
      selector: (row) => row.tel_memb,
      sortable: true,
      wrap: true,
      width: "12%",
    },
    {
      name: "Statut",
      selector: (row) => (
        <ChangerStatusExaminateur setrecharger={setrecharger} row={row} />
      ),
      sortable: true,
    },
    {
      name: "Affectation",
      width: "18%",
      selector: (row) => (
        <AffectationExaminateur
          centre={centre}
          row={row}
          setrecharger={setrecharger}
          load={loadcentre}
        />
      ),
      sortable: true,
    },
    {
      name: "Détails",
      width: "18%",
      cell: (row) => (
        <div className="d-flex gap-1">
          <button
            className="btn btn-primary"
            onClick={() => {
              // alert(JSON.stringify(row))
              setmode(true);
              setFormValues(row);
              setShow(true);
              setImage(`${file_url}${row.photo_memb}`);
              setImagescan(`${file_url}${row.scan_capp}`);
            }}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
    },
  ];
  const colAtt = [
    {
      name: "photo",
      selector: (row) => (
        <Image src={file_url + row.photo_memb} alt="Image" width="50" preview />
      ),
      sortable: true,
    },
    {
      name: "nom & prenom",
      selector: (row) => row.nom_memb + " " + row.prenom_memb,
      sortable: true,
    },
    {
      name: "email",
      selector: (row) => row.email_memb,
      sortable: true,
      wrap: true,
      width: "23%",
    },
    {
      name: "Statut",
      selector: (row) => (
        <Tag
        severity={
          row.active_memb === "Activer"
            ? "success"
            : row.active_memb === "Desactiver"
            ? "secondary"
            : "danger"
        }
        value={row.active_memb == "Activer" ? "Activé" : row.active_memb == "Desactiver"  ? "Desactivé" : "Bloqué"}
      ></Tag>
      ),
      sortable: true,
    },
    {
      name: "Détails",
      cell: (row) => (
        <div className="d-flex gap-1">
          <button
            className="btn btn-primary"
            onClick={() => {
              // alert(JSON.stringify(row))
              setmode(true);
              setFormValues(row);
              setShow(true);
              setImage(`${file_url}${row.photo_memb}`);
              setImagescan(`${file_url}${row.scan_capp}`);
            }}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [data, setdata] = useState([]);
  const [show, setShow] = useState(false);
  const [mode, setmode] = useState(false);
  const [supp, setsupp] = useState(false);
  const [recharger, setrecharger] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [load, setLoad] = useState(false);
  const [formValues, setFormValues] = useState({
    role_memb: "Examinateurs",
  });
  const [vide, setvide] = useState({
    role_memb: "Examinateurs",
  });

  const [toggledClearRows, settoggledClearRows] = useState(false);
  const handleClearRows = () => {
    settoggledClearRows(!toggledClearRows);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlesup = () => setsupp(!supp);
  const [error, setError] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    if (isconnected.user().type_util == "MTM") {
      setFormValues({ ...formValues, [name]: value });
      setError({ ...error, [name]: null });
    }
  };

  const [image, setImage] = useState(null);
  const [imagescan, setImagescan] = useState(null);

  const onImageChange = (e) => {
    console.log(e.target.files[0].size);
    if (isconnected.user().type_util == "MTM") {
      if (e.target.files && e.target.files[0]) {
        setFormValues({ ...formValues, [e.target.name]: e.target.files[0] });
        setError({ ...error, [e.target.name]: null });
      }
      if (e.target.name === "photo_memb") {
        setImage(URL.createObjectURL(e.target.files[0]));
      } else {
        setImagescan(URL.createObjectURL(e.target.files[0]));
      }
    }
  };

  const handleSubmit = () => {
    console.log(formValues)
      setLoad(true);
      const apiErrors = {};
      axios
      .post(addExaminateur, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          console.log(response);
          setLoad(false);
          setShow(false);
          response.data?.message && (toast.success( response.data?.message))
          getAllOrganisme()
          setImage(null);
          setFormValues(vide);
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            setError(msg);
          }
          if (error.response.status == 500) {
            toast.error(error.message);
            setShow(false);
            setFormValues(vide);
            setImage(null);
          }
        });
    
    
  };
  const handleDelete = () => {
    selectedRows.forEach((element, i) => {
      axios
        .delete(examinateurByStatus + "/" + element.id_memb)
        .then((re) => {
          if (i == selectedRows.length - 1) {
            getAllOrganisme();
            settoggledClearRows(!toggledClearRows);
            toast.success("suppression reussi");
            setsupp(!supp);
          }
        })
        .catch((err) => {
          toast.error("Un erreur est survenu");
          settoggledClearRows(!toggledClearRows);
          setsupp(!supp);
          console.log(err);
        });
    });
  };
  const imagenotfound = (url) => {
    setImagescan(url);
  };
  const getAllOrganisme = () => {
    setLoad(true);
    axios
      .get(examinateurByStatus + filtre.type)
      .then((rep) => {
        console.log(rep);
        setdata(rep.data.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCentre = () => {
    setloadcentre(true);
    axios
      .get(organisme_API + "?type_org=Examinateurs")
      .then((rep) => {
        console.log(rep);
        setCentre(rep.data.data);
        setloadcentre(false);
      })
      .catch((err) => {
        console.log(err);
        setloadcentre(false);
      });
  };
 const getExaminateurByCentre = () => {
  setLoad(true);
  axios
    .get(getListExaminateur + Compte.id_org)
    .then((rep) => {
      console.log(rep);
      setdata(rep.data.data);
      setLoad(false);
    })
    .catch((err) => {
      console.log(err);
    });
 }
  useEffect(() => {
    if (isconnected.user().type_util == "MTM") {
    getAllOrganisme();
    getCentre();
    }else {
      getExaminateurByCentre()
    }
    return () => {
      abortController.abort();
    };
  }, [recharger, filtre.type]);
  return (
    <div className="container-lg container-fluid py-4">
      <Modal
        show={supp}
        onHide={handlesup}
        backdrop="static"
        keyboard={false}
        fullscreen={false}
        size={"sm"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Supprimer</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlesup}>
            Annuler
          </Button>
          <Button
            variant="danger"
            disabled={load}
            onClick={() => handleDelete()}
          >
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
        <div className="">
          <h2>
            <FontAwesomeIcon icon={faPerson} className="me-1" /> Examinateurs{" "}
          </h2>
        </div>
        <div>
         {isconnected.user().type_util == "MTM" && (<Button
            variant="primary"
            onClick={() => {
              setmode(false);
              setFormValues(vide);
              setImage(null);
              setImagescan(null);
              handleShow();
            }}
          >
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            Nouvel examinateur
          </Button>)}
        </div>
      </div>

      <div className="">
        <div className="row my-2 mb-3 g-2">
          <div className="col-lg-3 col-md-6  d-flex align-items-center">
            <label className="text-nowrap me-2" style={{ width: "180px" }}>
              Nom ou Num CAPP:
            </label>
            <input
              className="form-control"
              name="nom"
              value={filtre.nom}
              onChange={handleFilter}
              type="text"
              placeholder="nom ou n° capp"
            />
          </div>
         {isconnected.user().type_util == "MTM" && <> <div className="col-lg-3 col-md-6  d-flex align-items-center">
            <label style={{ width: "65px" }}>Région :</label>
            <select
              className="form-control"
              name="region"
              value={filtre.region}
              onChange={handleFilter}
            >
              <option value="">sélectionner ici...</option>
              {regions.map((reg) => (
                <option value={reg.label}>{reg.label}</option>
              ))}
            </select>
          </div>
          <div className="col-lg-3 col-md-6  d-flex align-items-center">
            <label style={{ width: "65px" }}>Ville :</label>
            <input
              className="form-control"
              name="ville"
              value={filtre.ville}
              placeholder="Choix ville"
              onChange={handleFilter}
            />
          </div>
          <div className="col-lg-3 col-md-6  d-flex align-items-center">
            <label style={{ width: "65px" }}>Status :</label>
            <select
              className="form-control"
              name="type"
              value={filtre.type}
              onChange={handleFilter}
            >
              <option value="">sélectionner ici...</option>
              <option value="Activer">Activé</option>
              <option value="Desactiver">Desactivé</option>
            </select>
          </div></>}
        </div>
        <div className="d-flex justify-content-end mb-2">
          <Button
            variant="secondary"
            onClick={() => setfiltre(videfiltre)}
            size="md"
          >
            <FontAwesomeIcon icon={faFilterCircleXmark} className="me-2" />{" "}
            Enlever filtre
          </Button>
        </div>
        <DataTable
        responsive
          className="custom-table"
          title={<p className="fs-4">Liste des examinateurs</p>}
          columns={isconnected.user().type_util == "MTM" ? columns : colAtt}
          data={data
            .filter((f) =>
              filtre.nom != "" && f.nom_memb != null
                ? f.nom_memb.toLowerCase().includes(filtre.nom.toLowerCase()) ||
                  f.num_capp?.toLowerCase().includes(filtre.nom.toLowerCase())
                : f
            )
            .filter((f) =>
              filtre.region != "" && f.province_memb != null
                ? f.province_memb
                    .toLowerCase()
                    .includes(filtre.region.toLowerCase())
                : f
            )
            .filter((f) =>
              filtre.type != "" && f.type_org != null
                ? f.type_org.toLowerCase().includes(filtre.type.toLowerCase())
                : f
            )
            .filter((f) =>
              filtre.ville != "" && f.ville_org != null
                ? f.ville_org.toLowerCase().includes(filtre.ville.toLowerCase())
                : f
            )}
          selectableRows
          clearSelectedRows={toggledClearRows}
          selectedRow={selectedRows}
          progressPending={load}
          contextActions={
            <div className="d-flex w-100 justify-content-between">
              <Button
                className="me-2"
                variant="secondary"
                onClick={() => {
                  settoggledClearRows(!toggledClearRows);
                }}
              >
                <FontAwesomeIcon icon={faUndoAlt} className="" />
              </Button>

              <button
                className="btn btn-danger me-2"
                onClick={() => {
                  setsupp(true);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          }
          progressComponent={
            <div className="w-100 d-flex justify-content-center py-5">
              <TailSpin
                height="80"
                width="80"
                color="#22577A"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          }
          contextMessage={{
            singular: "élement",
            plural: "élements",
            message: "sélectionnées",
          }}
          onSelectedRowsChange={(rows) => {
            setSelectedRows(rows.selectedRows);
          }}
          pagination
          paginationPerPage={5}
          paginationComponentOptions={{
            rowsPerPageText: "Lignes par page",
          }}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Formulaire </h2>

            {formValues?.active_memb && <h4>
              Status :{" "}
              <Tag
                severity={
                  formValues.active_memb === "Activer"
                    ? "success"
                    : formValues.active_memb === "Desactiver"
                    ? "secondary"
                    : "danger"
                }
                value={
                  formValues.active_memb == "Activer"
                    ? "Activé"
                    : formValues.active_memb == "Desactiver"
                    ? "Desactivé"
                    : "Bloqué"
                }
              ></Tag>
            </h4>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Details
            error={error}
            setError={setError}
            formValues={formValues}
            setFormValues={setFormValues}
            handleChange={handleChange}
            image={image}
            imagescan={imagescan}
            onImageChange={onImageChange}
            setImage={setImage}
            setImagescan={setImagescan}
            imagenotfound={imagenotfound}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          {mode == false && (
            <Button
              variant="primary"
              disabled={load}
              onClick={() => handleSubmit()}
            >
              {<>Enregistrer</>}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
