import {
  faArrowAltCircleRight,
  faArrowCircleLeft,
  faAward,
  faBackwardStep,
  faBlenderPhone,
  faCircleInfo,
  faFile,
  faFilePdf,
  faFileWord,
  faNewspaper,
  faPersonWalkingArrowLoopLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ACTU_API,
  file_url,
  SENSI_API,
  SENSI_API_ID,
} from "../../../service/config";
import { Button } from "react-bootstrap";
import ScrollToTop from "react-scroll-to-top";


export default function Details_sens() {
  let { id } = useParams();
  const {pathname} = useLocation()
  const [load, setload] = useState(true);
  const navigate = useNavigate()
  const [textlois, setTextlois] = useState({});
  function Separer(strin) {
    if (strin === undefined || strin === null) {
      return null;
    } else {
      var tab = strin.split(";");

      return (
        <>
          {tab.map(
            (element, index) =>
              element !== "" && (
                <span
                  key={index}
                  className="badge bg-transparent text-primary border border-indigo"
                >
                  {element}
                </span>
              )
          )}
        </>
      );
    }
  }

  const getTextloi = async () => {
    setload(true);
    try {
      await axios
        .get(SENSI_API_ID + "/" + id)
        .then((reponse) => {
          console.log(reponse);
          setload(false);
          setTextlois(reponse.data.sensibilisation);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTextloi();
  }, []);

  return (
    <div className="container-lg container-fluid py-3 mt-2 vh-100">
      
      <div className="card w-100 mb-3">
        <div className="card-header d-flex justify-content-between align-items-center">
         <div>
         <Button variant="btn btn-secondary btn-md" onClick={()=>navigate(-1)}><FontAwesomeIcon icon={faArrowCircleLeft} className="me-2"/> retour </Button>
         </div>
          <h3 className="fst-italic">
            Détails du message
          </h3>
         
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row  flex-lg-row-reverse justify-content-between align-items-center g-5 py-4">
            <div className="col-12 col-sm-8 col-lg-4">
              {load === false && (
                <img
                  src={file_url + textlois?.img_sens}
                  className="d-block mx-lg-auto img-fluid text-center"
                  style={{ width: "100%" }}
                  alt="..."
                  loading="lazy"
                />
              )}
            </div>
            <div className="col-lg-8 align-self-start">
              <h1 className="display-7 fw-bold lh-1 mb-3 text-primary text-start">
                {textlois?.titre_sens}
              </h1>
              <div className="my-2 d-flex gap-1"></div>
              <p className="lead w-100">{textlois?.desc_sens}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
