import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./ActifStyle.css"

export default function NavStat() {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.pathname);
  return (
    <div className="nav bg-light rounded-3">
      <div
        className={
          location.pathname === "/statistique de suivi"
            ? "nav-item fs-5 fw-bolder actif"
            : "nav-item fs-5 "
        }
      >
        <div
          onClick={() => navigate("/statistique de suivi")}
          className="nav-link"
        >
          Accueil
        </div>
      </div>{" "}
      <div
        className={
          location.pathname === "/statgen"
            ? "nav-item fs-5 fw-bolder actif"
            : "nav-item fs-5 "
        }
      >
        <div
          onClick={() => navigate("/statgen")}
          className="nav-link"
        >
          Générales
        </div>
      </div>{" "}
      <div
        className={
          location.pathname === "/statmodule"
            ? "nav-item fs-5 fw-bolder actif"
            : "nav-item fs-5 "
        }
      >
        <div
          className="nav-link"
          onClick={() => navigate("/statmodule")}
        >
          Modules
        </div>
      </div>{" "}
      <div
        className={
          location.pathname === "/statautoecole"
            ? "nav-item fs-5 fw-bolder actif"
            : "nav-item fs-5 "
        }
      >
        <div
          className="nav-link"
          onClick={() => navigate("/statautoecole")}
        >
          Auto-école
        </div>
      </div>{" "}
      <div
        className={
          location.pathname === "/statcandidat"
            ? "nav-item fs-5 fw-bolder actif"
            : "nav-item fs-5 "
        }
      >
        <div
          className="nav-link"
          onClick={() => navigate("/statcandidat")}
        >
          Candidats
        </div>
      </div>
    </div>
  );
}
